import { LoadableValue } from "@/lib/mobx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import { LoadablePlaceholder } from "./loadable-placeholder";

type LoadableMessageBlockProps<T> = {
  loadable?: LoadableValue<T>;
  loadingMessage: string;
  renderLoaded: (a: T) => JSX.Element | string | null;
};

export const LoadableMessageBlock = observer(function <T>(
  props: LoadableMessageBlockProps<T>
) {
  const { loadingMessage, ...rest } = props;
  return (
    <LoadablePlaceholder
      {...rest}
      renderPlaceholder={() => (
        <Box textAlign={"center"} m={4}>
          <Typography variant="body2">{loadingMessage}</Typography>
        </Box>
      )}
    />
  );
});
