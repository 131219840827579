import { doing } from "@/framework/errors/error-context";
import { getLanguageAlpha2 } from "@/modules/language/getLangaugeAndRegion";
import { unpackTrpcErrors } from "@/server/client/unpackTrpcError";
import { trpc } from "@/utils/trpc";
import { z } from "zod";
import { SearchResults } from "../search/types";
import debug from "debug";
import { SettingsState } from "@/modules/settings/SettingsState";

const log = debug("dict:RemoteDictionary");

/*

    Dictionary that uses a remote server to look up translations.
    (as opposed to the solution that downloads the whole dictionary and can operate offline)

*/

const ApiResponseZ = z.object({
  provider: z.string(),
  results: z.array(
    z.object({
      score: z.number(),
      source: z.string(),
      translations: z.array(z.string()),
    })
  ),
});

export class RemoteDictionary {
  async findTranslations(
    word: string,
    opts: {
      sourceLang: string | null;
      targetLang: string | null;
      textContext?: [string, string, string] | null;
      settingsState: SettingsState;
    }
  ): Promise<SearchResults> {
    log("findTranslations", { word, opts });
    const { sourceLang, targetLang, textContext, settingsState } = opts;
    if (
      sourceLang &&
      getLanguageAlpha2(sourceLang) === "de" &&
      (targetLang === null || getLanguageAlpha2(targetLang) === "en")
    ) {
      const response = await fetch(`/api/v2/dict/de-en/lookup?word=${e(word)}`);
      const parsedResponse = await response.json();
      return ApiResponseZ.parse(parsedResponse);
    } else {
      return await doing(
        {
          doing: "looking up word in remote dictionary",
          params: { word, sourceLang, targetLang },
        },
        async () => {
          log("trpc.dictionary.lookup");
          const result = await unpackTrpcErrors(() =>
            trpc.dictionary.lookup.mutate({
              word,
              sourceLang,
              targetLang,
              textContext,
              apiKeys: {
                openaiApiKey: settingsState.openaiApiKey.value,
              },
            })
          );
          return result;
        }
      );
    }
  }
}

const e = encodeURIComponent;
