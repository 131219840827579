import { observer } from "mobx-react";
import { TrackListItemButton } from "../episode/TrackListItemButton";
import { TrackListLook } from "../look/TrackListLook";
import type { CxProps } from "../types";
import { FeaturedEpisodeCollection } from "./FeaturedEpisodeCollection";

type FeaturedEpisodeListProps = CxProps & {
  featuredEpisodeCollection: FeaturedEpisodeCollection;
  limit?: number;
  expandButton?: React.ReactNode;
  loadingMore?: boolean;
};

export const FeaturedEpisodeList = observer(
  ({
    cx,
    featuredEpisodeCollection,
    limit,
    expandButton,
  }: FeaturedEpisodeListProps) => {
    const tracks = featuredEpisodeCollection.tracks;
    const tracksToShow = limit ? tracks.slice(0, limit) : tracks;
    const showExpandButton = featuredEpisodeCollection.fullyLoaded
      ? limit && tracks.length > limit
      : limit && tracks.length >= limit;
    const loadingMore =
      !showExpandButton &&
      !featuredEpisodeCollection.fullyLoaded &&
      (!limit || tracks.length <= limit);

    return (
      <TrackListLook
        expandButton={showExpandButton && expandButton}
        loadingMore={loadingMore}
      >
        {tracksToShow.map((track, index) => {
          return (
            <TrackListItemButton
              cx={cx}
              key={track.key}
              sx={{ my: 2 }}
              track={track}
              lazy={index > 5}
            />
          );
        })}
      </TrackListLook>
    );
  }
);
