import { isMissing } from "@/lib/prelude";
import { AudioPlayerSettings } from "@/lib/audio-player/AudioPlayerState";
import { SingletonEntity } from "@/lib/mobx-pouch/SingletonEntity";
import { LoadableValue } from "@/lib/mobx/LoadableValue";
import { findOrCreatePouchDoc } from "@/lib/pouch/utils";
import { Workspace } from "@/modules/main/Workspace";
import { TranslationLanguage } from "@/modules/translation/TranslationLanguage";
import debug from "debug";
import { makeObservable } from "mobx";
import { SettingItemState } from "./SettingItemState";
import { Iso2LanguageCode } from "@/lib/language/codes/iso2ToName";

export const log = debug("settings:SettingsState");

type SettingsAttrsLanguages = Partial<
  Record<Iso2LanguageCode, LanguageSettingsAttrs>
>;

export type SettingsAttrs = {
  volume?: number;
  playbackRate?: number;
  playbackRateCycler?: number[];
  translationLanguage?: TranslationLanguage;
  translateAutomatically?: boolean;
  showTranslationsAutomatically?: boolean;
  languages?: SettingsAttrsLanguages;
  deepgramApiKey?: string;
  openaiApiKey?: string;
  googleTranslateApiKey?: string;
  deeplApiKey?: string;
};
export type LanguageSettingsAttrs = {
  autoTranslateInfo?: boolean;
};

export type SettingKey = keyof SettingsAttrs;

export type SettingsEntity = SingletonEntity<SettingsAttrs, SettingsState>;

export class SettingsState {
  volumeState = new SettingItemState<number>(this.entity, "volume");
  playbackRateState = new SettingItemState<number>(this.entity, "playbackRate");
  playbackRateCyclerState = new SettingItemState<number[]>(
    this.entity,
    "playbackRateCycler"
  );
  translationLanguageState = new SettingItemState<TranslationLanguage>(
    this.entity,
    "translationLanguage"
  );
  // showTranslationsAutomaticallyState = new SettingItemState<boolean>(
  //   this.entity,
  //   "showTranslationsAutomatically",
  //   {
  //     label: "Show translations automatically",
  //     hint: "Automatically show existing translations for visible transcript sentences. This will not consume your credits.",
  //   }
  // );

  translateAutomaticallyState = new SettingItemState<boolean>(
    this.entity,
    "translateAutomatically",
    {
      label: "Translate automatically",
      hint: "Automatically translate visible transcript sentences. Enabling this will consume more credits or cause you to run into the free usage limit quicker.",
    }
  );

  deepgramApiKeyState = new SettingItemState<string>(
    this.entity,
    "deepgramApiKey",
    {
      label: "Deepgram API Key",
    }
  );

  deeplApiKeyState = new SettingItemState<string>(this.entity, "deeplApiKey", {
    label: "DeepL API Key",
  });

  googleTranslateApiKeyState = new SettingItemState<string>(
    this.entity,
    "googleTranslateApiKey",
    {
      label: "Google Cloud Translate API Key",
    }
  );

  openaiApiKey = new SettingItemState<string>(this.entity, "openaiApiKey", {
    label: "OpenAI API Key",
  });

  // languagesSettingsState = new LanguagesSettingsState(this.entity);

  constructor(public workspace: Workspace, public entity: SettingsEntity) {
    log("loaded settings", JSON.stringify(entity.doc, null, 2));
    makeObservable(this, {});
  }

  static getLoadable(workspace: Workspace): LoadableValue<SettingsState> {
    return new LoadableValue<SettingsState>(async () => {
      const doc = await findOrCreatePouchDoc(
        workspace.database.db,
        "settings",
        {}
      );
      const entity = new SingletonEntity(doc, workspace.database);
      return new SettingsState(workspace, entity);
    });
  }

  async save() {
    return this.entity.save().then(() => {
      log("saved settings", JSON.stringify(this.entity.doc, null, 2));
    });
  }

  get audioSettings(): AudioPlayerSettings {
    return {
      volume: this.volumeState.value,
      playbackRate: this.playbackRateState.value,
    };
  }

  getAutoTranslateInfo(languageCode: Iso2LanguageCode): boolean {
    const languages = this.entity.doc.languages ?? {};
    return languages[languageCode]?.autoTranslateInfo ?? false;
  }

  setAutoTranslateInfo(languageCode: Iso2LanguageCode, value: boolean) {
    const languages: SettingsAttrsLanguages = this.entity.doc.languages ?? {};
    languages[languageCode] = {
      ...(languages[languageCode] ?? {}),
      autoTranslateInfo: value,
    };
    this.entity.doc.languages = languages;
    this.save();
  }

  get deepgramApiKey() {
    return this.deepgramApiKeyState.value;
  }
  get googleTranslateApiKey() {
    return this.googleTranslateApiKeyState.value;
  }
}

// class LanguagesSettingsState {
//   public list: LanguageSettingsState[];

//   constructor(public entity: SettingsEntity) {
//     makeObservable(this, {});

//     this.list = [];
//     if (entity.doc.languages) {
//       entity.doc.languages.forEach((languageEntry) => {
//         this.list.push(new LanguageSettingsState(entity, languageEntry));
//       });
//     }
//   }

//   forLanguage(
//     languageCode: Iso2LanguageCode
//   ): LanguageSettingsState | undefined {
//     return this.list.find((item) => item.entry.code === languageCode);
//   }

//   addLanguage(languageCode: Iso2LanguageCode) {
//     const languageEntry: LanguageSettingsAttrs = {
//       code: languageCode,
//     };
//     this.list.push(new LanguageSettingsState(this.entity, languageEntry));
//     this.entity.doc.languages = this.list.map((entry) => entry.entry);
//   }
// }

// class LanguageSettingsState {
//   constructor(
//     public entity: SettingsEntity,
//     public entry: LanguageSettingsAttrs
//   ) {
//     makeObservable(this, {});
//   }

//   setAutoTranslateInfo(autoTranslateInfo: boolean) {
//     this.entry.autoTranslateInfo = autoTranslateInfo;
//     this.entity.doc.languages = this.entity.doc.languages.map((entry) =>
//       entry.code === this.entry.code ? this.entry : entry
//     );
//   }
// }
