import React from "react";
import IconButton from "@mui/material/IconButton";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

export const TranslationFavoriteButton = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  return (
    <IconButton onClick={onClick}>
      <BookmarkBorderIcon />
    </IconButton>
  );
};
