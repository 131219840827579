import { TextFieldValueState } from "@/lib/mobx-mui-form/TextFieldValueState";
import { LoadableValue } from "@/lib/mobx/LoadableValue";
import { Router } from "@/lib/nemmp/starter/routing/Router";
import { debounce } from "@/lib/utils";
import { Feed } from "@/modules/data/types";
import { Workspace } from "@/modules/main/Workspace";
import { feedIdFromUrl } from "@/modules/rules/identifiers";
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";

export class PodcastSearchState {
  searchTermState = new TextFieldValueState();

  submissionState: LoadableValue<string> | null = null;

  searchResults: Feed[] | null = null;

  constructor(public workspace: Workspace, public router: Router) {
    makeObservable(this, {
      submissionState: observable,
      searchResults: observable,
      submit: action,
      isSubmitting: computed,
    });

    const debouncedSearch = debounce(700, () => {
      this.submit();
    });
    autorun(() => {
      if (this.searchTermState.value) {
        debouncedSearch();
      }
    });
  }

  submit() {
    const searchTerm = this.searchTermState.value;
    runInAction(() => {
      this.searchResults = null;
    });

    this.submissionState = new LoadableValue(async () => {
      const searchResults = await this.workspace.api.searchPodcasts(searchTerm);

      runInAction(() => {
        this.searchResults = searchResults;
      });

      return "ok";
    });
  }

  open() {
    this.router.goTo("search");
  }

  get isSubmitting() {
    return this.submissionState?.inProgress ?? false;
  }

  async goToFeed(feed: Feed) {
    this.router.goTo(feedIdFromUrl(feed.url));
  }
}
