export function getJsonFromLocalStorage(key: string): any {
  if (typeof window === "undefined") return null;

  const value = window.localStorage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.log("Error while parsing JSON", e);
      return null;
    }
  }
}
