import { PageCenter } from "@/lib/react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { observer } from "mobx-react";
import dynamic from "next/dynamic";
import { DashboardScreen } from "../dashboard/DashboardScreen";
import type { CxProps } from "../types";
import { BottomPanel } from "./BottomPanel";
import { HistoryItem } from "@/lib/nemmp/starter/routing/History";
import debug from "debug";

/**
 * MainRouter
 *
 * Renders the current screen based on the current route,
 * and maybe some other screens in the history for a smoother transition.
 */

const log = debug("routing:MainRouter");

type MainRouterProps = CxProps;

type Section = {
  code: string;
  label: string;
  // icon: React.ReactNode;
  content: (props: CxProps) => React.ReactNode;
};

const CenteredCircularProgress = () => (
  <PageCenter>
    <CircularProgress />
  </PageCenter>
);

const DQueueScreen = dynamic(() => import("../queue/QueueScreen"), {
  loading: () => <CenteredCircularProgress />,
});

const DLatestEpisodesScreen = dynamic(
  () => import("../subscriptions/LatestEpisodesScreen"),
  {
    loading: () => <CenteredCircularProgress />,
  }
);

const DSubscriptionsScreen = dynamic(
  () => import("../subscriptions/SubscriptionsScreen"),
  {
    loading: () => <CenteredCircularProgress />,
  }
);

const DPodcastSearchScreen = dynamic(
  () => import("../podcast/search/PodcastSearchScreen"),
  {
    loading: () => <CenteredCircularProgress />,
  }
);

const DTrendingPodcastsScreen = dynamic(
  () => import("../podcast/trending/TrendingPodcastsScreen"),
  {
    loading: () => <CenteredCircularProgress />,
  }
);

const DTrackRoute = dynamic(() => import("../track/TrackRoute"), {
  loading: () => <CenteredCircularProgress />,
});

const DFeedRoute = dynamic(() => import("../feed/FeedRoute"), {
  loading: () => <CenteredCircularProgress />,
});

const DSubscriptionRoute = dynamic(
  () => import("../subscriptions/SubscriptionRoute"),
  {
    loading: () => <CenteredCircularProgress />,
  }
);

const sections: Section[] = [
  {
    code: "dashboard",
    label: "Dashboard",
    content: (props: CxProps) => <DashboardScreen {...props} />,
  },
  {
    code: "queue",
    label: "Queue",
    // icon: <PlaylistPlayIcon />,
    content: (props: CxProps) => <DQueueScreen {...props} />,
  },
  {
    code: "latest",
    label: "Latest",
    // icon: <RecentIcon />,
    content: (props: CxProps) => <DLatestEpisodesScreen {...props} />,
  },
  {
    code: "podcasts",
    label: "Podcasts",
    // icon: <PodcastsIcon />,
    content: (props: CxProps) => <DSubscriptionsScreen {...props} />,
  },
  {
    code: "search",
    label: "Search",
    // icon: <SearchIcon />,
    content: (props: CxProps) => <DPodcastSearchScreen {...props} />,
  },
  {
    code: "trending-podcasts",
    label: "Trending Podcasts",
    // icon: <PodcastsIcon />,
    content: (props: CxProps) => <DTrendingPodcastsScreen {...props} />,
  },
];

// if (process.env["NODE_ENV"] === "development" && devModeConfig.devPane) {
//   sections.unshift({
//     code: "dev",
//     label: "Development",
//     icon: <HeadphonesIcon />,
//     content: (props: CxProps) => <DevelopmentScreen {...props} />,
//   });
// }

export const MainRouter = observer(({ cx }: MainRouterProps) => {
  const { router } = cx.wEnv;
  const { history } = router;

  const hItems = [...history.items];
  let hIndex = history.index;

  // If the current item is a dialog, don't render a screen for it
  if (router.route?.dialog) {
    // remove from end
    hItems.pop();
    hIndex -= 1;
  }

  // The extra history item is so that when navigating to a new item, the transition still works
  // hItems.push({ id: -1, path: null });

  return (
    <Box
      className="with-bottom-nav"
      sx={{
        position: "fixed",
        inset: 0,
        display: "grid",
        height: "100dvh",
        width: "100vw",
        gridTemplateRows: "1fr auto",
      }}
    >
      <div className="screen-stack">
        {getRenderableHistory(hItems, hIndex).map(
          ({ h, key, relative }, index) => (
            <div
              key={key}
              className="screen-stack-item"
              style={{
                position: "fixed",
                top: 48,
                bottom: 0,
                left: `${relative}00vw`,
                width: "100vw",
                // transition: "left 0.3s ease-in-out",
              }}
            >
              {/* <h1 style={{ textAlign: "center" }}>{index}</h1> */}
              <RouteScreen cx={cx} path={h.path ?? ""} />
            </div>
          )
        )}
      </div>

      <BottomPanel cx={cx} />
    </Box>
  );
});

// Figure out which history items to render
function getRenderableHistory(
  items: HistoryItem[],
  currentIndex: number
): { h: HistoryItem; key: string; relative: number }[] {
  const result: { h: HistoryItem; key: string; relative: number }[] = [];

  const alreadyHaveScreenFor = { track: false };

  // TODO: TranscriptSliceManager causes errors when present on two different screens at the same time,  so we disable hidden screens for now.
  const historyBackCount = 5;
  const historyForwardCount = 5;

  const add = (index: number, toStart: boolean = false) => {
    const key = items[index].path ?? "";
    if (result.some((r) => r.key == key)) return;

    // Make sure we don't have two track screen at the same time, because they conflict
    const discriminator = key.startsWith("track:") ? "track" : null;
    if (discriminator) {
      if (alreadyHaveScreenFor[discriminator]) return;
      alreadyHaveScreenFor[discriminator] = true;
    }

    const item = {
      h: items[index],
      key: key,
      relative: index - currentIndex,
    };
    if (toStart) {
      result.unshift(item);
    } else {
      result.push(item);
    }
  };

  // Add the current item
  let i = currentIndex;
  log(`getRenderableHistory: currentIndex=${currentIndex}`);
  log(JSON.stringify(items, null, 2));
  add(i);
  // result.push({ h: items[i], key: items[i].path ?? "", relative: 0 });

  // Add the previous 5 items
  while (i < items.length - 1 && result.length < 1 + historyBackCount) {
    i += 1;
    add(i);
    // result.push({
    //   h: items[i],
    //   key: items[i].path ?? "",
    //   relative: i - currentIndex,
    // });
  }

  i = currentIndex;

  // Add the next 3 items
  while (i > 0 && result.length < 1 + historyBackCount + historyForwardCount) {
    i -= 1;
    if (result.some((r) => r.key === (items[i].path ?? ""))) continue;
    add(i);
    // result.unshift({
    //   h: items[i],
    //   key: items[i].path ?? "",
    //   relative: i - currentIndex,
    // });
  }

  log(
    `getRenderableHistory: ${result.length} items`,
    JSON.stringify(result, null, 2)
  );

  return result;
}

const RouteScreen = observer(
  ({ cx, path }: MainRouterProps & { path: string }) => {
    const section =
      sections.find((s) => s.code === path) ??
      (path === "" ? sections[0] : null);

    if (section) {
      return <>{section.content({ cx })}</>;
    }

    if (path.startsWith("track:")) {
      return <DTrackRoute cx={cx} trackId={path} />;
    }
    if (path.startsWith("feed:")) {
      return <DFeedRoute cx={cx} feedId={path} />;
    }
    if (path.startsWith("subscription:")) {
      return <DSubscriptionRoute cx={cx} subscriptionId={path} />;
    }

    return (
      <div>
        Sorry, an error occurred. <br />
        Try reloading the app. <br />
        (invalid route: {path})
      </div>
    );
  }
);
