import { useEffect, useRef } from "react";
import { WebAudioPlayerState } from "./WebAudioPlayerState";
import debug from "debug";

const log = debug("player:AudioElement");

type AudioElementProps = {
  url?: string;
  continueFromTime?: number;
  onTimeUpdate?: (audioElement: HTMLAudioElement) => void;
  onEnd?: (audioElement: HTMLAudioElement) => void;
  audioPlayerState: WebAudioPlayerState;
  hidden?: boolean;
  autoplay?: boolean;
};

export function AudioElement({
  url,
  continueFromTime,
  onTimeUpdate,
  onEnd,
  audioPlayerState,
  hidden,
  autoplay,
}: AudioElementProps) {
  log("render", { url, continueFromTime, audioPlayerState });
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Execute on mount
  useEffect(() => {
    log("useEffect");
    const audioElement = audioRef.current;
    if (!audioElement) {
      log("no audio element");
      return;
    }
    if (!audioPlayerState.media) {
      log("setting media");
      audioPlayerState.init(audioElement);
    } else {
      log("media already set");
    }
    if (onTimeUpdate && audioElement) {
      audioElement.addEventListener("timeupdate", () =>
        onTimeUpdate(audioElement)
      );
    }
    if (onEnd && audioElement) {
      audioElement.addEventListener("ended", () => onEnd(audioElement));
    }

    const oldAudioPlayerState = audioPlayerState;

    return () => {
      log("useEffect(UNMOUNT)");
      oldAudioPlayerState.media = undefined;
    };
  }, [audioPlayerState]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement) {
      return;
    }
    if (continueFromTime) {
      log("Setting currentTime =", continueFromTime);
      audioElement.currentTime = continueFromTime;
    }
    if (typeof url === "undefined") {
      audioElement.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <>
      {/* <Info audioPlayerState={audioPlayerState} /> */}
      <audio
        key={audioPlayerState.id}
        ref={audioRef}
        controls
        autoPlay={autoplay}
        src={url}
        style={{ width: "100%", display: hidden ? "none" : "block" }}
      >
        Your browser does not support the <code>audio</code> element.
      </audio>
    </>
  );
}

// const Info = observer(
//   ({ audioPlayerState }: { audioPlayerState: AudioPlayerState }) => {
//     return (
//       <div>
//         <div>url: {audioPlayerState.url}</div>
//         <div>currentTime: {audioPlayerState.currentTime}</div>
//         <div>duration: {audioPlayerState.duration} </div>
//       </div>
//     );
//   }
// );
