import { z } from "zod";

export const TranscriptionJobZ = z.object({
  trackId: z.string(),
  startedAt: z.string(),
  params: z.any(),
  serviceCode: z.string(),
  completedAt: z.string().nullish(),
  duration: z.number().nullish(),
  results: z.any().nullish(),
});

export type TranscriptionJob = z.infer<typeof TranscriptionJobZ>;

export const TranscriptionJobDocZ = TranscriptionJobZ.extend({
  _id: z.string(),
  _rev: z.string(),
  type: z.literal("transcriptionJob"),
});

export type TranscriptionJobDoc = z.infer<typeof TranscriptionJobDocZ>;
