import { makeAutoObservable, observable, action } from "mobx";

export class ErrorState<M extends { message?: any }> {
  message: M | null = null;

  constructor() {
    this.clear();
    makeAutoObservable(this, {
      message: observable,
      set: action,
      clear: action,
    });
  }

  set(message: M) {
    this.message = message;
  }

  clear() {
    this.message = null;
  }
}

export const errorState = new ErrorState();
