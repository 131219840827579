import { IWEnv } from "../base/types";
import { processIncomingShare } from "./processIncomingShare";
import { WorkspacesManager } from "./WorkspacesManager";

export function attachBrowserDebugHooks<TWEnv extends IWEnv>(
  workspacesManager: WorkspacesManager<TWEnv>,
  wEnv: TWEnv
) {
  const w = window as any;

  // For debugging:
  // - deleteLocalData(); location.reload();
  // - receiveShare('{"accessKey":"xxx"}')

  w.wEnv = wEnv;
  w.database = wEnv.database;

  w.deleteLocalData = async () => {
    await workspacesManager.deleteLocalData();
    console.log("Local database destroyed, reloading page");
    window.location.href = "/app";
  };

  w.receiveShare = async (encodedPayload: string) => {
    const isSuccess = await processIncomingShare(
      workspacesManager,
      encodedPayload
    );
    console.log("Share received", isSuccess);
  };
}
