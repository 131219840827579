/**
 *   Implements switchable workspaces within an app.
 *
 *   The app defines how the workspace is built (buildNewEnv) and rendered (workspaceComponent).
 */
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { attachBrowserDebugHooks } from "./browserDebugHooks";
import { WorkspacesManager } from "./WorkspacesManager";
import { IWEnv } from "../base/types";

type GenericAppScreenProps<TWEnv extends IWEnv> = {
  renderWorkspace: (wEnv: TWEnv) => JSX.Element;
  workspacesManager: WorkspacesManager<TWEnv>;
};

function _GenericAppScreen<TWEnv extends IWEnv>(
  props: GenericAppScreenProps<TWEnv>
) {
  const { workspacesManager } = props;
  const { renderWorkspace } = props;
  const [wEnvs, setTWEnvs] = useState<TWEnv[]>([]);

  const replaceTWEnv = useRef<(newTWEnv: TWEnv) => void>();
  replaceTWEnv.current = (newTWEnv: TWEnv) => {
    console.log("Setting newTWEnv", newTWEnv.key);

    // Remove old app env; allow react components to unmount (we don't want multiple WRouter components as their event listeners might conflict)
    setTWEnvs([]);
    setTimeout(() => {
      setTWEnvs([newTWEnv]);
    }, 100);
  };

  useEffect(() => {
    const wEnv = workspacesManager.currentWEnv;

    // Called when the user switches to a new database
    console.log("switchPubSub: subscribe");
    workspacesManager.switchPubSub.subscribe((newWEnv: TWEnv) => {
      console.log("switchPubSub: received:", newWEnv.key);
      attachBrowserDebugHooks(workspacesManager, newWEnv);
      if (replaceTWEnv.current) {
        replaceTWEnv.current(newWEnv);
      }
    });

    setTWEnvs(wEnv ? [wEnv] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {wEnvs.map((wEnv, index) => (
        <div
          key={wEnv.key}
          style={{
            position: "absolute",
            inset: 0,
            opacity: index === 0 ? 1 : 0,
            transition: "opacity 0.5s ease-out",
          }}
        >
          {renderWorkspace(wEnv)}
        </div>
      ))}
    </>
  );
}

export const GenericAppScreen = observer(_GenericAppScreen);
