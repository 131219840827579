import { sleep } from "@/lib/utils/sleep";
import * as PouchDB from "pouchdb";

export async function upsertPouchDoc(
  db: PouchDB.Database<any>,
  id: string,
  payload: any
) {
  if (!db) throw new Error("db missing");
  try {
    const doc = await db.get(id);
    return await forceUpdatePouchDoc(db, { ...doc, ...payload });
  } catch (error: any) {
    if (isNotFound(error)) {
      return await createOrGetExistingPouchDoc(db, id, payload);
    }
    throw convertPouchDBError(error, `in upsert (${id})`);
  }
}

export async function findPouchDoc(db: PouchDB.Database<any>, id: string) {
  if (!db) throw new Error("db missing");
  try {
    const doc = await db.get(id);
    return doc;
  } catch (error: any) {
    if (isNotFound(error)) {
      return null;
    } else {
      throw convertPouchDBError(error, `in find (${id})`);
    }
  }
}

export async function findOrCreatePouchDoc(
  db: PouchDB.Database<any>,
  id: string,
  payload: any
) {
  if (!db) throw new Error("db missing");
  try {
    const doc = await db.get(id);
    return doc;
  } catch (error: any) {
    if (isNotFound(error)) {
      return createOrGetExistingPouchDoc(db, id, payload);
    } else {
      throw convertPouchDBError(error, `in findOrCreate (${id})`);
    }
  }
}

export async function createPouchDoc(db: PouchDB.Database<any>, doc: any) {
  if (!db) throw new Error("db missing");
  const { id, rev } = await normalizePouchDBErrors<any>(
    `in create (${JSON.stringify(doc).slice(0, 100)})`,
    async () => (await db.put(doc)) as any
  );
  return { ...doc, _id: id, _rev: rev };
}

export async function createOrGetExistingPouchDoc(
  db: PouchDB.Database<any>,
  id: string,
  payload: any
) {
  if (!db) throw new Error("db missing");
  return await db.put({ _id: id, ...payload }).then(
    // put successful
    ({ rev }) => ({ _id: id, _rev: rev, ...payload }),

    // put failed
    async (error: any) => {
      if (isConflict(error)) {
        // put failed because id already exists => fetch document
        return await findPouchDoc(db, id);
      } else {
        console.warn("error from db.put in createOrGetExisting:", error);
        // put failed with some other error
        throw convertPouchDBError(error, `in createOrGetExisting (${id})`);
      }
    }
  );
}

export async function forceUpdatePouchDoc(
  db: PouchDB.Database<any>,
  doc: PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta>,
  level = 1
): Promise<{ rev: string }> {
  const maxTries = 5;
  console.log("forceUpdate", level, doc._id, doc._rev);
  return await db.put(doc).then(
    (response: any) => response,
    async (error: any) => {
      if (isConflict(error)) {
        if (level > maxTries) {
          throw new Error(`forceUpdate failed after ${maxTries} retries`);
        }
        await sleep(Math.random() * 200 * level);
        const latestDoc: PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta> =
          await findPouchDoc(db, doc._id);

        const newDoc = { ...doc, _rev: latestDoc._rev };
        return forceUpdatePouchDoc(db, newDoc, level + 1);
      }
      throw convertPouchDBError(error, `in forceUpdate (${doc._id})`);
    }
  );
}

export async function forcePatchPouchDoc(
  db: PouchDB.Database<any>,
  doc: PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta>,
  newAttrsCb: (doc: any) => any,
  level = 1
): Promise<PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta>> {
  console.log("forcePatch", level, doc._id, doc._rev);
  const patchedDoc = { ...doc, ...newAttrsCb(doc) };
  return await db.put(patchedDoc).then(
    (response: any) => {
      return { ...patchedDoc, _rev: response.rev };
    },
    async (error: any) => {
      if (isConflict(error)) {
        if (level > 3) {
          throw new Error("forceUpdate failed after 3 retries");
        }
        // console.log(
        //   "forcePatch: conflict, getting latest revision:",
        //   "level:",
        //   level
        // );
        const latestDoc: PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta> =
          await db.get(doc._id);
        // console.log("forceUpdate: retrying with latest rev", latestDoc._rev);
        return forcePatchPouchDoc(db, latestDoc, newAttrsCb, level + 1);
      }
      throw convertPouchDBError(error, `in forcePatch (${doc._id})`);
    }
  );
}

export async function createOrOverwritePouchDoc(
  db: PouchDB.Database<any>,
  doc: PouchDB.Core.NewDocument<PouchDB.Core.AllDocsMeta> & {
    _id: string;
    _rev?: string;
  },
  level = 1
): Promise<{ id: string; rev: string }> {
  const { _rev, ...docWithoutRev } = doc;
  return await db.put(docWithoutRev).then(
    (result: any) => {
      console.log("createOrOverwrite", result);
      return { id: result.id, rev: result.rev };
    },
    async (error: any) => {
      console.warn("error:", error);
      if (isConflict(error) && level < 3) {
        const latestDoc: PouchDB.Core.ExistingDocument<PouchDB.Core.AllDocsMeta> =
          await db.get(doc._id);
        const { rev } = await forceUpdatePouchDoc(
          db,
          { ...doc, _rev: latestDoc._rev },
          level + 1
        );
        return { id: doc._id, rev };
      } else {
        throw convertPouchDBError(error, `in createOrOverwrite (${doc._id})`);
      }
    }
  );
}

export function isNotFound(error: any) {
  return error.error === "not_found" || error.name === "not_found";
}
export function isConflict(error: any) {
  return error.error === "conflict" || error.name === "conflict";
}

class PouchDBError extends Error {
  public additionalInfo;

  constructor(e: any, context: string) {
    const items: string[] = [];
    items.push(`PouchDBError ${context}`);
    if (e.status) items.push(e.status);
    if (e.name) items.push(e.name);
    if (e.error && e.error != e.name) items.push(e.error);
    if (e.message) items.push(e.message);
    if (e.reason && e.reason != e.message) items.push(e.reason);
    if (e.docId && e.docId != e.reason && e.docId !== e.message)
      items.push(e.docId);
    super(items.join(" - "));

    this.additionalInfo = { pouchdb: e };
  }

  get name() {
    return "PouchDBError";
  }
}

export async function normalizePouchDBErrors<R>(
  context: string,
  callback: () => Promise<R>
): Promise<R> {
  try {
    return await callback();
  } catch (e: any) {
    throw convertPouchDBError(e, context);
  }
}

export function convertPouchDBError(e: any, context: string): Error {
  if (e instanceof Error) return e;
  if (e.error && e.reason && e.status && e.name && e.message && e.stack) {
    return new PouchDBError(e, context);
  }
  return new Error(`Unknown error: ${JSON.stringify(e)}`);
}
