import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { LatestEpisodeCollection } from "@/modules/episode/LatestEpisodeCollection";
import { ExpandButtonLook } from "@/modules/look/ExpandButtonLook";
import { LatestEpisodesList } from "@/modules/subscriptions/LatestEpisodesList";
import { CxProps } from "@/modules/types";
import { observer } from "mobx-react";
import { useState } from "react";

export const ExpandableLatestEpisodesList = observer(
  ({
    cx,
    episodeCollection,
    collapsedLimit = 5,
  }: CxProps & {
    episodeCollection: LatestEpisodeCollection;
    collapsedLimit?: number;
  }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      recordEvent("expand-latest-episodes-list");
      setExpanded(!expanded);
    };

    return (
      <>
        <LatestEpisodesList
          cx={cx}
          episodeCollection={episodeCollection}
          limit={expanded ? undefined : collapsedLimit}
          expandButton={
            !expanded && (
              <ExpandButtonLook onClick={handleExpandClick}>
                Show more
              </ExpandButtonLook>
            )
          }
        />
      </>
    );
  }
);
