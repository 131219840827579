import { AudioPlayerState } from "@/lib/audio-player/AudioPlayerState";
import { buildPlayerState } from "@/lib/audio-player/buildPlayerState";
import debug from "debug";
import { action, autorun, makeObservable, observable } from "mobx";
import { Workspace } from "./../main/Workspace";

const log = debug("player:PlayerSwitchState");

export class PlayerSwitchState {
  playerState: AudioPlayerState = this.buildPlayerState();

  constructor(private workspace: Workspace) {
    makeObservable(this, {
      playerState: observable,
      playUrlFrom: action,
    });
    workspace.settingsState.then((settingsState) => {
      autorun(() => {
        this.playerState.applySettings(settingsState.audioSettings);
      });
    });
  }

  playUrlFrom(url: string, seconds: number | null, play?: boolean) {
    log("playUrlFrom", url, seconds, play);
    if (this.playerState.url !== "" && this.playerState.url !== url) {
      this.playerState.clearMedia();
      this.playerState = this.buildPlayerState();
    }
    this.playerState.playUrlFrom(url, seconds, play);
  }

  private buildPlayerState() {
    const playerState = buildPlayerState();

    if (this.workspace.settingsState?.value) {
      playerState.applySettings(
        this.workspace.settingsState.value.audioSettings
      );
    }
    return playerState;
  }

  // Callback for reporting to query the current player state
  get state() {
    return this.playerState.state;
  }
}
