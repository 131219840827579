import { Database } from "@/lib/mobx-pouch/Database";
import { GenericAppScreen } from "@/lib/nemmp/pouch-couch-sync/GenericAppScreen";
import { AppConfig } from "@/lib/nemmp/app-config/types";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { Router } from "../routing/Router";
import { WRouter } from "../routing/WRouter";
import type { StarterConfig } from "../types";
import { AppEnv } from "./AppEnv";
import { WEnv } from "./WEnv";
import { AppConfigContext } from "./contexts";
import dynamic from "next/dynamic";
import { initClient } from "./initClient";

const DynamicAppDialogSwitch = dynamic(() => import("./AppDialogSwitch"), {
  loading: () => <></>,
});

export const AppScreen = observer(
  <TWorkspace,>({
    appConfig,
    starterConfig,
  }: {
    appConfig: AppConfig;
    starterConfig: StarterConfig<TWorkspace>;
  }) => {
    const [router] = useState(new Router());
    const [appEnv, setAppEnv] = useState<AppEnv<TWorkspace> | null>(null);

    useEffect(() => {
      console.log("AppScreen: useEffect");
      if (typeof window !== "undefined") {
        initClient();
      }
    });

    const callbacks = useRef({
      buildNewWorkspaceEnv: (database: Database) => {
        return new WEnv(
          appConfig,
          database,
          router,
          starterConfig.workspaceConstructor
        );
      },
      beforeWorkspaceChange: async (): Promise<void> => {
        const dialogOpenBefore = router.dialog;

        await router.withSuppressedPopStateHandler(async () => {
          if (dialogOpenBefore) {
            await router.closeDialog();
          }

          // // Insert a history entry so that the pressing back from the workspace dialog will go to the root item (and not go back to an item in the old workspace, which would throw a 404)
          // if (router.route?.itemId !== "root") {
          //   router.goToItemId("root");
          // }

          // Insert history entry for the workspaces dialog
          if (dialogOpenBefore) {
            router.openDialog(dialogOpenBefore);
          }
        });
      },
    });

    useEffect(() => {
      (async () => {
        console.group("AppScreen: useEffect");
        const appEnv = await AppEnv.build(window.localStorage, appConfig, {
          apiBaseUrl: appConfig.apiBaseUrl ?? "",
          baseDbName: appConfig.code,
          buildNewWorkspaceEnv: (database) =>
            callbacks.current.buildNewWorkspaceEnv(database),
          beforeWorkspaceChange: () =>
            callbacks.current.beforeWorkspaceChange(),
        });
        console.log("setting appEnv");
        setAppEnv(appEnv);

        appEnv.statsTracker.onOpen();

        await appEnv.workspacesManager.initialize();

        const wEnv = appEnv.workspacesManager.currentWEnv;
        if (!wEnv) throw new Error("No current workspace");
        console.groupEnd();
      })();
    }, []);

    if (!appEnv) {
      return null;
    }

    return (
      <div className={"app"}>
        <AppConfigContext.Provider value={appConfig}>
          <DynamicAppDialogSwitch
            cx={{
              appConfig,
              router,
              workspacesManager: appEnv.workspacesManager as any,
            }}
          />
          <GenericAppScreen
            workspacesManager={appEnv.workspacesManager}
            renderWorkspace={(wEnv: WEnv<TWorkspace>) => (
              <WRouter
                key={wEnv.key}
                cx={{
                  appConfig,
                  router,
                  starterConfig,
                  wEnv,
                }}
              />
            )}
          />
        </AppConfigContext.Provider>
      </div>
    );
  }
);
