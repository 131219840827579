import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { isMissing, isPresent } from "@/lib/prelude";
import { action, makeObservable, observable } from "mobx";
import { ListenerEpisode } from "../episode/ListenerEpisode";
import { Workspace } from "@/modules/main/Workspace";
import { Queue } from "../queue/Queue";
import { Track } from "@/modules/track/Track";
import debug from "debug";

const log = debug("player:PlayingTrackController");

// Manages the playing episode state
// - updates listening position
// - when an episode starts to play, resume from last listening position
//
// Referenced by: workspace.playingTrackController

type PlaybackTiming = {
  currentTime: number;
  duration: number | null;
};

export class PlayingTrackController {
  currentTrack: Track | null = null;

  listenerEpisode: ListenerEpisode | null = null;

  constructor(public workspace: Workspace) {
    makeObservable(this, {
      currentTrack: observable,
      _handlePause: action,
      _handleTimeUpdate: action,
    });
    const { playerState } = workspace;

    playerState.addEventListener("play", (event: any) => {
      recordEvent("media-event-play", {
        currentTime: playerState.currentTime,
        percent: isPresent(playerState.duration)
          ? Math.round((playerState.currentTime / playerState.duration) * 100)
          : null,
      });
    });

    playerState.addEventListener("pause", (event: any) => {
      recordEvent("media-event-pause", {
        currentTime: playerState.currentTime,
        percent: isPresent(playerState.duration)
          ? Math.round((playerState.currentTime / playerState.duration) * 100)
          : null,
      });
      this._handlePause({
        currentTime: playerState.currentTime,
        duration: playerState.duration,
      });
    });

    playerState.addEventListener("timeupdate", (event: any) =>
      this._handleTimeUpdate({
        currentTime: playerState.currentTime,
        duration: playerState.duration,
      })
    );

    playerState.addEventListener("ended", (event: any) => {
      recordEvent("media-event-ended", {
        currentTime: playerState.currentTime,
        percent: isPresent(playerState.duration)
          ? Math.round((playerState.currentTime / playerState.duration) * 100)
          : null,
      });
      this._handleComplete();
    });

    playerState.addEventListener("error", (event: any) => {
      recordEvent("media-event-error", {
        error: event.error ?? playerState.error,
      });
    });

    [
      "durationchange",
      "loadedmetadata",
      "loadeddata",
      "canplay",
      "canplaythrough",
      "ratechange",
      "seeking",
      "seeked",
    ].forEach((eventName) => {
      playerState.addEventListener(eventName, () =>
        recordEvent(`media-event-${eventName}`)
      );
    });
  }

  get playerState() {
    return this.workspace.playerState;
  }

  async clearCurrent() {
    const { playerState } = this.workspace;
    this.currentTrack = null;
    playerState.clearMedia();
  }

  async _handlePause(detail: PlaybackTiming) {
    this._updateListeningPosition(detail, true);
  }

  _handleTimeUpdate(detail: PlaybackTiming) {
    this._updateListeningPosition(detail);
    this._checkIfComplete(detail);
  }

  get isPlaying() {
    return this.workspace.playerState.playing;
  }

  isPlayingTrack(track: Track | undefined | null) {
    log("isPlayingTrack", this.isPlaying, this.currentTrack?.trackId);
    if (isMissing(track)) return false;
    const r =
      this.isPlaying &&
      this.currentTrack &&
      this.currentTrack.trackId === track.trackId;
    return r;
  }

  isCurrentTrack(track: Track | undefined | null): boolean {
    if (isMissing(track)) return false;
    const r = this.currentTrack && this.currentTrack.trackId === track.trackId;
    return r ?? false;
  }

  _updateListeningPosition(detail: PlaybackTiming, save = false) {
    if (!this.listenerEpisode) return;
    this.listenerEpisode.updateListeningPosition(
      detail.currentTime,
      detail.duration,
      {
        save,
      }
    );
  }

  _checkIfComplete(detail: PlaybackTiming) {
    // log("checkIfComplete", detail.currentTime, detail.duration);
    if (detail.currentTime === detail.duration) {
      log("Episode complete");
      this._handleComplete();
    }
  }

  _handleComplete() {
    this.workspace.queue.then((queue: Queue) => {
      queue.handleEpisodeComplete(this.currentTrack!);
    });
  }
}
