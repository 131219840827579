import { sleep } from "@/lib/utils/sleep";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import {
  AudioPlayerSettings,
  AudioPlayerState,
  AudioPlayerStateCode,
} from "./AudioPlayerState";
import { attachPlayerEventListeners } from "./player-event-listeners";

const MEDIA_NONE = 0;
const MEDIA_STARTING = 1;
const MEDIA_RUNNING = 2;
const MEDIA_PAUSED = 3;
const MEDIA_STOPPED = 4;

export type TMedia = any;

export class CordovaAudioPlayerState implements AudioPlayerState {
  type = "cordova" as const;
  media: TMedia | null = null;
  url = "";
  playing = false;
  currentTime = 0;
  duration: number | null = null;
  playbackRate = 1;
  state: AudioPlayerStateCode = "idle";

  _onMediaCallbacks: ((media: HTMLMediaElement) => void)[] = [];

  // Event target for dispatching events to outside listeners
  private _eventTarget: EventTarget = new EventTarget();

  private _timeUpdateTimer: any = null;
  private _mediaStatus: null | number = null;
  private _mediaCounter = 0;
  private _onMediaError: null | ((error: any) => void) = null;
  private _mediaEventTarget = new EventTarget();
  private _seekToSecondsWhenReady: null | number = null;

  constructor() {
    makeAutoObservable(this, {
      url: observable,
      playing: observable,
      currentTime: observable,
      duration: observable,
      playbackRate: observable,
      state: observable,
      disabled: computed,
      _handleTimeUpdate: action,
      _handleDurationChange: action,
      play: action,
      playFrom: action,
      playUrlFrom: action,
      pause: action,
      addTime: action,
      clearMedia: action,
      seekTo: action,
      updateState: action,
      setPlaybackRate: action,
    });

    if (typeof window !== "undefined") {
      attachPlayerEventListeners(window, this);
    }
  }

  get error() {
    return null;
  }

  // The media is not playable, the controls should be disabled
  get disabled() {
    return this.url === "";
  }

  private _initMedia(src: string) {
    const mediaCounter = (this._mediaCounter += 1);
    const successCallback = (...args: any) => {
      // Finished
      console.log("M >", mediaCounter, "Success", ...args);
    };
    const failureCallback = (err: any) => {
      console.error(
        "M >",
        mediaCounter,
        "Media Failure callback",
        JSON.stringify(err)
      );
      this._dispatchMediaEvent("error", { error: err });
      if (this._onMediaError) {
        this._onMediaError(err);
      }
    };
    const statusCallback = (status: number) => {
      console.log("M >", mediaCounter, "Status", status);
      this._mediaStatus = status;
      if (status === MEDIA_RUNNING) {
        this.updateState("playing");
        this._dispatchMediaEvent("running");
        if (this._seekToSecondsWhenReady !== null) {
          this.media.seekTo(this._seekToSecondsWhenReady * 1000);
          this._seekToSecondsWhenReady = null;
        }
        this._handlePlay();
      } else if (status === MEDIA_PAUSED) {
        this.updateState("paused");
        this._dispatchMediaEvent("paused");
        this._handlePause();
      } else if (status === MEDIA_STOPPED) {
        this.updateState("paused");
        this._dispatchMediaEvent("stopped");
      } else if (status === MEDIA_STARTING) {
        this.updateState("loading");
        this._dispatchMediaEvent("starting");
      } else if (status === MEDIA_NONE) {
        this.updateState("idle");
        this._dispatchMediaEvent("none");
      }
    };
    const durationUpdateCallback = (d: number) => {
      console.log("M >", mediaCounter, "Duration update", d);
      this._handleDurationChange(d);
    };

    const Media = (window as any).Media;

    console.log("M >", mediaCounter, "instantiating media");
    this._mediaStatus = null;
    this.media = new Media(
      src,
      successCallback,
      failureCallback,
      statusCallback,
      durationUpdateCallback
    );

    console.log("M >", mediaCounter, "media getDuration");
    const duration = this.media.getDuration();
    if (duration > 0) {
      this._handleDurationChange(duration);
    } else {
      console.log("M >", mediaCounter, "Duration:", duration);
    }
  }

  get isReady() {
    return true;
  }

  // Called when:
  // - received play or pause event from the media player
  // - when our scheduled timer fires
  //
  async _checkTime() {
    // Schedule the next check, in case the getCurrentPosition call fails
    if (this.playing) {
      if (this._timeUpdateTimer) clearTimeout(this._timeUpdateTimer);
      this._timeUpdateTimer = setTimeout(() => {
        console.warn("M >", "Fallback time updater fired");
        this._timeUpdateTimer = null;
        this._checkTime();
      }, 2000);
    }

    if (!this.media) return;

    const position = await this._getCurrentPosition();

    if (position !== null) {
      this._handleTimeUpdate(position);
    }

    // Now we have the latest time, we can schedule the next check at the next whole second
    if (this.playing) {
      const delayMs = position === null ? 1000 : 1000 - (position % 1) * 1000;
      console.log("M >", "Scheduling next time check for", delayMs, "ms");
      if (this._timeUpdateTimer) clearTimeout(this._timeUpdateTimer);
      this._timeUpdateTimer = setTimeout(() => {
        this._timeUpdateTimer = null;
        this._checkTime();
      }, delayMs);
    }
  }

  _getCurrentPosition(): Promise<number | null> {
    return new Promise((resolve, reject) => {
      this.media.getCurrentPosition(
        (position: number) => {
          resolve(position < 0 ? null : position);
        },
        (e: any) => {
          console.log("M >", "Error getting pos: " + e);
          reject(e);
        }
      );
    });
  }

  async _handlePlay() {
    console.log("M >", "_handlePlay");
    runInAction(() => (this.playing = true));
    await this._checkTime();
    this._eventTarget.dispatchEvent(new CustomEvent("play"));
  }

  async _handlePause() {
    console.log("M >", "_handlePause");
    runInAction(() => (this.playing = false));
    await this._checkTime();
    this._eventTarget.dispatchEvent(new CustomEvent("pause"));
  }

  _handleTimeUpdate(rawT: number | null) {
    console.log("M >", "_handleTimeUpdate", rawT);
    if (rawT === null || rawT < 0) return;
    const t = Math.round(rawT);
    if (this.currentTime === t) return;
    console.log("M >", "time changed", t);
    this.currentTime = t;
    this._eventTarget.dispatchEvent(
      new CustomEvent("timeupdate", {
        detail: { currentTime: t },
      })
    );
  }
  _handleDurationChange(rawDuration: number) {
    console.log("M >", "_handleDurationChange", rawDuration);
    const newDuration = rawDuration < 0 ? null : Math.round(rawDuration);
    if (newDuration === this.duration) return;
    console.log("M >", "duration changed", newDuration);
    if (typeof newDuration === "number" && newDuration.toString() !== "NaN") {
      this.duration = newDuration;
    } else {
      this.duration = null;
    }

    this._eventTarget.dispatchEvent(
      new CustomEvent("durationchange", {
        detail: { duration: this.duration },
      })
    );
  }
  _handleRateChange(r: number) {
    console.log("M >", "_handleRateChange", r);
    this.playbackRate = r;
    this._eventTarget.dispatchEvent(
      new CustomEvent("ratechange", {
        detail: { playbackRate: this.playbackRate },
      })
    );
  }

  play() {
    console.log("M >", "play()");
    if (this.media) {
      if (this.currentTime !== null) {
        this.media.seekTo(this.currentTime * 1000);
      }
      this.updateState("loading");
      this.media.play();
    } else {
      console.log("M >", "no media to play");
    }
  }

  playFrom(seconds: number) {
    console.log("M >", "playFrom", seconds);
    if (this.media) {
      this.media.seekTo(seconds * 1000);
      this.play();
    } else {
      console.log("M >", "no media to play");
    }
  }
  playUrlFrom(url: string, seconds: number | null, play = true) {
    console.log("M >", "playUrlFrom", url, seconds, play);
    this.url = url;

    this.clearMedia();
    this._initMedia(url);

    this.currentTime = seconds ?? 0;

    if (play) {
      if (seconds !== null && seconds > 0) {
        if (this._mediaStatus === null || this._mediaStatus < MEDIA_RUNNING) {
          // The media is not ready yet, seek as soon as we get a MEDIA_RUNNING status
          this._seekToSecondsWhenReady = seconds;
        } else {
          this.media.seekTo(seconds * 1000);
        }
      }
      this.play();
    } else {
      // We don't need to start playing, but it's good to get the player ready.
      // The seekTo() call will trigger the player initialization.
      // NOTE: `play` and `seekTo` must not be called at the same time, due to a bug:
      //       https://github.com/apache/cordova-plugin-media/issues/364
      this.media.seekTo((seconds ?? 0) * 1000);
    }
  }

  pause() {
    console.log("M >", "pause()");
    if (this.media) {
      this.media.pause();
    } else {
      console.log("M >", "no media to pause");
    }
  }
  addTime(seconds: number) {
    console.log("M >", "addTime", seconds);
    this.seekTo(this.currentTime + seconds);
  }
  clearMedia() {
    console.log("M >", "clearMedia()");
    if (this.media) {
      if (this.playing) {
        this.media.stop();
      }
      this.media.release();
      this.media = null;
    }

    this.playing = false;
    this.currentTime = 0;
    this.duration = null;
  }

  seekTo(seconds: number) {
    console.log("M >", "seekTo", seconds);
    if (this.media) {
      this.currentTime = seconds;
      if (this._mediaStatus === null || this._mediaStatus < MEDIA_RUNNING) {
        // The media is not ready yet, seek as soon as we get a MEDIA_RUNNING status
        this._seekToSecondsWhenReady = seconds;
      } else {
        this.media.seekTo(seconds * 1000);
      }
    } else {
      console.log("M >", "no media to seek");
    }
  }

  rewind({ shift, alt }: { shift: boolean; alt: boolean }) {
    const seconds = shift ? 30 : alt ? 10 : 5;
    this.addTime(-seconds);
  }

  forward({ shift, alt }: { shift: boolean; alt: boolean }) {
    const seconds = shift ? 30 : alt ? 10 : 5;
    this.addTime(seconds);
  }

  togglePlay() {
    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  playSegment(start: number, end: number) {
    const media = this.media;
    if (!media) {
      console.warn("No media to play");
      return;
    }

    listenForNextOccurrence(this._eventTarget, "timeupdate")
      .then(() => {
        // (step 2) Once timeupdate is done, start playing.
        this.play();
        return listenForNextOccurrence(media, "play");
      })
      .then(() => {
        // (step 3) Once playing is started, schedule to pause it at the end of the word.
        const timeDiff = end - this.currentTime;
        return sleep(timeDiff * 1000);
      })
      .then(() => {
        // (step 4) Timeout fired, we should be at the end of the word => pause playing.
        this.pause();
      });

    // (step 1) Kick off chain of events above -> (step 2)
    this.media.seekTo(start * 1000);
  }

  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject
  ): void {
    this._eventTarget.addEventListener(type, listener);
  }

  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject
  ): void {
    this._eventTarget.addEventListener(type, listener);
  }

  private _dispatchMediaEvent(name: string, detail: any = null) {
    this._mediaEventTarget.dispatchEvent(
      new CustomEvent(name, { detail: detail })
    );
  }

  onNextPlay(callback: () => void) {
    this._eventTarget.addEventListener("play", callback);
  }

  get preciseCurrentTime() {
    if (this.media) {
      return this.media.currentTime;
    }
    return undefined;
  }

  updateState(newState: AudioPlayerStateCode) {
    this.state = newState;
  }

  setPlaybackRate(rate: number) {
    this.playbackRate = rate;
    this.media.playbackRate = rate;
  }

  applySettings(settings: AudioPlayerSettings) {
    // this.onMedia((media) => {
    //   if (typeof settings.volume === "number") {
    //     media.volume = settings.volume;
    //   }
    //   if (typeof settings.playbackRate === "number") {
    //     media.playbackRate = settings.playbackRate;
    //   }
    // });
  }
}

const listenForNextOccurrence = (
  element: EventTarget,
  name: string
): Promise<Event> => {
  return new Promise((resolve) => {
    const modifiedListener = (event: Event) => {
      element.removeEventListener(name, modifiedListener);
      resolve(event);
    };
    element.addEventListener(name, modifiedListener);
  });
};
