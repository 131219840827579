import Box from "@mui/material/Box";

export const ProgressBox = ({
  listeningPositionPercent,
}: {
  listeningPositionPercent: number | null;
}) => {
  return (
    <Box sx={{ height: "5px", width: "80px" }}>
      {listeningPositionPercent !== null && (
        <Box
          sx={{
            height: "4px",
            background: "#33E",
            borderRadius: "2px",
          }}
          style={{
            width: `${listeningPositionPercent}%`,
          }}
        />
      )}
    </Box>
  );
};
