import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

type TrackListLookProps = {
  children: React.ReactNode;
  expandButton?: React.ReactNode;
  loadingMore?: boolean;
};

export const TrackListLook = ({
  children,
  expandButton,
  loadingMore,
}: TrackListLookProps) => {
  return (
    // -1 to break out of standard text container, and allow room for the background of the highlighted item
    <Box sx={{ mx: -1 }}>
      <List sx={{ m: 0, p: 0 }}>{children}</List>
      {expandButton && (
        <Stack sx={{ mx: 2, mt: -2, textAlign: "center" }}>
          {expandButton}
        </Stack>
      )}
      {loadingMore && <Skeleton />}
    </Box>
  );
};
