import { ChatGptNotice } from "@/modules/dictionary/search/ChatGptNotice";
import { DictionarySearchState } from "@/modules/dictionary/search/DictionarySearchState";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { KaikkiCopyrightNotice } from "./KaikkiCopyrightNotice";
import ResultsTable, { TranslationWrapper } from "./ResultsTable";
import SearchBox from "./SearchBox";
import { LoadableSkeleton } from "@/lib/mobx-mui/loadable-skeleton/loadable-skeleton";
import { LoadableSpinnerBlock } from "@/lib/mobx-mui/loadable-skeleton/loadable-spinner-block";
import { LoadableMessageBlock } from "@/lib/mobx-mui/loadable-skeleton/loadable-message-block";
import { TextFieldMobx } from "@/lib/mobx-mui-form/TextFieldMobx";

/*

    SearchAndResultsBox

    Responsibilities:

    - Auto-focus on the search input field when the page loads.
    - Accepting an initial search term as a prop to pre-populate the search box and perform an initial search.
    - Handling search term input changes, calling the dictionary, updating the displayed results accordingly.
    - Providing a text field for entering a custom English translation (if the user doesn't like any of the search results)
    - Enabling the submission of a translation pair (source term and its translation) if both fields are filled.

    Uses:

    - SearchBox - display the search box and handle search term input changes (left-hand side input)
    - ResultsTable - display search results and allow the user to pick a result
    - DictLoader - display a loading indicator while the dictionary is being loaded

*/

// Auto-focus
// - on DOMContentLoaded, focus and select the #q element
// TODO: move into component
if (typeof window !== "undefined") {
  document.addEventListener("DOMContentLoaded", function () {
    const qInput: any = document.getElementById("q");
    if (qInput) {
      qInput.focus();
      qInput.select();
    }
  });
}

export type SearchAndResultsBoxProps = {
  dictionarySearchState: DictionarySearchState;
  initialSearchTerm?: string;
  onClear?: () => void;
  onResultPick?: (source: string, translation: string) => void;
  translationWrapper?: TranslationWrapper;
  sourceLang: string | null;
  targetLang: string | null;
};

export const SearchAndResultsBox = observer(
  ({
    dictionarySearchState,
    onClear,
    onResultPick,
    translationWrapper,
  }: SearchAndResultsBoxProps) => {
    const submit = () => {
      if (dictionarySearchState.isTranslationSubmittable) {
        onResultPick?.(
          dictionarySearchState.queryInput.value,
          dictionarySearchState.translationInput.value
        );
      }
    };

    const ref = useRef<HTMLTableElement>(null);
    const [minHeight, setMinHeight] = useState<number>(400);

    // Allow the results table to grow the dialog to the maximum,
    // but do not shrink the dialog if the input changes to avoid flickering.
    useEffect(() => {
      if (ref.current) {
        const observer = new ResizeObserver(() => {
          console.log("RESIZEd");
          if (!ref.current) {
            console.log("no ref");
            return;
          }
          const root = ref.current.closest(".MuiDialogContent-root");
          if (!root) {
            console.log("root not found");
            return;
          }

          const header = root.querySelector(".MuiStack-root");
          if (!header) {
            console.log("header not found");
            return;
          }

          console.log("root", root.clientHeight);
          console.log("header", header.clientHeight);
          const height = root.clientHeight; // - header.clientHeight;
          if (height && height > minHeight) {
            setMinHeight(height);
          }
        });
        observer.observe(ref.current);
        return () => observer.disconnect();
      }
      return () => {};
    }, [ref.current]);

    if (!dictionarySearchState.results) return null;

    return (
      <>
        <div id="wrapper" style={{ minHeight }} ref={ref}>
          <Stack direction={"row"} justifyContent="center" gap={1}>
            <SearchBox
              textFieldState={dictionarySearchState.queryInput}
              onClear={onClear}
              onSubmit={() => submit()}
            />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <TextFieldMobx
                state={dictionarySearchState.translationInput}
                id="tr"
                name="tr"
                type="text"
              />
            </form>
          </Stack>

          <div style={{ minHeight: 400, marginTop: "8px" }}>
            <LoadableMessageBlock
              loadable={dictionarySearchState.results}
              loadingMessage="Loading translations..."
              renderLoaded={(results) => (
                <>
                  {results && results.results.length > 0 && (
                    <>
                      <ResultsTable
                        results={results.results}
                        onResultPick={onResultPick}
                        translationWrapper={translationWrapper}
                      />
                      {results.provider === "kaikki" && (
                        <KaikkiCopyrightNotice />
                      )}
                      {results.provider === "chatgpt-3.5" && <ChatGptNotice />}
                    </>
                  )}
                </>
              )}
            />
          </div>
        </div>
      </>
    );
  }
);
