import { useState } from "react";
import { ContactDialog } from "./ContactDialog";

export type TContactLinkProps = {
  label?: string;
};

export function ContactLink(props: TContactLinkProps) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button onClick={handleClickOpen} {...props}>
        {props.label}
      </button>
      <ContactDialog open={open} onClose={handleClose} />
    </>
  );
}
