import { observer } from "mobx-react";
import { AppBarLayout } from "../app/AppBarLayout";
import { StarterCx } from "../app/contexts";
import { DialogSwitch } from "./DialogSwitch";

export const WScreenSwitch = observer(
  <TWorkspace,>({ cx }: { cx: StarterCx<TWorkspace> }) => {
    // console.log("ScreenSwitch");
    const { starterConfig, wEnv } = cx;

    const MainScreen = starterConfig.mainScreenComponent;

    return (
      <AppBarLayout cx={cx}>
        <MainScreen cx={cx} />
        <DialogSwitch cx={cx} />
      </AppBarLayout>
    );

    // return router.route?.isRoot ? (
    // <AppBarLayout wEnv={wEnv}>
    //   {/* <ItemScreenContainer wEnv={wEnv} />
    //   <ItemScreenDialogSwitch wEnv={wEnv} /> */}
    //   <MainScreen wEnv={wEnv} />
    // </AppBarLayout>;
    // ) : (
    //   <div>Not found</div>
    // );
  }
);
