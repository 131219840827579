import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { CxProps } from "../types";

const fadeOutDurationSeconds = 15;

//---

type RefreshStateDisplayProps = CxProps;

export const RefreshStateDisplay = observer(
  ({ cx }: RefreshStateDisplayProps) => {
    const { refreshState } = cx.wEnv.workspace;

    const [visible, setVisible] = useState(refreshState.isRefreshing);

    useEffect(() => {
      if (refreshState.isRefreshing) {
        setVisible(true);
      } else {
        setTimeout(() => {
          setVisible(false);
        }, fadeOutDurationSeconds * 1000);
      }
    }, [refreshState.isRefreshing]);

    if (!visible) {
      return null;
    }

    return (
      <ListItem sx={{ m: 0, p: 0, px: 1, height: 0, overflow: "visible" }}>
        <ListItemText>
          <Typography
            variant="body2"
            sx={{
              fontSize: 12,
              opacity: refreshState.isRefreshing ? 0.75 : 0,
              transition: `all ${fadeOutDurationSeconds}s ease`,
              mt: 1,
            }}
          >
            {refreshState.text}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  }
);
