import PauseIcon from "@mui/icons-material/Pause";
import PlayIcon from "@mui/icons-material/PlayArrow";
import IconButton from "@mui/material/IconButton";
import { observer } from "mobx-react";
import type { CxProps } from "../types";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";

type PlayingTrackButtonProps = CxProps;

export const PlayingTrackButton = observer(
  ({ cx }: PlayingTrackButtonProps) => {
    const { workspace } = cx.wEnv;
    const { playerState: player } = workspace;

    const Icon = player?.playing ? PauseIcon : PlayIcon;
    return (
      <IconButton
        onClick={(event: any) => {
          event.stopPropagation();
          if (!player) return;
          if (player.playing) {
            recordEvent("playing-track-bar-pause-button-clicked");
            player.pause();
          } else {
            recordEvent("playing-track-bar-play-button-clicked");
            player.play();
          }
        }}
        disabled={!player || player.disabled}
        sx={{ borderRadius: 0 }}
      >
        <Icon sx={{ fontSize: "48px" }} />
      </IconButton>
    );
  }
);
