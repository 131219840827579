import { DateTime } from "luxon";
import { action, makeAutoObservable, observable } from "mobx";

export class SyncState {
  changesCount: any = 0;
  error: any = null;
  seq: any = null;
  lastUpdateTime: any = null;

  constructor() {
    makeAutoObservable(this, {
      changesCount: observable,
      error: observable,
      seq: observable,
      lastUpdateTime: observable,
      start: action,
      recordChange: action,
      recordComplete: action,
      recordError: action,
    });
  }

  start() {
    this.changesCount = 0;
    this.error = null;
  }

  recordChange(change: { seq: any }) {
    this.changesCount++;
    this.error = null;
    this.seq = change.seq;
    this.lastUpdateTime = DateTime.now;
  }

  recordComplete(_info: any) {
    null;
  }

  recordError(error: any) {
    this.error = JSON.stringify(error);
  }
}
