function Link(props: {
  href: string;
  blank?: boolean;
  children: React.ReactNode;
}) {
  const { href, blank, children } = props;
  if (blank) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  } else {
    return <a href={href}>{children}</a>;
  }
}

export function KaikkiCopyrightNotice() {
  return (
    <footer style={{ fontSize: 12, marginTop: 4 }}>
      <div>
        Data licensed under{" "}
        <a
          href="//creativecommons.org/licenses/by-sa/3.0/"
          rel="license noreferrer"
          target="_blank"
          title="Creative Commons Attribution-ShareAlike License 3.0"
        >
          CC-BY-SA 3.0
        </a>
        ; based on{" "}
        <Link href="http://www.wiktionary.org" blank>
          Wiktionary
        </Link>{" "}
        via{" "}
        <Link href="https://kaikki.org/dictionary/index.html" blank>
          kaikki.org
        </Link>
        .
      </div>
    </footer>
  );
}
