import { AudioPlayerState } from "@/lib/audio-player/AudioPlayerState";
import { DialogState, LoadableValue } from "@/lib/mobx";
import { CustomDialogState } from "@/lib/mobx/CustomDialogState";
import {
  recordEvent,
  reportingQueue,
} from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { StarterCx, WEnv } from "@/lib/nemmp/starter";
import { withCache } from "@/lib/utils/caching";
import { localStorageUtils } from "@/lib/utils/localStorageUtils";
import { memoizeOn } from "@/lib/utils/memoize";
import { randomName } from "@/lib/utils/random";
import { FeedInfoClient } from "@/modules/data/types";
import { ListenerEpisode } from "@/modules/episode/ListenerEpisode";
import { PodcastSearchState } from "@/modules/podcast/search/PodcastSearchState";
import { SettingsState } from "@/modules/settings/SettingsState";
import { RefreshState } from "@/modules/subscriptions/RefreshState";
import { Subscription } from "@/modules/subscriptions/Subscription";
import {
  SubscriptionCollection,
  buildSubscriptionCollection,
} from "@/modules/subscriptions/SubscriptionCollection";
import { refreshAllSubscriptions } from "@/modules/subscriptions/refreshAllSubscriptions";
import { ITrackWorkspace } from "@/modules/track/ITrack";
import { buildTrackStateFromApe } from "@/modules/track/buildTrackStateFromApe";
import debug from "debug";
import { action, makeAutoObservable, observable } from "mobx";
import { API } from "../api/api-client";
import { LatestEpisodeCollection } from "../episode/LatestEpisodeCollection";
import {
  ListenerEpisodeCollection,
  buildListenerEpisodeCollection,
} from "../episode/ListenerEpisodeCollection";
import { PodcastEpisodeTrack } from "../episode/PodcastEpisodeTrack";
import { TrackLearnerState } from "../learn/TrackLearnerState";
import { OfflineState } from "../offline/OfflineState";
import { OnboardingState } from "../onboarding/OnboardingState";
import { PlayerSwitchState } from "../player/PlayerSwitchState";
import { PlayingTrackController } from "../playing-track/PlayingTrackController";
import { Queue } from "../queue/Queue";
import { makeTrackCurrent } from "../queue/makeTrackCurrent";
import { getTrackFeedAndItemInfoFromSubscriptions } from "../subscriptions/getTrackFeedAndItemInfoFromSubscriptions";
import { Track } from "../track/Track";
import { FeedItemAttributes } from "../types";
import { WordSearchEntryState } from "../word-search/WordSearchEntryState";
import { FeaturedEpisodeCollection } from "./../featured/FeaturedEpisodeCollection";
import { WorkspaceDialogState } from "@/modules/main/WorkspaceDialogState";

// Workspace relevant to the podcast playing app.
// A user can switch between multiple workspaces, each one has its own PouchDB database.
export class Workspace implements ITrackWorkspace {
  api: API;
  flexibleDialog = new CustomDialogState<WorkspaceDialogState>();

  // Data containers
  subscriptionCollection: SubscriptionCollection;
  listenerEpisodeCollection: ListenerEpisodeCollection;
  queue: LoadableValue<Queue>;
  trackCache = new Map<string, PodcastEpisodeTrack>();

  // Fixed States / controllers
  playerSwitchState: PlayerSwitchState;
  playingTrackController: PlayingTrackController; // persist listening position

  // Variable States
  currentSubscription: Subscription | null = null;
  trackLearnerState: TrackLearnerState | null = null;

  // Will be set by WordSearchDialog is rendered
  wordSearchDialogState: WordSearchEntryState | null = null;

  // Podcast search
  // (the state must be preserved when clicks on a result then navigates back)
  podcastSearchState = new PodcastSearchState(this, this.wEnv.router);

  onboardingState: OnboardingState;
  earlyAccessDialogState = new DialogState();

  refreshState = new RefreshState();
  offlineState: OfflineState;

  featuredEpisodeCollection: LoadableValue<FeaturedEpisodeCollection>;
  feedbackDialogState = new DialogState();

  settingsState: LoadableValue<SettingsState>;

  // --- CONSTRUCTOR ---

  constructor(
    public wEnv: WEnv<Workspace>,
    public options: { demo?: boolean; admin?: boolean } = {}
  ) {
    const { appConfig } = wEnv;

    // API
    if (!wEnv.appConfig.apiUrl) throw new Error("appConfig.apiUrl not set");
    const apiUrl = wEnv.appConfig.apiUrl;
    console.log("appConfig", wEnv.appConfig);
    console.log("apiUrl", apiUrl);
    this.api = new API({ rootUrl: apiUrl });

    // Data containers - Collections/Singletons
    this.subscriptionCollection = buildSubscriptionCollection(this);
    this.listenerEpisodeCollection = buildListenerEpisodeCollection(this);
    this.queue = Queue.getLoadable(this);
    this.settingsState = SettingsState.getLoadable(this);
    this.playerSwitchState = new PlayerSwitchState(this); // must be after settingsState is initialized

    // Fixed states / controllers
    this.playingTrackController = new PlayingTrackController(this);

    this.onboardingState = new OnboardingState(this);

    makeAutoObservable(this, {
      currentSubscription: observable,
      trackLearnerState: observable,
      showSubscription: action,
      hideSubscription: action,
    });

    if (!this.options.demo) {
      refreshAllSubscriptions(this);

      // Make the first item in the queue the current episode
      this.queue.then(async (queue) => {
        if (queue.tracks.length > 0) {
          makeTrackCurrent(queue.tracks[0]);
        }
      });
    }

    (window as any).workspace = this;

    this.offlineState = new OfflineState(this);

    this.featuredEpisodeCollection =
      FeaturedEpisodeCollection.getLoadable(this);

    reportingQueue.setStatusCallback(() => {
      const params: Record<string, any> = {
        media: this.playerSwitchState.state,
      };
      if (document.hidden) {
        params.hidden = true;
      }
      return params;
    });
  }

  get playerState(): AudioPlayerState {
    return this.playerSwitchState.playerState;
  }

  get userId(): string {
    return memoizeOn(this, "userId", () => {
      const value = localStorageUtils.getOrGenerate(
        "userId",
        () => `user:${randomName(40)}`
      );
      if (!value) throw new Error("userId not set");
      return value;
    });
  }

  get database() {
    return this.wEnv.database;
  }

  // --- MEMOIZED LOADABLES ---

  latestEpisodeCollection: LoadableValue<LatestEpisodeCollection> =
    new LoadableValue(async () => {
      await this.subscriptionCollection.load();
      return new LatestEpisodeCollection(this);
    });

  get admin() {
    return this.options.admin;
  }

  // --- ACTIONS ---

  showSubscription(subscription: Subscription) {
    recordEvent("go-to-subscription");
    this.wEnv.router.goTo(subscription.id);
    // this.currentSubscription = subscription;
  }

  hideSubscription() {
    this.currentSubscription = null;
  }

  getOrMakeTrackInstance(
    trackId: string,
    feedInfo: FeedInfoClient,
    feedItem: FeedItemAttributes
  ): PodcastEpisodeTrack {
    return withCache(
      this.trackCache,
      trackId,
      (): PodcastEpisodeTrack =>
        new PodcastEpisodeTrack(this, trackId, feedInfo, feedItem)
    );
  }

  getLoadableTrackById(trackId: string, stack: string[]): LoadableValue<Track> {
    const log = debug("track:info:getLoadableTrackById");
    stack = [...stack, "getLoadableTrackById"];
    return new LoadableValue<Track>(async (): Promise<Track> => {
      await this.subscriptionCollection.load();
      const result = await getTrackFeedAndItemInfoFromSubscriptions(
        trackId,
        this.subscriptionCollection,
        stack
      );

      if (result) {
        // Success: we got all the info we need from PouchDB
        const { feedInfo, feedItem } = result;
        return new PodcastEpisodeTrack(this, trackId, feedInfo, feedItem);
      }

      // Fallback: get track info from the backend
      const r = await this.api.getTrackInfo(trackId, stack);
      log("getTrackInfo", trackId, "returned", r);

      return buildTrackStateFromApe(this, r);
    });
  }

  async getListenerEpisode(trackId: string): Promise<ListenerEpisode> {
    await this.listenerEpisodeCollection.load();
    return await this.listenerEpisodeCollection.createOrFind(
      `listenerEpisode:${trackId}`,
      {
        type: "listenerEpisode",
        listeningPositionSeconds: 0,
        duration: null,
      }
    );
  }
}
