import { AudioPlayerState } from "@/lib/audio-player/AudioPlayerState";
import { trackSimpleEvent } from "@/lib/nemmp/base/tracking/trackEvent";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { Workspace } from "@/modules/main/Workspace";
import debug from "debug";
import { action, makeAutoObservable, observable } from "mobx";
import { DictionarySearchState } from "./../dictionary/search/DictionarySearchState";
import { TrackLearnerState } from "./../learn/TrackLearnerState";

const log = debug("dict:WordSearchEntryState");

// State for:
// - opening/closing the search dialog
// - saving pin

export type WordSearchContext = {
  transcriptContext: [string, string, string] | null;
};

export class WordSearchEntryState {
  isOpen = false;
  text = "";
  translation: string | null = null;

  // Data on open
  timestamp: number | null = null;
  trackLearnerState: TrackLearnerState | null = null;
  onClose: null | (() => void) = null;

  sourceLang: string | null = null;
  targetLang: string | null = null;

  dictionarySearchState: DictionarySearchState | null = null;
  wordSearchContext: WordSearchContext | null = null;

  constructor(
    public playerState: AudioPlayerState,
    // public trackLearnerState: TrackLearnerState,
    public workspace: Workspace
  ) {
    makeAutoObservable(this, {
      isOpen: observable,
      text: observable,
      translation: observable,
      open: action,
      submit: action,
      close: action,
      updateText: action,
      updateTranslation: action,
      pickResult: action,
      dictionarySearchState: observable,
    });
  }

  open({
    text,
    timestamp,
    pauseWhileOpen,
    trackLearnerState,
    sourceLang,
    targetLang,
    onClose,
    wordSearchContext,
  }: {
    text?: string;
    timestamp?: number;
    pauseWhileOpen?: boolean;
    trackLearnerState: TrackLearnerState | null;
    onClose: () => void;
    sourceLang: string | null;
    targetLang: string | null;
    wordSearchContext: WordSearchContext;
  }) {
    recordEvent("open-word-search-dialog");
    log("open word search entry", text);
    this.isOpen = true;
    this.text = text ?? "";
    this.timestamp = timestamp ?? this.playerState.currentTime;
    this.trackLearnerState = trackLearnerState;
    this.sourceLang = sourceLang;
    this.targetLang = targetLang;
    this.onClose = onClose;
    this.dictionarySearchState = new DictionarySearchState(
      this.text,
      sourceLang,
      targetLang,
      wordSearchContext?.transcriptContext,
      this.workspace
    );
    this.wordSearchContext = wordSearchContext;
  }

  submit() {
    recordEvent("submit-word-search-entry");
    if (!this.timestamp) throw new Error("No timestamp");

    if (this.trackLearnerState) {
      trackSimpleEvent("submit-new-pin");
      recordEvent("submit-new-pin");
      this.trackLearnerState.submitNewPin(
        this.timestamp,
        this.text,
        this.translation,
        this.sourceLang,
        this.targetLang,
        this.wordSearchContext?.transcriptContext
      );
    } else {
      console.warn(
        "No trackLearnerState prsent when trying to submit word search entry"
      );
    }

    this.close();
  }

  close() {
    recordEvent("close-word-search-dialog");
    this.isOpen = false;
    if (this.onClose) {
      this.onClose();
      this.onClose = null;
    }
  }

  updateText(value: string) {
    this.text = value;
  }

  updateTranslation(value: string) {
    this.translation = value;
  }

  pickResult(text: string, translation: string) {
    recordEvent("pick-word-search-result");
    this.text = text;
    this.translation = translation;
    this.submit();
  }
}
