import { debugFactory } from "@/lib/utils/debug";
import { DialogName, DialogNames, Route } from "./types";

const debug = debugFactory("routing:parseRoute");

export const parseRoute = (pathElements: string[]): Route | null => {
  debug("parseRoute", pathElements);

  if (pathElements.length === 0) return { isRoot: true };

  if (DialogNames.includes(pathElements[0])) {
    return { isRoot: true, dialog: pathElements[0] as DialogName };
  }

  return null;
};
