import { isMissing } from "@/lib/prelude";
import { feedIdFromUrl } from "@/modules/rules/identifiers";

export const getTrackOptimizedCoverUrl = (
  track: { trackId: string; coverUrl?: string | null | undefined },
  size: number
): string | undefined => {
  if (isMissing(track.coverUrl)) return undefined;
  return `/api/v2/tracks/${track.trackId}/cover/${size}.webp`;
};

export const getTrackOptimizedCoverUrlFn = (track: {
  trackId: string;
  coverUrl?: string | null | undefined;
  podcast?: {
    coverUrl?: string | null | undefined;
    url: string;
  };
}): ((size: number) => string) | undefined => {
  const { podcast } = track;
  // (A) The podcast has a cover and the track cover is either missing or identical => use the podcast cover
  if (
    podcast?.coverUrl &&
    (isMissing(track.coverUrl) || track.coverUrl === podcast.coverUrl)
  ) {
    return getFeedOptimizedCoverUrlFn(podcast);
  }

  // (B) Neither the track nor the podcast has a cover
  if (isMissing(track.coverUrl)) return undefined;

  // (C) The track has a cover and it's different from the podcast cover
  return (size) => `/api/v2/tracks/${track.trackId}/cover/${size}.webp`;
};

export const getFeedOptimizedCoverUrlFn = (feed: {
  url: string;
  coverUrl?: string | null | undefined;
}): ((size: number) => string) | undefined => {
  if (isMissing(feed.coverUrl)) return undefined;
  return (size: number) =>
    `/api/v2/feeds/${feedIdFromUrl(feed.url)}/cover/${size}.webp`;
};
