import { devEnv } from "@/config/dev";
import { MyErrorZ } from "@/framework/errors/MyError";
import { UserError } from "@/framework/errors/UserError";
import { toUserError } from "@/framework/errors/toUserError";
import { TRPCClientError } from "@trpc/client";

export function unpackTrpcError(error: unknown): UserError {
  // console.log(error);
  // console.log("unpackTrpcError", JSON.stringify(error, null, 2));
  if (error instanceof TRPCClientError && error.data?.custom) {
    try {
      const myError = MyErrorZ.parse(error.data?.custom);
      if (devEnv) {
        return new UserError({
          message:
            myError.internal?.message ??
            myError.technicalMessage ??
            myError.message,
          ...myError,
        });
      } else {
        return new UserError(myError);
      }
    } catch (e) {
      console.warn(
        "Failed to parse error.",
        "Original error:",
        error.data.custom,
        "Parse error:",
        e
      );
    }
  }
  return toUserError(error);
}

export async function unpackTrpcErrors<T>(fn: () => Promise<T>): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    throw unpackTrpcError(error);
  }
}
