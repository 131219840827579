import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button, { ButtonProps } from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import ResponsiveDialog from "@/lib/mui/responsive-dialog/responsive-dialog";

export type BackableDialogButtonWithDialogContentProps = ButtonProps & {
  label: string;
  // dialogComponent: React.ComponentType<
  //   TProps & { open: boolean; onClose: () => void }
  // >;
  children: React.ReactNode;
};

export function BackableDialogButtonWithDialogContent(
  props: BackableDialogButtonWithDialogContentProps
) {
  const { children, label, ...buttonProps } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          setOpen(true);
        }}
      >
        {label}
      </Button>

      <ResponsiveDialog
        open={open}
        maxWidth="sm"
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
}
