export function memoizeOn<T>(context: any, key: string, fn: () => T): T {
  // Use a Symbol to uniquely identify our cache, avoiding naming collisions
  const symbol = Symbol.for("memoizeCache");

  // If there's no cache on this instance, create one
  if (!context[symbol]) {
    context[symbol] = {};
  }

  // Check if we have a cached result for this function
  if (context[symbol][key] !== undefined) {
    return context[symbol][key];
  }

  // Otherwise compute, cache, and return
  const result = fn();
  context[symbol][key] = result;
  return result;
}
