import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { StarterConfig, WEnv } from "@/lib/nemmp/starter";
import { MainScreen } from "@/modules/main/MainScreen";
import { Workspace } from "@/modules/main/Workspace";
import HomeIcon from "@mui/icons-material/Home";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import RecentIcon from "@mui/icons-material/Update";

export const starterConfig: StarterConfig<Workspace> = {
  mainScreenComponent: MainScreen,
  workspaceConstructor: (wEnv: WEnv<Workspace>) =>
    new Workspace(wEnv, {
      // admin: buildConfig.inst === "staging",
    }),
  workspaces: {
    switchable: true,
  },
  buildMenuItems: (wEnv: WEnv<Workspace>) => {
    return [
      {
        label: "Home",
        icon: <HomeIcon />,
        onClick: ({ closeDrawer }) => {
          recordEvent("click-app-drawer-home");
          wEnv.router.goTo("dashboard");
          closeDrawer();
        },
      },
      {
        label: "Podcasts",
        icon: <PodcastsIcon />,
        onClick: ({ closeDrawer }) => {
          recordEvent("click-app-drawer-podcasts");
          wEnv.router.goTo("podcasts");
          closeDrawer();
        },
      },
      {
        label: "Trending Podcasts",
        icon: <PodcastsIcon />,
        onClick: ({ closeDrawer }) => {
          recordEvent("click-app-drawer-trending");
          wEnv.router.goTo("trending-podcasts");
          closeDrawer();
        },
      },
      {
        label: "Latest Episodes",
        icon: <RecentIcon />,
        onClick: ({ closeDrawer }) => {
          recordEvent("click-app-drawer-latest");
          wEnv.router.goTo("latest");
          closeDrawer();
        },
      },
      {
        label: "Queue",
        icon: <PlaylistPlayIcon />,
        onClick: ({ closeDrawer }) => {
          recordEvent("click-app-drawer-queue");
          wEnv.router.goTo("queue");
          closeDrawer();
        },
      },
    ];
  },
  privacyPolicy: {
    url: "/privacy-policy",
  },
};
