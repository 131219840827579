import { makeAutoObservable, observable, action } from "mobx";

export class TextFieldValueState {
  value: string;

  constructor(initialValue = "") {
    this.value = initialValue;
    makeAutoObservable(this, {
      value: observable,
      change: action,
      clear: action,
    });
  }

  change(newValue: string) {
    this.value = newValue;
  }

  clear() {
    this.value = "";
  }
}
