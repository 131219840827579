export const stripTags = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  let t = div.textContent || div.innerText || "";
  t = stripLines(t);
  return t;
};

// Avoid lines beginning with whitespace
function stripLines(text: string): string {
  return text
    .split("\n")
    .map((line) => line.trim())
    .join("\n");
}
