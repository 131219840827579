import { ParagraphTitle } from "@/lib/mui/ParagraphTitle";
import Typography from "@mui/material/Typography";

export function ImprintContentText() {
  return (
    <>
      <ParagraphTitle>Developer:</ParagraphTitle>
      <Typography variant="body2">
        Levente-Laszlo Bagi
        <br />
        Stendaler Str. 77, 12627 Berlin, Germany
        <br />
        <a href="https://levente.bagi.name/" target="_blank" rel="noreferrer">
          https://levente.bagi.name/
        </a>
      </Typography>
    </>
  );
}
