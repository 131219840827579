import { refreshConfig } from "./../feed/refresh/refreshConfig";
import { RefreshState } from "./RefreshState";
import { Workspace } from "@/modules/main/Workspace";
import debug from "debug";
import { FeedItemAttributes, FeedResult } from "../types";
import { Subscription } from "./Subscription";
import { findNewEpisodesByTrackId } from "./refresh/findNewEpisodesByTrackId";
import { filterMap, sleep } from "@/lib/utils";

/*
Refresh - client algorithm overview:

- builds a list of all subscriptions
- calls backend to refresh subscriptions in this list
- a response contains some results (the ones that haven't timed out)
- successful ones are removed from the list, and the process repeats
- the backend is expected to not restart the refresh process when it receives a new call with the same URLs

- backend: refreshManyFeedsByUrls() -> refreshItemOfBulk()

*/

const log = debug("refresh:refreshAllSubscriptions");

export async function refreshAllSubscriptions(workspace: Workspace) {
  log("refreshing all subscriptions");
  const subscriptionCollection = await workspace.subscriptionCollection;

  const subscriptions: Subscription[] = await subscriptionCollection.all;

  log(
    "subscriptions:",
    subscriptions.map((s) => s.title)
  );

  const { refreshState } = workspace;
  refreshState.start(subscriptions.length);

  // Remaining subscriptions to refresh - we'll remove successful ones in each iteration
  const subscriptionByUrl = new Map<string, Subscription>(
    subscriptions.map((s) => [s.feedUrl, s])
  );
  let attemptsPerformed = 0;

  while (
    subscriptionByUrl.size > 0 &&
    attemptsPerformed < refreshConfig.maxIterations
  ) {
    if (attemptsPerformed > 0) {
      await sleep(refreshConfig.iterationCooldownFn(attemptsPerformed));
    }
    log(`refresh iteration ${attemptsPerformed + 1}`);
    await refreshIteration(
      workspace,
      subscriptionByUrl,
      refreshState,
      attemptsPerformed === refreshConfig.maxIterations - 1
    );
    attemptsPerformed += 1;
  }

  log("done refreshing all subscriptions");
}

export async function refreshIteration(
  workspace: Workspace,
  subscriptionByUrl: Map<string, Subscription>,
  refreshState: RefreshState,
  isFinalIteration: boolean
) {
  const subscriptions = Array.from(subscriptionByUrl.values());

  // API: refresh remaining subscriptions -> backend: refreshManyFeedsByUrls()
  const results = await workspace.api.refreshFeeds(
    subscriptions.map((subscription) => ({
      url: subscription.feedUrl,
      etag: subscription.etag,
      lastCheckedAt: subscription.lastCheckedAt,
    }))
  );

  const resultsByUrl = new Map(results.map((result) => [result.url, result]));

  await Promise.all(
    subscriptions.map(async (subscription) => {
      const result = resultsByUrl.get(subscription.feedUrl);
      if (!result) {
        if (isFinalIteration) {
          refreshState.addFailure();
          console.warn(
            "No result for subscription in final iteration",
            subscription.title,
            subscription.url
          );
        }
        return;
      }

      log(
        "response for",
        subscription.title,
        subscription.feedUrl,
        result.status
      );

      try {
        if (result.status === "not_modified") {
        } else if (result.status === "ok") {
          const originalItems = [...subscription.doc.items];

          const updatedFeed: FeedResult = result.feed;

          const newItems = findNewEpisodesByTrackId(originalItems, updatedFeed);

          logNewItems(newItems, { limit: 5 });

          // If there are any new episodes at this stage, we should save it to the user db
          const etagChanged = updatedFeed.etag !== subscription.etag;
          const shouldSave = etagChanged || newItems.length > 0;
          log(`shouldSave: ${shouldSave}`);
          if (shouldSave) {
            await subscription.entity.put(updatedFeed);
            log("saved");
          }
        } else {
          throw new Error(result.error);
        }
        subscriptionByUrl.delete(subscription.feedUrl);
        refreshState.addSuccess();
      } catch (error) {
        subscriptionByUrl.delete(subscription.feedUrl);
        refreshState.addFailure();
        console.error(
          "Error refreshing subscription",
          subscription.title,
          error
        );
      }
    })
  );

  const subscriptionUrls = new Set(subscriptions.map((s) => s.feedUrl));
  const extraUrls = filterMap(results, (result) =>
    !subscriptionUrls.has(result.url) ? result.url : null
  );
  if (extraUrls.length > 0) {
    console.warn("Got extra urls", extraUrls);
  }
}

function logNewItems(
  newItems: FeedItemAttributes[],
  { limit = 5 }: { limit: number }
) {
  log(`new episodes: ${newItems.length}`);
  newItems.slice(0, limit).forEach((item) => {
    log(`- ${item.trackId}`);
  });
}
