export function is125SeriesNumber(n: number) {
  let i = n;
  while (i > 0) {
    if (i === 1 || i === 2 || i === 5) {
      return true;
    }
    if (i % 10 !== 0) {
      return false;
    }
    i /= 10;
  }
  return false;
}

// Rounds down to the nearest of 1, 2, 5, 10, 20, 50, 100, 200, 500, etc
export function floorTo125SeriesNumber(n: number) {
  if (n <= 0) return 0;

  const digits = Math.floor(Math.log10(n));

  const normalized = n / Math.pow(10, digits);

  // `normalized` is between [1, 10)
  // E.g:  999 => 9.99
  //      1000 => 1

  if (normalized < 2) {
    // Between [1, 2)
    return Math.pow(10, digits);
  }
  if (normalized < 5) {
    // Between [2, 5)
    return 2 * Math.pow(10, digits);
  }
  // Between [5, 10)
  return 5 * Math.pow(10, digits);
}
