import { Track } from "@/modules/track/Track";

export async function pause(track: Track) {
  const { workspace } = track;
  const { playerState } = workspace;

  if (playerState.playing) {
    playerState.pause();
  }
}
