import { FeedItemAttributes, FeedResult } from "../../types";

// Find new episodes in the result by comparing the trackId and publishedAt
export function findNewEpisodesByTrackId(
  originalItems: FeedItemAttributes[],
  updatedFeed: FeedResult
): FeedItemAttributes[] {
  // Create a set with all the trackIds of we had before refreshing
  const oldEpisodeKeys = new Set();
  originalItems.forEach((episode) => {
    oldEpisodeKeys.add(episode.trackId);
  });

  // Find episodes that are not in the database yet (based on the key)
  return updatedFeed.items.filter((item) => {
    const episodeKey = item.trackId;
    return !oldEpisodeKeys.has(episodeKey);
  });
}
