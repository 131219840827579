import { logTime } from "@/lib/utils/benchmarking";
import { Entity } from "@/lib/mobx-pouch/Entity";
import { debounce } from "@/lib/utils/debounce";
import { DateTime } from "luxon";
import { computed, makeObservable } from "mobx";
import { PodcastEpisodeTrack } from "../episode/PodcastEpisodeTrack";
import { Workspace } from "@/modules/main/Workspace";
import { Podcast } from "../podcast/Podcast";
import type { FeedItemAttributes } from "../types";
import type { FeedAttributes } from "./../types";

export type SubscriptionAttributes = {
  feedId: string;

  url: string;
  etag?: string | null;
  lastCheckedAt?: string | null; // UTC ISO timestamp generated on the server before checking for an update

  title: string;
  coverUrl?: string | null;
  language?: string | null;
  linkUrl?: string | null;
  description?: string | null;

  items: FeedItemAttributes[];
};

export type SubscriptionDoc = {
  _id: string;
  _rev: string;
  type: string;
} & SubscriptionAttributes;

export class Subscription {
  isLoaded = false;
  debouncedSave: () => void;

  // The last refresh time since the application was loaded
  lastRefreshT: DOMHighResTimeStamp | null = null;

  constructor(
    public workspace: Workspace,
    public entity: Entity<SubscriptionAttributes, Subscription>
  ) {
    makeObservable(this, {
      recentEpisodes: computed,
    });

    this.debouncedSave = debounce(500, () => this.save());
  }

  get feedId() {
    return this.doc.feedId;
  }

  get db() {
    return this.entity.collection.db;
  }
  get doc() {
    return this.entity.doc;
  }
  get id() {
    return this.doc._id;
  }
  get type() {
    return this.doc.type;
  }
  get feedUrl() {
    return this.doc.url;
  }
  get etag() {
    return this.doc.etag;
  }
  get lastCheckedAt() {
    return this.doc.lastCheckedAt;
  }
  get url() {
    return this.doc.url;
  }
  get title() {
    return this.doc.title;
  }
  get coverUrl() {
    return this.doc.coverUrl;
  }
  get linkUrl() {
    return this.doc.linkUrl;
  }
  get description() {
    return this.doc.description;
  }

  async delete() {
    this.entity.delete();
  }

  get inspect() {
    return `<#Subscription ${this.id} ${this.title}>`;
  }

  get feedAttributes(): FeedAttributes {
    return {
      url: this.feedUrl,
      title: this.title,
      coverUrl: this.coverUrl,
      linkUrl: this.linkUrl,
      description: this.description,
      items: this.doc.items,
    };
  }

  get podcast(): Podcast {
    // TODO: make sure we don't instantiate a podcast multiple times
    return new Podcast(this.workspace, this.feedAttributes);
  }

  get recentEpisodes(): PodcastEpisodeTrack[] {
    // 30 days ago timestamp
    const thirtyDaysAgo = DateTime.now()
      .minus({ days: 30 })
      .toUTC()
      .toISO() as string;

    return logTime("recentEpisodes", () => {
      const filteredEpisodes = this.doc.items.filter(
        (episode: FeedItemAttributes) => {
          if (!episode.publishedAt) return false;
          return episode.publishedAt > thirtyDaysAgo;
        }
      );
      return filteredEpisodes.map((episodeAttributes: FeedItemAttributes) =>
        this._trackInstance(episodeAttributes)
      );
    });
  }

  get episodes(): PodcastEpisodeTrack[] {
    return this.doc.items.map((feedEpisodeResult) => {
      return this._trackInstance(feedEpisodeResult);
    });
  }

  getTrackById(trackId: string): PodcastEpisodeTrack | null {
    const feedEpisodeResult = this.doc.items.find(
      (feedEpisodeResult) => feedEpisodeResult.trackId === trackId
    );
    if (!feedEpisodeResult) return null;
    return this._trackInstance(feedEpisodeResult);
  }
  // --- Sync ---

  _trackInstances(items: FeedItemAttributes[]): PodcastEpisodeTrack[] {
    return items.map((feedEpisodeResult) =>
      this._trackInstance(feedEpisodeResult)
    );
  }

  _trackInstance(feedEpisodeResult: FeedItemAttributes): PodcastEpisodeTrack {
    return this.workspace.getOrMakeTrackInstance(
      feedEpisodeResult.trackId,
      this.doc,
      feedEpisodeResult
    );
  }

  async save() {
    return await this.entity.save();
  }
}
