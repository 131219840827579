import { ErrorText } from "@/lib/mui/ErrorText";
import { toUserError } from "./toUserError";
import { getUserFriendlyError } from "./getUserFriendlyError";

type ErrorDisplayProps = {
  error: unknown;
};

export const ErrorDisplay = ({ error }: ErrorDisplayProps) => {
  const e = toUserError(error);
  return (
    <>
      <ErrorText>
        {getUserFriendlyError(e)}

        {process.env.NODE_ENV === "development" && (
          <div>
            <pre style={{ fontSize: "0.8em" }}>
              {JSON.stringify(e, null, 2)}
            </pre>
          </div>
        )}
      </ErrorText>
    </>
  );
};
