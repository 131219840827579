import { SnackbarProvider } from "notistack";

type AppNotificationsDisplayProps = {
  children: React.ReactNode;
};

export const AppNotificationsDisplay = ({
  children,
}: AppNotificationsDisplayProps) => {
  return (
    <SnackbarProvider
      autoHideDuration={2000}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      {children}
    </SnackbarProvider>
  );
};
