import { ContactDialog } from "@/lib/nemmp/base";
import { StarterCx } from "../app/contexts";
type NemmpContactDialogProps<TWorkspace> = {
  cx: StarterCx<TWorkspace>;
};

export const NemmpContactDialog = <TWorkspace,>({
  cx,
}: NemmpContactDialogProps<TWorkspace>) => {
  const { router } = cx;
  return (
    <ContactDialog
      open={true}
      onClose={() => router.closeDialog()}
      easterEggs={{
        disablemetrics: () => {
          window.localStorage.setItem("metrics-disabled", "yes");
        },
        enablemetrics: () => {
          window.localStorage.setItem("metrics-disabled", "no");
        },
      }}
    />
  );
};
