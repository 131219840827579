import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { Track } from "@/modules/track/Track";

export async function enqueueTrack(
  track: Track,
  position: "top" | "bottom" = "bottom"
) {
  recordEvent("enqueue-track", { position });
  const { workspace } = track;

  if (!workspace.queue) {
    throw new Error("Cannot enqueue - no queue in workspace");
  }

  const queue = await workspace.queue;

  if (queue.isTrackInQueue(track)) {
    return queue.moveTrack(track, position);
  }

  if (position === "top") return queue.addToTheTop(track);

  return queue.addToTheEnd(track);
}
