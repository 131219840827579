/*
Purpose: Dump the contents of a PouchDB database as a backup, that can be later restored.

*/

import { getCurrentTimeForFilename } from "../prelude/time";
import { downloadJson } from "../utils/browser-download";

export async function downloadPouchDbDumpZip(
  fileNamePrefix: string,
  db: PouchDB.Database
) {
  if (typeof db === "undefined") throw "db is undefined";
  const pouchDBDocs = await db.allDocs({
    include_docs: true,
    attachments: true,
  });

  const everything = {
    pouchDBDocs,
    localStorage,
  };

  downloadJson(
    `${fileNamePrefix}${getCurrentTimeForFilename()}.json`,
    everything
  );
}
