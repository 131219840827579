import { FeedInfoClient } from "../data/types";
import { subscriptionIdFromTrackId } from "../rules/identifiers";
import { SubscriptionCollection } from "./SubscriptionCollection";
import { FeedItemAttributes } from "../types";
import debug from "debug";

const log = debug("track:getTrackFeedAndItemInfoFromSubscriptions");

export async function getTrackFeedAndItemInfoFromSubscriptions(
  trackId: string,
  subscriptionCollection: SubscriptionCollection,
  stack: string[]
): Promise<{ feedInfo: FeedInfoClient; feedItem: FeedItemAttributes } | null> {
  stack = [...stack, "getTrackFeedAndItemInfoFromSubscriptions"];
  // Determine subscription ID
  const subscriptionId = subscriptionIdFromTrackId(trackId);
  if (!subscriptionId) {
    log("Subscription cannot be determined from this track ID:", trackId);
    return null;
  }

  // Find subscription in PouchDB
  const subscription = subscriptionCollection.fetch(subscriptionId);
  if (!subscription) {
    console.warn("Subscription not found:", subscriptionId, trackId, { stack });
    return null;
  }

  // Find item in subscription doc
  const feedItem = subscription.doc.items.find(
    (item) => item.trackId === trackId
  );
  if (!feedItem) {
    console.warn(
      "Item not found in subscription doc:",
      trackId,
      subscriptionId,
      { stack }
    );
    return null;
  }

  // Build return value
  const { url, title, description, linkUrl, coverUrl, language } =
    subscription.doc;
  const feedInfo: FeedInfoClient = {
    url,
    title,
    description,
    linkUrl,
    coverUrl,
    language,
  };
  return { feedInfo, feedItem };
}
