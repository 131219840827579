// Collection
//
// A MobX collection of all documents with a given 'type' attribute.
//
// Purpose:
// - to provide an object that can observed and changes when items are added/removed remotely

import { LoadableValue } from "@/lib/mobx";
import {
  createOrGetExistingPouchDoc,
  createOrOverwritePouchDoc,
} from "@/lib/pouch";
import {
  computed,
  makeAutoObservable,
  observable,
  ObservableMap,
  runInAction,
} from "mobx";
import { Database } from "./Database";
import { Entity } from "./Entity";
import { consoleGroupCollapsed, debugFactory } from "@/lib/utils/debug";
import { filterMap } from "@/lib/utils/array";
import { memo } from "@/lib/utils/decorators";

export type TCouchMeta = { _id: string; _rev: string };
export type TMeta = TCouchMeta & { type: string };

export type TEntityConstructor<A, E extends { entity: Entity<A, E> }> = (
  doc: A & TMeta,
  database: Database,
  collection: Collection<A, E>
) => E;

export class Collection<
  TAttributes,
  TEntity extends { entity: Entity<TAttributes, TEntity> }
> {
  database: Database;
  type: string;
  entityConstructor: TEntityConstructor<TAttributes, TEntity>;

  elements: ObservableMap<string, TEntity | null> = observable.map({});

  debug: (...args: any) => void;

  private loadPromise: Promise<Array<TAttributes & TMeta>> | undefined;

  static buildAndLoad<A, E extends { entity: Entity<A, any> }>(
    database: Database,
    type: string,
    entityConstructor: TEntityConstructor<A, E>
  ): LoadableValue<Collection<A, E>> {
    return new LoadableValue(async () => {
      const collection = new Collection<A, E>(
        database,
        type,
        entityConstructor
      );
      return collection;
    });
  }

  //---------------------------
  constructor(
    database: Database,
    type: string,
    entityConstructor: (
      doc: TAttributes & TMeta,
      database: Database,
      collection: Collection<TAttributes, TEntity>
    ) => TEntity
  ) {
    this.database = database;
    this.type = type;
    this.entityConstructor = entityConstructor;

    this.debug = debugFactory("Collection");

    makeAutoObservable(this, {
      elements: observable,
      allObs: computed,
    });

    runInAction(() => {
      this.elements = observable.map({});
    });

    this.load().then((docs) => {
      consoleGroupCollapsed("processing load() results", () => {
        runInAction(() => {
          docs.forEach((doc) => {
            this._addOrUpdate(doc);
          });
        });
      });
    });

    // ------ SUBSCRIBE TO CHANGES ------ //

    this.database.onChange((change) => {
      const doc = change.doc as any as TAttributes & TMeta;

      if (change.deleted) {
        const element = this.elements.get(doc._id);
        this.debug("element found:", element);
        if (element) {
          runInAction(() => {
            this.elements.delete(doc._id);
          });
        }
      } else if (doc.type === this.type) {
        runInAction(() => {
          this._addOrUpdate(doc);
        });
      }
    });
  }
  //---------------------------

  get db() {
    return this.database.db;
  }

  fetch(id: string): TEntity | null {
    return this.elements.get(id) ?? null;
  }

  load() {
    // this.debug("load()");

    if (this.loadPromise) {
      return this.loadPromise;
    }

    return (this.loadPromise = (async () => {
      // TODO: create index when user database is created
      // curl -X POST 'http://localhost:5984/userdb_123/_index' -H "Content-Type: application/json" --data '{"index": {"fields": ["type"]}, "type": "json"}'
      const results = await this.db.find({
        selector: { type: this.type },
      });
      this.debug("load() results:", results);

      // Result structure:
      //  - bookmark (string)
      //  - docs[]
      //    - _id
      //    - _rev
      //    - type
      //    - ... (other document attributes)

      return results.docs as any as Array<TAttributes & TMeta>;
    })());
  }

  // WARNING: Returns the docs at the point when the collection was FIRST loaded
  docs: LoadableValue<Array<TAttributes & TMeta>> = new LoadableValue(
    async () => {
      return this.load();
    },
    false,
    "Collection-docs"
  );

  get currentDocs(): Array<TAttributes & TMeta> {
    const arr = Array.from(this.elements.values());
    return filterMap(arr, (e) => e?.entity.doc);
  }

  all: LoadableValue<Array<TEntity>> = new LoadableValue<Array<TEntity>>(
    async (): Promise<Array<TEntity>> => {
      await this.load();
      return this.getAll();
    },
    false,
    "Collection-all"
  );

  getAll(): Array<TEntity> {
    return Array.from(this.elements.values()) as Array<TEntity>;
  }

  get allObs(): Array<TEntity> {
    return this.getAll();
  }

  async create(doc: TAttributes & { _id?: string }): Promise<TEntity> {
    this.debug("create", doc);
    // FIXME: handle timeout (couchdb hanged once in development until I restarted it)
    if (doc._id) {
      const { id, rev } = await createOrOverwritePouchDoc(this.db, {
        ...doc,
        _id: doc._id,
        type: this.type,
      });
      this.debug("createOrOverwrite result:", id, rev);
      return this._addOrUpdate({ ...doc, type: this.type, _id: id, _rev: rev });
    } else {
      const { id, rev } = (await this.db.post({
        ...doc,
        type: this.type,
      })) as any as { id: string; rev: string };
      this.debug("create result:", id, rev);
      return this._addOrUpdate({ ...doc, type: this.type, _id: id, _rev: rev });
    }
  }

  async createOrFind(id: string, attributes: TAttributes): Promise<TEntity> {
    this.debug("createOrFind", attributes);
    const doc = await createOrGetExistingPouchDoc(this.db, id, attributes);
    return this._addOrUpdate({
      ...doc,
      type: this.type,
    });
  }

  _addOrUpdate(doc: TAttributes & TMeta): TEntity {
    if (this.elements.has(doc._id)) {
      this.debug(`Updating ${doc._id} in the ${this.type} collection`);
      const entity = this.elements.get(doc._id) as TEntity;
      runInAction(() => {
        Object.assign(entity.entity.doc, doc);
      });
      return entity;
    } else {
      this.debug(`Adding ${doc._id} to the ${this.type} collection`);
      const entity = this._makeEntity(doc);
      this.elements.set(doc._id, entity);
      return entity;
    }
  }

  _makeEntity(doc: TAttributes & TMeta): TEntity {
    return this.entityConstructor(doc, this.database, this);
  }
}
