import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { Track } from "@/modules/track/Track";
import debug from "debug";
import { runInAction } from "mobx";

const log = debug("player:makeTrackCurrent");

export async function makeTrackCurrent(
  track: Track,
  { play = false }: { play?: boolean } = {}
) {
  recordEvent("make-track-current");
  const { workspace } = track;
  const { playingTrackController, playerState } = workspace;

  // console.log(
  //   "playTrack",
  //   JSON.stringify({
  //     audioUrl: track.audioUrl,
  //     coverUrl: track.coverUrl,
  //     friendlyReference: track.friendlyReference,
  //     key: track.key,
  //     title: track.title,
  //     trackId: track.trackId,
  //     podcast: {
  //       title: podcast?.title,
  //     },
  //     reference: track.reference,
  //   })
  // );

  if (playingTrackController.isCurrentTrack(track)) {
    if (playerState.playing) {
      log("Already playing.");
      return;
    } else {
      log("Already current. Resuming...");
      playerState.play();
      return;
    }
  }

  const queue = await workspace.queue;
  await queue?.addTrack(track);

  let listeningPositionSeconds: number | null = null;

  if (
    "listenerEpisode" in playingTrackController &&
    "currentTrack" in playingTrackController
  ) {
    // Create an ListenerEpisode in the user's DB so where the position can be saved
    const listenerEpisode =
      "getListenerEpisode" in workspace
        ? await workspace.getListenerEpisode(track.trackId)
        : null;

    if (listenerEpisode) {
      log("ListenerEpisode found for track", track);
    } else {
      log("No listenerEpisode for track", track);
    }
    if (listenerEpisode?.isComplete) {
      log("Episode has been completed. Resetting listening position.");
      listenerEpisode.resetListeningPosition();
    }

    // Change state
    runInAction(async () => {
      playingTrackController.currentTrack = track;
      playingTrackController.listenerEpisode = listenerEpisode;
    });

    listeningPositionSeconds ??=
      listenerEpisode?.listeningPositionSeconds ?? null;
  }

  // Play / prepare to play
  const audioUrl = workspace.api.getAudioUrl(track.trackId);

  if ("playerSwitchState" in workspace) {
    workspace.playerSwitchState.playUrlFrom(
      audioUrl,
      listeningPositionSeconds ?? 0,
      play
    );
  }
}
