import { trackSimpleEvent } from "@/lib/nemmp/base";
import { debugFactory } from "@/lib/utils/debug";
import { floorTo125SeriesNumber } from "@/lib/utils/series125";
import {
  getFirstOpenDate,
  getLastOpenDate,
  getUsageDaysCounter,
  setFirstOpenDateToToday,
  setLastOpenDateToToday,
  setUsageDaysCounter,
} from "@/modules/app-meta/usage/usage-data";
import { DateTime } from "luxon";

const debug = debugFactory("StatsTracker");

interface TCallbacks {}

export class StatsTracker {
  constructor(private callbacks: TCallbacks = {}) {}

  async onOpen() {
    (window as any).DateTime = DateTime;
    debug("onOpen");
    const lastOpenDate = getLastOpenDate();

    if (!lastOpenDate || !this.isToday(lastOpenDate)) {
      this.onNewDay();
    }

    setLastOpenDateToToday();

    const firstOpenDate = getFirstOpenDate();
    if (!firstOpenDate) {
      setFirstOpenDateToToday();
    }
  }

  async onNewDay() {
    debug("onNewDay");
    let counter = await getUsageDaysCounter();
    counter += 1;

    if (counter > 1) {
      trackSimpleEvent(`ret`);
      trackSimpleEvent(`ret${floorTo125SeriesNumber(counter)}`);
    }

    setUsageDaysCounter(counter);
  }

  isToday(d: DateTime): boolean {
    return d.startOf("day").equals(this.today);
  }

  get today(): DateTime {
    return DateTime.local().startOf("day");
  }
}
