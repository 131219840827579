import { LoadableValue } from "@/lib/mobx";
import { isNumber, isPresent } from "@/lib/prelude";
import { FeedInfoClient } from "@/modules/data/types";
import { Workspace } from "@/modules/main/Workspace";
import { TranscriptWChaPaSeId } from "@/modules/transcript/formats/types";
import { computed, makeAutoObservable, observable } from "mobx";
import { feedTimestampToSeconds } from "../feed/feedUtils";
import { QueueItem } from "../queue/Queue";
import { ITrack } from "../track/ITrack";
import type { FeedItemAttributes } from "../types";
import { ListenerEpisode } from "./ListenerEpisode";
import { getEpisodeKey as _getEpisodeKey } from "./episode-referencing";

export const getEpisodeKey = _getEpisodeKey;

// export const getEpisodeKey = (episode: EpisodeAttributes) => {
//   const { id, guid, title, audioUrl } = episode;
//   return sha1(id ?? guid ?? title ?? audioUrl);
// };

// Top-level episode state
// May include an ListenerEpisode which is persisted in PouchDB.
export class PodcastEpisodeTrack implements ITrack {
  workspace: Workspace;
  listenerEpisode: ListenerEpisode | null = null;

  constructor(
    workspace: Workspace,
    public trackId: string,
    public feedAttributes: FeedInfoClient,
    public feedItemAttributes: FeedItemAttributes
  ) {
    this.workspace = workspace;
    makeAutoObservable(this, {
      listenerEpisode: observable,
      isCurrent: computed,
      isComplete: computed,
      listeningPositionSeconds: computed,
      listeningPositionPercent: computed,
    });
  }

  get key() {
    return this.trackId;
  }

  get publishedAt(): string | undefined {
    return this.feedItemAttributes.publishedAt;
  }
  get title(): string {
    return this.feedItemAttributes.title;
  }
  get description(): string | undefined {
    return this.feedItemAttributes.description ?? undefined;
  }
  get linkUrl(): string | undefined {
    return this.feedItemAttributes.linkUrl ?? undefined;
  }
  get audioUrl(): string {
    return this.feedItemAttributes.audioUrl;
  }
  get language(): string | undefined {
    return this.feedAttributes.language ?? undefined;
  }
  get podcast() {
    return this.feedAttributes;
  }
  get coverUrl(): string | undefined {
    return (
      this.feedItemAttributes.coverUrl ??
      this.feedAttributes.coverUrl ??
      undefined
    );
  }

  get sortKey(): string {
    return this.publishedAt ?? "";
  }

  get isCurrent() {
    const currentTrack = this.workspace.playingTrackController.currentTrack;
    if (!currentTrack) return false;
    return currentTrack.key === this.key;
  }

  get isComplete() {
    return this.listenerEpisode?.isComplete ?? false;
  }

  // Reference: stored in the queue, used to find the episode in the queue
  get reference(): QueueItem {
    return {
      trackId: this.trackId,
      title: this.feedItemAttributes.title,
      feedItemGuid: this.feedItemAttributes.guid,
      feedTitle: this.feedAttributes.title,
      feedUrl: this.feedAttributes.url,
    };
  }

  get publishedAtSeconds(): number {
    const t = this.feedItemAttributes.publishedAt;
    return feedTimestampToSeconds(t) ?? 0;
  }

  get listeningPositionSeconds(): number | null {
    return this.listenerEpisode?.listeningPositionSeconds ?? null;
  }

  get listeningPositionPercent(): number | null {
    if (!this.listenerEpisode) return null;
    const { listeningPositionSeconds, duration } = this.listenerEpisode;
    if (
      !isNumber(listeningPositionSeconds) ||
      !isNumber(duration) ||
      duration === 0
    )
      return null;
    return (listeningPositionSeconds / duration) * 100;
  }

  get friendlyReference() {
    return `${this.title} from ${this.feedAttributes.title}`;
  }

  private _memoized_transcript: LoadableValue<TranscriptWChaPaSeId> | null =
    null;

  get debugAttributes() {
    return {
      ...this.feedItemAttributes,
      listeningPositionPercent: this.listeningPositionPercent,
      isComplete: this.isComplete,
    };
  }

  async getOrCreateListenerEpisode(): Promise<ListenerEpisode> {
    return this.workspace?.getListenerEpisode(this.trackId);
  }
}
