import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { AppDrawer } from "./AppDrawer";
import { StarterCx } from "./contexts";

export const AppDrawerIconButton = <TWorkspace,>({
  cx,
}: {
  cx: StarterCx<TWorkspace>;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 2 }}
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>

      <AppDrawer cx={cx} open={open} onClose={() => setOpen(false)} />
    </>
  );
};
