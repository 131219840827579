import { makeAutoObservable, observable, action } from "mobx";

export class SuggestionState {
  isOpen: boolean;

  constructor(isOpen = false) {
    this.isOpen = isOpen;
    makeAutoObservable(this, {
      isOpen: observable,
      open: action,
      close: action,
    });
  }

  flash(seconds = 5) {
    this.open();
    setTimeout(() => this.close(), seconds * 1000);
  }

  open() {
    this.isOpen = true;
  }
  close() {
    console.log("closing");
    this.isOpen = false;
    console.log("closed", this.isOpen);
  }
}
