import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { getFragmentRouteForTrack } from "@/modules/app/fragment-routes";
import { Track } from "@/modules/track/Track";

export async function goToTrack(track: Track) {
  recordEvent("go-to-track");
  console.log("goToTrack", track.trackId);
  const { workspace } = track;
  if (!("wEnv" in workspace)) throw new Error("No wEnv in workspace");
  const { router } = workspace.wEnv;
  router.goTo(getFragmentRouteForTrack(track));
  return;
}
