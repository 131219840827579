export const refreshConfig = {
  // How many times the client calls the backend; after this the remaining feeds are considered failing.
  maxIterations: 5,

  // Client waits this long between iterations.
  iterationCooldownFn: (attempts: number) => 1000,

  // After this time the backend returns even if not all feeds have been refreshed.
  // The total time the refresh takes is:
  //   maxIterations * iterationBackendTimeou
  //    + iterationCooldown * (maxIterations - 1)
  //    + network overhead
  iterationBackendTimeout: 5000,

  // Config for the mutex that prevents multiple concurrent refreshes of the same feed.
  rssRefreshMutex: {
    retryTimeMillis: 100,
    failAfterMillis: 4000,
    timeoutMillis: 30000, // key expires (in case of a crash)
  },

  // Don't re-fetch RSS if it has been checked within this time.
  rssStillFreshMinutes: 5,
};
