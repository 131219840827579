import { action, makeObservable, observable } from "mobx";

export class CustomDialogState<T> {
  isOpen = false;
  component: React.FC<{
    state: T;
  }> | null = null;

  constructor() {
    makeObservable(this, {
      component: observable,
      isOpen: observable,
      open: action,
      close: action,
    });
  }

  open(
    component: React.FC<{
      state: T;
    }> | null
  ) {
    this.component = component;
    this.isOpen = true;
  }

  close() {
    // Don't set component to null, so that it can transition out
    this.isOpen = false;
  }
}
