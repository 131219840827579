import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AppDrawerIconButton } from "./AppDrawerIconButton";
import { AppBackButton } from "./AppBackButton";
import { StarterCx } from "./contexts";

type AppToolbarProps<TWorkspace> = {
  cx: StarterCx<TWorkspace>;
};

export const AppToolbar = <TWorkspace,>({
  cx,
}: AppToolbarProps<TWorkspace>) => {
  const RightContent = cx.starterConfig.appBarRightContent;

  return (
    <Toolbar variant="dense">
      <AppDrawerIconButton cx={cx} />
      <AppBackButton router={cx.router} />

      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{ flexGrow: 1 }}
      ></Typography>

      {RightContent && (
        <Box>
          <RightContent cx={cx} />
        </Box>
      )}
    </Toolbar>
  );
};
