import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import { ListItemIcon } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { enqueueTrack } from "../queue/enqueueTrack";
import { goToTrack } from "../queue/goToTrack";
import { playTrack } from "../queue/playTrack";
import { Queue } from "../queue/Queue";
import { removeFromQueue } from "../queue/removeFromQueue";
import { Track } from "@/modules/track/Track";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { TrackDisplayState } from "@/modules/track/display/TrackDisplayState";
import TranslateIcon from "@mui/icons-material/Translate";
import { runInAction } from "mobx";
export const TrackPopupMenu = ({
  track,
  onClose,
  anchorEl,
  trackDisplayState,
}: {
  track: Track;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  trackDisplayState?: TrackDisplayState;
}) => {
  const close = onClose;
  const [queue, setQueue] = useState<Queue | null>(null);
  useEffect(() => {
    track.workspace.queue?.then(setQueue);
  }, []);

  const mcx = {
    isInQueue: queue?.isTrackInQueue(track),
    close,
  };

  const isOnTrackScreen = !!trackDisplayState;

  return (
    <Menu
      id="track-popup-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {!isOnTrackScreen && <OpenMenuItem track={track} mcx={mcx} />}
      {!isOnTrackScreen && <PlayMenuItem track={track} mcx={mcx} />}

      <AddToQueueTopMenuItem track={track} mcx={mcx} />
      <AddToQueueMenuItem track={track} mcx={mcx} />
      <RemoveFromQueueMenuItem track={track} mcx={mcx} />

      {isOnTrackScreen && (
        <ToggleTranslateInfoMenuItem
          track={track}
          trackDisplayState={trackDisplayState}
          mcx={mcx}
        />
      )}
    </Menu>
  );
};

type CustomMenuItemProps = {
  track: Track;
  mcx: {
    isInQueue: boolean | undefined;
    close: () => void;
  };
};
const OpenMenuItem = ({ track, mcx }: CustomMenuItemProps) => {
  return (
    <MenuItem
      onClick={() => {
        goToTrack(track);
        mcx.close();
      }}
    >
      <ListItemIcon>
        <ArrowForwardIcon />
      </ListItemIcon>
      Open
    </MenuItem>
  );
};
const PlayMenuItem = ({ track, mcx }: CustomMenuItemProps) => {
  return (
    <MenuItem
      onClick={() => {
        recordEvent("click-play-from-track-popup-menu");
        playTrack(track);
        mcx.close();
      }}
    >
      <ListItemIcon>
        <PlayArrowIcon />
      </ListItemIcon>
      Play
    </MenuItem>
  );
};
const AddToQueueTopMenuItem = ({ track, mcx }: CustomMenuItemProps) => {
  // if (mcx.isInQueue) return null;
  return (
    <MenuItem
      onClick={() => {
        enqueueTrack(track, "top");
        mcx.close();
      }}
    >
      <ListItemIcon>
        <PlaylistAddIcon />
      </ListItemIcon>
      {mcx.isInQueue ? "Move" : "Add"} to the top of the queue
    </MenuItem>
  );
};
const AddToQueueMenuItem = ({ track, mcx }: CustomMenuItemProps) => {
  // if (mcx.isInQueue) return null;
  return (
    <MenuItem
      onClick={() => {
        enqueueTrack(track, "bottom");
        mcx.close();
      }}
    >
      <ListItemIcon>
        <PlaylistAddIcon />
      </ListItemIcon>
      {mcx.isInQueue ? "Move" : "Add"} to bottom of the queue
    </MenuItem>
  );
};
const RemoveFromQueueMenuItem = ({ track, mcx }: CustomMenuItemProps) => {
  if (!mcx.isInQueue) return null;

  return (
    <MenuItem
      onClick={() => {
        removeFromQueue(track);
        mcx.close();
      }}
    >
      <ListItemIcon>
        <PlaylistRemoveIcon />
      </ListItemIcon>
      Remove from queue
    </MenuItem>
  );
};

const ToggleTranslateInfoMenuItem = ({
  track,
  mcx,
  trackDisplayState,
}: CustomMenuItemProps & { trackDisplayState?: TrackDisplayState }) => {
  if (!trackDisplayState) return null;
  return (
    <MenuItem
      onClick={() => {
        runInAction(() => {
          trackDisplayState.autoTranslateInfo =
            !trackDisplayState.autoTranslateInfo;
        });
        mcx.close();
      }}
    >
      <ListItemIcon>
        <TranslateIcon />
      </ListItemIcon>
      {trackDisplayState.autoTranslateInfo
        ? "Show info in original language"
        : "Translate info"}
    </MenuItem>
  );
};
