import { observer } from "mobx-react";
import Typography from "@mui/material/Typography";
import { TypographyProps } from "@mui/system";

type ErrorTextProps = TypographyProps & {
  children: React.ReactNode;
};

export const ErrorText = observer(function (props: ErrorTextProps) {
  const { children, ...otherProps } = props;
  return (
    <Typography variant="body2" {...otherProps}>
      {children}
    </Typography>
  );
});
