import { ScreenErrorDisplay } from "@/framework/errors/ScreenErrorDisplay";
import { LoadableValue } from "@/lib/mobx";
import Skeleton from "@mui/material/Skeleton";
import { observer } from "mobx-react";
import { LoadablePlaceholder } from "./loadable-placeholder";

type LoadableSkeletonProps<T> = {
  loadable?: LoadableValue<T>;
  renderLoaded: (a: T) => JSX.Element | string | null;
};

export const LoadableSkeleton = observer(function <T>(
  props: LoadableSkeletonProps<T>
) {
  return (
    <LoadablePlaceholder
      {...props}
      renderPlaceholder={() => <Skeleton variant="text" />}
      renderError={(error: any) => <ScreenErrorDisplay error={error} />}
    />
  );
});
