import { Database } from "@/lib/mobx-pouch/Database";
import { WorkspacesManagerOptions } from "./WorkspacesManager";
import { WorkspacesManagerState } from "./WorkspacesManagerState";
import { z } from "zod";
import debug from "debug";

const log = debug("pouch:DatabaseFactory");

export class DatabaseFactory<TWEnv> {
  constructor(
    public state: WorkspacesManagerState,
    public options: WorkspacesManagerOptions<TWEnv>
  ) {}

  async build(): Promise<Database> {
    log("pouch: DatabaseFactory.build()");
    if (this.state.isSyncEnabled) {
      const dbUrl = await this.getDatabaseUrl();
      return new Database(dbUrl, this.state.localName);
    } else {
      return new Database(null, this.state.localName);
    }
  }

  /**
   * Get database URL from localstorage or from server
   */
  async getDatabaseUrl(): Promise<string> {
    const accessKey = this.state.getOrGenerateAccessKey();
    const url = this.state.remoteDatabaseUrl;
    if (url) {
      return url;
    }
    const newUrl = await this.fetchDatabaseUrl(accessKey);
    log(
      `%cUser database: ${newUrl}`,
      "font-weight: bold; background-color: #FCE;"
    );
    this.state.updateCurrentEntry({ remoteDatabaseUrl: newUrl });
    return newUrl;
  }

  async fetchDatabaseUrl(accessKey: string): Promise<string> {
    // TODO: resolve name discrepancy between userId and accessKey
    const response = await fetch(
      `${this.options.apiBaseUrl}/api/database?userId=${accessKey}`
    );

    const responseZ = z.union([
      z.object({ dbUrl: z.string() }),
      z.object({ dbUrl: z.object({ dbUrl: z.string() }) }),
    ]);

    const payload = responseZ.parse(await response.json());

    const dbUrl =
      typeof payload.dbUrl === "string" ? payload.dbUrl : payload.dbUrl.dbUrl;

    if (!dbUrl) {
      throw new Error(
        `No dbUrl in response: ${JSON.stringify(payload).slice(0, 200)}`
      );
    }
    return dbUrl;
  }
}
