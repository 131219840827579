import { z } from "zod";
import { FeedClientZ } from "../data/types";

export const FeedRefreshResultItemZ = z.union([
  z.object({
    url: z.string(),
    status: z.literal("ok"),
    feed: FeedClientZ,
  }),
  z.object({
    url: z.string(),
    status: z.literal("not_modified"),
  }),
  z.object({
    url: z.string(),
    status: z.literal("error"),
    error: z.string(),
  }),
]);

export type FeedRefreshResultItem = z.infer<typeof FeedRefreshResultItemZ>;

export const FeedRefreshResultZ = z.array(FeedRefreshResultItemZ);

export type FeedRefreshResult = z.infer<typeof FeedRefreshResultZ>;
