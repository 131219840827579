import Button, { ButtonProps } from "@mui/material/Button";
import { useState } from "react";

export type BackableDialogButtonProps<TProps> = ButtonProps & {
  dialogComponent: React.ComponentType<
    TProps & { open: boolean; onClose: () => void }
  >;
  dialogProps: TProps;
  beforeOpen?: () => void;
};

export function BackableDialogButton<TProps>(
  props: BackableDialogButtonProps<TProps>
) {
  const {
    dialogComponent: DialogComponent,
    dialogProps,
    beforeOpen,
    ...buttonProps
  } = props;
  const [open, setOpen] = useState(false);
  const allDialogProps: TProps & { open: boolean; onClose: () => void } = {
    open,
    onClose: () => {
      setOpen(false);
    },
    ...dialogProps,
  };
  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => {
          if (beforeOpen) beforeOpen();
          setOpen(true);
        }}
      />
      <DialogComponent {...allDialogProps} />
    </>
  );
}
