import { StatsTracker } from "../stats/StatsTracker";
import { WEnv } from "./WEnv";
import { Database } from "@/lib/mobx-pouch/Database";
import { fetchJSON } from "@/lib/utils/fetch";
import { ProgressState } from "@/lib/mobx/ProgressState";
import { createOrOverwritePouchDoc } from "@/lib/pouch/utils";
import {
  WorkspacesManager,
  WorkspacesManagerOptions,
} from "../../pouch-couch-sync/WorkspacesManager";
import { AppConfig } from "../../app-config";

type PouchDBDump = {
  pouchDBDocs: {
    total_rows: number;
    offset: 0;
    rows: Array<{
      id: string;
      key: string;
      value: {
        rev: string;
      };
      doc: Record<string, any> & {
        _id: string;
        _rev: string;
      };
    }>;
  };
};

type AppEnvOptions<TWorkspace> = WorkspacesManagerOptions<WEnv<TWorkspace>>;

// A context object for the whole app (across all workspaces)
// Instantiated in AppScreen.
export class AppEnv<TWorkspace> {
  statsTracker: StatsTracker;

  static async build<TWorkspace>(
    storage: Storage,
    appConfig: AppConfig,
    { ...wmOpts }: AppEnvOptions<TWorkspace>
  ) {
    console.group("AppEnv.build()");
    const wm = await WorkspacesManager.build(storage, wmOpts);
    const appEnv = new AppEnv(appConfig, wm);
    console.groupEnd();
    return appEnv;
  }

  constructor(
    public appConfig: AppConfig,
    public workspacesManager: WorkspacesManager<WEnv<TWorkspace>>
  ) {
    console.log("constructing AppEnv");

    this.statsTracker = new StatsTracker({
      getItemsCount: async () => {
        return 0;
      },
    });
  }

  // async createPlaygroundWorkspace({
  //   progressState,
  // }: {
  //   progressState: ProgressState;
  // }) {
  //   await this.workspacesManager.createBlankWorkspace(
  //     { name: "Playground" },
  //     {
  //       prepareDatabase: async (database: Database) => {
  //         const db = database.db;

  //         const json: PouchDBDump = await fetchJSON(
  //           "/data/demo-workspace.json"
  //         );

  //         progressState.setTotal(json.pouchDBDocs.total_rows);

  //         for (const row of json.pouchDBDocs.rows) {
  //           console.log("import", row);
  //           const doc = row.doc;
  //           await createOrOverwrite(db, doc);
  //           progressState.setProgress(progressState.progress + 1);
  //         }
  //       },
  //     }
  //   );
  // }

  // hasPlaygroundWorkspace() {
  //   return this.workspacesManager.state.entries.some(
  //     (entry) => entry.name === "Playground"
  //   );
  // }
}
