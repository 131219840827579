import { getUserFriendlyError } from "@/framework/errors/getUserFriendlyError";
import { onIntersection } from "@/lib/dom/onIntersection";
import { LoadablePlaceholder } from "@/lib/mobx-mui/loadable-skeleton/loadable-placeholder";
import { LoadableValue } from "@/lib/mobx/LoadableValue";
import { TranslationContext } from "@/modules/translation/TranslationContext";
import { CxProps } from "@/modules/types";
import { unpackTrpcErrors } from "@/server/client/unpackTrpcError";
import { trpc } from "@/utils/trpc";
import ReportIcon from "@mui/icons-material/Report";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useContext, useEffect, useRef, useState } from "react";

type TranslatableTextProps = CxProps & {
  text: string;
  subject: string;
  render?: (text: string) => React.ReactNode;
};

export const TranslatableText = ({
  text,
  subject,
  render,
  cx,
}: TranslatableTextProps) => {
  const { targetLang, sourceLang, autoTranslate } =
    useContext(TranslationContext);

  const ref = useRef<HTMLDivElement>(null);
  const [isTriggered, setTriggered] = useState(false);

  const { workspace } = cx.wEnv;

  const [loadableTranslatedText, setLoadableTranslatedText] =
    useState<LoadableValue<string> | null>();

  useEffect(() => {
    setLoadableTranslatedText(
      sourceLang
        ? new LoadableValue<string>(async () => {
            const settingsState = await workspace.settingsState.load();
            const apiKeys = {
              deeplApiKey: settingsState.deeplApiKeyState.value,
              googleTranslateApiKey:
                settingsState.googleTranslateApiKeyState.value,
            };

            const result = await unpackTrpcErrors(() =>
              trpc.translations.translate.query({
                subject,
                text,
                sourceLang: sourceLang,
                targetLang: targetLang ?? "en",
                apiKeys: apiKeys,
              })
            );

            return result.translation;
          })
        : null
    );
  }, [text, subject, sourceLang, targetLang]);

  useEffect(() => {
    if (ref.current) {
      onIntersection(ref.current, {}, (on) => {
        if (on) setTriggered(on);
      });
    }
  }, [ref.current]);

  const _render = render ?? ((text) => <>{text}</>);

  return (
    <span ref={ref}>
      {autoTranslate && isTriggered && loadableTranslatedText ? (
        <LoadablePlaceholder
          loadable={loadableTranslatedText}
          renderPlaceholder={() => (
            <>
              {text}
              <Tooltip arrow title="Translating...">
                <CircularProgress size="14px" sx={{ mx: 1 }} />
              </Tooltip>
            </>
          )}
          renderLoaded={(text) => _render(text) as any}
          renderError={(e) => (
            <>
              {text}
              <Tooltip arrow title={getUserFriendlyError(e)}>
                <ReportIcon
                  color="disabled"
                  sx={{ mx: 1, lineHeight: 0 }}
                  fontSize="small"
                />
              </Tooltip>
            </>
          )}
        />
      ) : (
        _render(text)
      )}
    </span>
  );
};
