import { observer } from "mobx-react";
import { EarlyAccessSignupDialog } from "../demo/EarlyAccessSignupDialog";
import { CxProps } from "../types";
import { WordSearchDialog } from "../word-search/WordSearchDialog";

type WorkspaceDialogsProps = CxProps;

export const WorkspaceDialogs = observer(({ cx }: WorkspaceDialogsProps) => {
  const { workspace } = cx.wEnv;
  const FlexibleDialog = workspace?.flexibleDialog?.component;
  return (
    <>
      <WordSearchDialog cx={cx} />
      <EarlyAccessSignupDialog cx={cx} />
      {FlexibleDialog && (
        <FlexibleDialog state={{ cx, dialog: workspace?.flexibleDialog }} />
      )}
    </>
  );
});
