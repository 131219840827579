import { rfcOrIsoToHumanDate } from "@/lib/prelude/time";
import { stripTags } from "@/lib/utils/stripTags";
import { getTrackOptimizedCoverUrlFn } from "@/modules/podcast/image/url";
import { TranslatableText } from "@/modules/translation/TranslatableText";
import { ButtonBaseProps } from "@mui/material/ButtonBase";
import { ListItemProps } from "@mui/material/ListItem";
import { observer } from "mobx-react";
import { clickTrack } from "../queue/clickTrack";
import { Track } from "../track/Track";
import { TrackListItemActions } from "../track/TrackListItemActions";
import { CxProps } from "../types";
import { TrackListItemButtonLayout } from "./track-list-item/TrackListItemButtonLayout";

export type TrackListItemButtonProps = {
  track: Track;
  onClick?: () => void;
  sx?: ButtonBaseProps["sx"];
  secondaryAction?: ListItemProps["secondaryAction"];
  lazy?: boolean;
} & CxProps;

export const TrackListItemButton = observer(
  ({ track, onClick, sx, cx, ...props }: TrackListItemButtonProps) => {
    const title = stripTags(track.title);

    return (
      <div>
        <TrackListItemButtonLayout
          onClick={() => (onClick ? onClick() : clickTrack(cx, track))}
          title={
            <TranslatableText
              text={title}
              subject={`${track.trackId}:title`}
              cx={cx}
            />
          }
          programmeTitle={
            track.podcast?.title ? stripTags(track.podcast?.title) : undefined
          }
          publishedAt={rfcOrIsoToHumanDate(track.publishedAt)}
          coverUrlFn={getTrackOptimizedCoverUrlFn(track)}
          isPlaying={track.isCurrent}
          isComplete={track.isComplete}
          listeningPositionPercent={track.listeningPositionPercent}
          sx={sx}
          secondaryAction={
            props.secondaryAction ?? <TrackListItemActions track={track} />
          }
          lazy={props.lazy}
          // debugInfo={"sortKey" in track ? track.sortKey : ""}
        />
      </div>
    );
  }
);
