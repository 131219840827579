// --- Deprecated scriptea types ---
export type TTranscript = {
  chapters: TChapter[];
};
export type TChapter = {
  title?: string | null | undefined;
  start: number;
  end: number;
  paragraphs: TParagraph[];
};
export type TParagraph = {
  start: number;
  end: number;
  sentences: TSentence[];
};
export type TSentence = {
  start: number;
  end: number;
  words: TWord[];
};
export type TWord = {
  start: number;
  end: number;
  text: string;
  bare_text?: string | null;
  confidence?: number | null;
  speaker?: number | null;
  speaker_confidence?: number | null;
};

// --- New types ---
// export const TextimeLevels = [
//   "document",
//   "chapter",
//   "section",
//   "paragraph",
//   "sentence",
//   "line",
//   "word",
// ];
// export type TextimeLevel = typeof TextimeLevels[number];

export enum TextimeLevel {
  word = 20,
  minisentence = 25,
  line = 30,
  sentence = 40,
  paragraph = 50,
  section = 60,
  chapter = 70,
  article = 80,
}

export type TextimeSpeaker = {
  id?: number;
  name?: string;
  confidence?: number;
};

// Flexible type for any level of textime
export type TextimeAny = {
  id?: string;
  level?: TextimeLevel;
  startTime?: number;
  endTime?: number;
  text?: string;
  bareText?: string;
  confidence?: number;
  speaker?: TextimeSpeaker;
  parts?: Textime[];
};

export type TextimeLeaf = {
  startTime?: number;
  text: string;
} & TextimeAny;

export type TextimeComposite = {
  parts: Textime[];
} & TextimeAny;

export type Textime = TextimeLeaf | TextimeComposite;

export type TextimeEntity = Textime & {
  id: string;
};

// --- Specific types for a podcast episode ---
//
// Episode -> Chapter -> Paragraph -> Word
//                          -id         -startTime
//                                      -endTime
//                                      -text

export type TextimeEpisode = TextimeComposite & {
  level: TextimeLevel.article;
  parts: TextimeChapter[];
};

export type TextimeChapter = TextimeComposite & {
  level: TextimeLevel.chapter;
  parts: TextimeParagraph[];
};

export type TextimeParagraph = TextimeComposite & {
  id?: string;
  level: TextimeLevel.paragraph;
  parts?: TextimeWord[];
};

export type TextimeWord = TextimeAny & {
  level: TextimeLevel.word;
  startTime?: number;
  endTime?: number;
  text: string;
};

// --- From transcript-parsers ---

export type Word = {
  startTime?: number;
  endTime?: number;
  text: string;
};

// Half-timed word
export type HTWord = {
  startTime: number;
  endTime?: number;
  text: string;
};

// Fully timed word
export type FTWord = {
  startTime: number;
  endTime: number;
  text: string;
};

export type SpeakerWithName = {
  id?: number;
  name: string;
};

export type _ParagraphBase = {
  startTime?: number;
  endTime?: number;
  speaker?: SpeakerWithName;
};

export type PlainParagraph = _ParagraphBase & {
  text: string;
};

export type TimedParagraph = _ParagraphBase & {
  parts: Word[];
};

export type TimedOrPlainParagraph = TimedParagraph | PlainParagraph;

export type PlainChapter = {
  title?: string;
  startTime?: number;
  endTime?: number;
  parts: PlainParagraph[];
};

export type TimedChapter = {
  title?: string;
  startTime?: number;
  endTime?: number;
  parts: TimedOrPlainParagraph[];
};

export type TimedTranscriptWithChapters = {
  parts: TimedChapter[];
};
export type TimedTranscriptWithParagraphs = {
  parts: TimedParagraph[];
};

export type PlainTranscript = {
  parts: PlainChapter[];
};

// --- TrpE processed

export type TrpeTimestamp = {
  text: string;
  seconds: number;
};

export type TrpeSegment = {
  timestamp: TrpeTimestamp;
  text: string;
};

export type TrpeSpeaker = {
  name: string;
};

export type TrpeParagraph = {
  translation_id: string;
  slug: string;
  speaker: TrpeSpeaker | null;
  timestamp: TrpeTimestamp | null;
  segments: TrpeSegment[] | null;
  text: string;
};

export type TrpeChapter = {
  title: string | null;
  paragraphs: TrpeParagraph[];
};

export type TrpeAudioChapter = {
  id: string;
  start_time: number;
  end_time: number;
  has_picture: boolean;
};

export type TrpeEpisode = {
  title: string;
  cover_url: string;
  audio_url: string;
  notes_html: string;
  intro_text: string | null;
  chapters: TrpeChapter[] | null;
  audio_chapters: TrpeAudioChapter[] | null;
};

// --- Exporting for Trpe

export type TrpeExportParagraph = {
  speaker: TrpeSpeaker | null;
  timestamp: TrpeTimestamp | null;
  segments: TrpeSegment[] | null;
  text: string;
};

export type TrpeExportChapter = {
  title: string | null;
  paragraphs: TrpeExportParagraph[];
};

export * from "./wid/WidTranscript";
