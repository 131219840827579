export const sendContactMessage = async ({
  action,
  name,
  email,
  message,
}: {
  action?: string;
  name?: string;
  email?: string;
  message: string;
}) => {
  const response = await fetch("/api/contact", {
    method: "post",
    body: JSON.stringify({
      action,
      name,
      email,
      message,
    }),
  });
  console.log("fetch resolved", response);
};
