import { action, makeObservable, observable } from "mobx";
import { SettingKey, SettingsEntity, log } from "./SettingsState";

export class SettingItemState<T> {
  value: T;
  initialValue: T;
  tempValue: T | null = null; // The value while the user is dragging the knob on the slider, we don't need to actually change the value until the user releases the knob
  disabled: boolean = false;
  label?: string = this.options.label;
  hint?: string = this.options.hint;

  constructor(
    public settingsEntity: SettingsEntity,
    public key: SettingKey,
    private options: { label?: string; hint?: string } = {}
  ) {
    this.initialValue = this.value = settingsEntity.doc[key] as T;
    makeObservable(this, {
      value: observable,
      tempValue: observable,
      disabled: observable,
      change: action,
      changeTemp: action,
      disable: action,
      enable: action,
    });
  }

  changeTemp(newValue: T) {
    this.tempValue = newValue;
  }

  change(newValue: T) {
    this.tempValue = null;
    this.value = newValue;
    log("change", this.key, newValue);
    this.settingsEntity.doc[this.key] = newValue as any;
    this.settingsEntity.save();
  }

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }
}
