export const DialogNames = [
  "help",
  "contact",
  "about",
  "workspaces",
  "feedback",
  "privacy",
];
export type DialogName = (typeof DialogNames)[number];

export type Route = {
  isRoot: boolean;
  dialog?: DialogName | null;
};
