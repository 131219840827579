import { ContactDialog, ContactDialogConfig } from "./ContactDialog";
import { useState } from "react";
import Button, { ButtonProps } from "@mui/material/Button";

export type TContactButtonProps = Omit<ButtonProps, "onClick"> & {
  topic?: string;
  config?: ContactDialogConfig;
};

export function ContactButton(props: TContactButtonProps) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { topic, ...otherProps } = props;

  return (
    <>
      <Button onClick={handleClickOpen} {...otherProps}>
        {props.children ?? "Contact"}
      </Button>
      <ContactDialog
        open={open}
        onClose={handleClose}
        topic={topic}
        config={props.config}
      />
    </>
  );
}
