import { z } from "zod";

export const TrackZ = z.object({
  filePath: z.string(),
  duration: z.number().optional().nullable(),
  language: z.string().nullish(),
  featuredAt: z.string().nullish(),
  transcriptId: z.string().optional().nullable(),
  transcriptionJobId: z.string().optional().nullable(),
  transcriptIds: z.array(z.string()).optional().nullable(),
  transcriptionJobIds: z.array(z.string()).optional().nullable(),
});

export type Track = z.infer<typeof TrackZ>;

export const TrackDocZ = TrackZ.extend({
  _id: z.string(),
  _rev: z.string(),
  type: z.literal("track"),
});

export type TrackDoc = z.infer<typeof TrackDocZ>;
