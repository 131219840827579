import { Iso2LanguageCode } from "@/lib/language/codes/iso2ToName";
import { FeedRefreshResultItem } from "./../feed/refresh";
import {
  TranscriptWChaPaSeId,
  TranscriptWChaPaSeIdZ,
} from "@/modules/transcript/formats/types";
import { fetchJSON } from "@/lib/utils/fetch";
import {
  Feed,
  FeedDocZ,
  FeedInfoClient,
  FeedInfoClientZ,
  FeedInfoZ,
  FeedItemZ,
  FeedZ,
  TrackZ,
} from "@/modules/data/types";
import { FeedResult, Pin, PinZ } from "@/modules/types";
import debug from "debug";
import z from "zod";
import { FeedRefreshResultZ } from "../feed/refresh";
import { prefixErrorsWith } from "@/lib/utils/error-handling-utils";
import { TrackPlayInfoApeZ } from "@/modules/track/track-play-info-ape";

const q = encodeURIComponent;

const log = debug("api");

export class API {
  rootUrl: string;

  constructor({ rootUrl }: { rootUrl: string }) {
    this.rootUrl = rootUrl;
  }

  async getV2(path: string) {
    log("fetching", path, "...");
    return fetchJSON(`/api/v2${path}`);
  }

  async postV2(path: string, body: any) {
    const opts = {
      method: "post",
      body: JSON.stringify(body),
    };
    return fetchJSON(`/api/v2${path}`, opts);
  }

  // ---

  async getFeedByUrl(feedUrl: string): Promise<FeedResult> {
    const result = await this.getV2(`/feeds/byUrl?url=${q(feedUrl)}`);

    const feedDoc = FeedDocZ.parse(result);

    const { _id: feedId, _rev, type, ...feed } = feedDoc;

    return { feedId, ...feed, items: feedDoc.items };
  }

  async getFeedById(feedId: string): Promise<Feed> {
    const result = await this.getV2(`/feeds/${q(feedId)}`);

    const feedDoc = FeedDocZ.parse(result.feed);

    const { _id, _rev, type, ...feed } = feedDoc;

    return { ...feed };
  }

  async refreshFeeds(
    feeds: { url: string; etag?: string | null }[]
  ): Promise<FeedRefreshResultItem[]> {
    const result = await this.postV2("/feeds/refresh", {
      feeds,
    });

    const feedsResult = FeedRefreshResultZ.parse(result);

    return feedsResult;
  }

  async searchPodcasts(query: string): Promise<Feed[]> {
    const result = await this.getV2(`/feeds/search?query=${q(query)}`);

    const feeds = z.array(FeedZ).parse(result);

    return feeds;
  }

  async getTrendingPodcasts(
    options: {
      category?: number | null;
      language?: Iso2LanguageCode | null;
    } = {}
  ): Promise<FeedInfoClient[]> {
    const result = await this.getV2(
      `/feeds/trending?category=${q(options.category ?? "")}&language=${q(
        options.language ?? ""
      )}`
    );

    const feeds = z.array(FeedInfoClientZ).parse(result);

    return feeds;
  }

  async getTranscriptSlice(
    trackId: string,
    opts: {
      startTime: number;
      endTime: number;
      serviceCode?: "deepgram" | "whisper" | undefined;
    }
  ): Promise<TranscriptWChaPaSeId> {
    const result = await this.getV2(
      buildUrl(`/tracks/${q(trackId)}/transcribe-slice`, {
        startTime: opts.startTime,
        endTime: opts.endTime,
        serviceCode: opts.serviceCode,
      })
    );
    return TranscriptWChaPaSeIdZ.parse(result.transcript);
  }

  async getTranscriptSliceOptionally(
    trackId: string,
    opts: {
      startTime: number;
      endTime: number;
      serviceCode?: "deepgram" | "whisper" | undefined;
      skipCache?: boolean;
      ifExists?: boolean;
    }
  ): Promise<TranscriptWChaPaSeId | null> {
    const result = await this.getV2(
      buildUrl(`/tracks/${q(trackId)}/transcribe-slice`, {
        startTime: opts.startTime,
        endTime: opts.endTime,
        serviceCode: opts.serviceCode,
        forceNew: opts.skipCache ? "yes" : undefined,
        ifExists: opts.ifExists ? "yes" : undefined,
      })
    );
    if (result.none) {
      return null;
    }
    return TranscriptWChaPaSeIdZ.parse(result.transcript);
  }

  async getTrackInfo(trackId: string, stack: string[] = []) {
    console.log("getTrackInfo", trackId, { stack });
    const result = await this.getV2(`/tracks/${q(trackId)}/info`);

    return TrackPlayInfoApeZ.parse(result);
  }

  getAudioUrl(trackId: string) {
    // This will redirect to the audio in the public data directory
    return `${this.rootUrl}/api/v2/tracks/${q(trackId)}/audio`;
  }

  async getPins(userId: string, trackId: string): Promise<Pin[]> {
    const payload = await this.getV2(
      `/pins/list?userId=${q(userId)}&trackId=${q(trackId)}`
    );
    return z.array(PinZ).parse(payload);
  }

  async createPin(
    id: string,
    userId: string,
    trackId: string,
    timestamp: number,
    text: string,
    translation: string | null,
    lang: string | null,
    translationLang: string | null,
    transcriptContext?: [string, string, string] | null
  ) {
    await this.postV2("/pins/create", {
      id,
      userId,
      trackId,
      timestamp,
      text,
      translation,
      lang,
      translationLang,
      transcriptContext,
    });
  }

  async changePinIsPublic(
    trackId: string,
    pinId: string,
    isPublic: boolean
  ): Promise<{ isPublic: boolean }> {
    const payload = await this.postV2("/pins/admin/isPublic", {
      trackId,
      pinId,
      isPublic: isPublic ? "true" : "false",
    });
    return z.object({ isPublic: z.boolean() }).parse(payload);
  }
}

function withExponentialBackoff<P, R>(options: {
  attempt: () => Promise<P>;
  isDone: (result: P) => boolean;
  isFailure: (result: P) => boolean;
  getResult: (result: P) => R;
  getError: (result: P) => Error;
}): Promise<R> {
  return new Promise((resolve, reject) => {
    const attempt = async (n: number) => {
      const result = await options.attempt();

      if (options.isDone(result)) {
        resolve(options.getResult(result));
      } else if (options.isFailure(result)) {
        reject(options.getError(result));
      } else {
        const delay = 2 ** n * 1000;
        log(`Waiting ${delay}ms before retrying...`);
        setTimeout(() => attempt(n + 1), delay);
      }
    };

    attempt(0);
  });
}

function buildUrl(
  baseUrl: string,
  queryParams: Record<string, string | number | undefined | null> = {}
) {
  let url = baseUrl;
  const query = Object.entries(queryParams)
    .filter(([key, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
    .join("&");
  if (query) {
    url += `?${query}`;
  }
  return url;
}
