import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { observer } from "mobx-react";
import { Router } from "../routing/Router";

export const AppBackButton = observer(({ router }: { router: Router }) => {
  if (router.canGoBack) {
    return (
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 2 }}
        onClick={() => router.goBack()}
      >
        <ArrowBackIcon />
      </IconButton>
    );
  } else {
    return null;
  }
});
