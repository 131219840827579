import { PrismaClient } from "@prisma/client";
import { Logger } from "@/framework/logging/base";

export * from "./feeds/types";
export * from "./tracks/types";
export * from "../transcript/couch/types";
export * from "./transcriptionJobs/types";

export type Cx = {
  logger: Logger;
  db?: PouchDB.Database;
};

export type DataCx = Cx & {
  prisma?: PrismaClient;
};
