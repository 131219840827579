import { StandardDialog, StandardDialogProps } from "@/lib/mui/StandardDialog";
import { debugFactory } from "@/lib/utils/debug";
import { useEffect, useRef } from "react";

const debug = debugFactory("routing:BackableDialog");

export type BackableDialogProps = StandardDialogProps & {
  open: boolean;
  onClose: () => void;
};

export function BackableDialog(props: BackableDialogProps) {
  const { open, onClose, ...otherProps } = props;
  console.log("BackableDialog", open);

  const isPushedToHistoryRef = useRef(false);

  // Keep track of whether the dialog was open, so we know the previous state
  // during the `useEffect` below.
  const prevOpen = useRef<boolean>();

  useEffect(() => {
    debug("open", prevOpen.current, "=>", open);

    if (!prevOpen.current && open) {
      debug("just opened");

      // Add `llbnemmpCloseDialog` to the current history state, so that
      // when the user comes back by pressing the back button, we can
      // call our callback to close the dialog.
      debug("----<>---- REPLACE");
      window.history.replaceState(
        { ...window.history.state, llbnemmpCloseDialog: true },
        ""
      );
      debug("----->>--- PUSH");
      window.history.pushState(window.history.state, "");
      isPushedToHistoryRef.current = true;

      const popStateHandler = (event: PopStateEvent) => {
        debug("---<<----- POP", event.state);
        const { llbnemmpCloseDialog, ...remainingState } = event.state;
        if (llbnemmpCloseDialog) {
          isPushedToHistoryRef.current = false;
          debug("closing dialog");
          debug("----<>---- REPLACE");
          // Remove our `llbnemmpCloseDialog` flag from the history state.
          window.history.replaceState(remainingState, "");
          onClose();
        }
      };
      debug("attaching popstate");
      window.addEventListener("popstate", popStateHandler);

      // Callback when the component is unmounted.
      return () => {
        debug("removing popstate");
        if (isPushedToHistoryRef.current) {
          debug(
            "--------- BACK - backing out before remoing popstate listener"
          );
          // This component has been without calling `close`; we need to remove the state so that the user doesn't have to press the back button twice.
          window.history.back();
        }
        window.removeEventListener("popstate", popStateHandler);
      };
    } else if (prevOpen.current && !open) {
      debug("just closed");
    }
    prevOpen.current = open;

    // Callback when the component is unmounted.
    return () => null;
  }, [open, onClose]);

  const close = () => {
    // This will trigger the `popStateHandler` above.
    debug("--------- BACK");
    window.history.back();
  };

  return <StandardDialog open={open} onClose={close} {...otherProps} />;
}
