import { action, computed, makeAutoObservable, observable } from "mobx";
import { debugFactory } from "../utils/debug";
import { getSequentialNumber } from "../utils/numbering";
import { MaybeLoaded } from "@/lib/utils/maylo";

export type Err = unknown;

export enum State {
  Unstarted,
  InProgress,
  Succeeded,
  Failed,
}

// Our internal state
type StatefulData<T> =
  | { state: State.Unstarted }
  | { state: State.InProgress; promise: Promise<T> }
  | { state: State.Succeeded; promise: Promise<T>; value: T }
  | { state: State.Failed; promise: Promise<T>; error: Err };

// Accepts a function that returns a promise.
// Whenever the value is first accessed, it will call the function and
// and exposes information about it's status
// (in progress, succeeded, failed, resolved value, error)
// Can be passed to <Loadable />.

type TOptions = {
  debugInfo?: string;
};
export class LoadableValue<T> {
  loader: () => Promise<T>;
  statefulData: StatefulData<T> = { state: State.Unstarted };

  // for debugging
  id: number;
  debugInfo: string | null;
  debug: (...args: any) => void;

  static Value<T>(value: T): LoadableValue<T> {
    return new LoadableValue(() => Promise.resolve(value), true);
  }

  static Never<T>(): LoadableValue<T> {
    return new LoadableValue(() => new Promise(() => null), true);
  }

  constructor(
    loader: () => Promise<T>,
    loadImmediately = false,
    debugInfo: string | null | undefined = null
  ) {
    this.id = getSequentialNumber();
    this.debugInfo = debugInfo;
    this.debug = debugFactory(
      `LoadableValue:${this.id}${debugInfo ? `(${debugInfo})` : ""}`
    );
    this.debug(`instantiate - loadImmediately: ${loadImmediately}`);
    this.loader = loader;

    makeAutoObservable(this, {
      statefulData: observable,
      state: computed,
      value: computed,
      error: computed,
      succeed: action,
      fail: action,
      stateString: computed,
      inProgress: computed,
      load: action,
      reload: action,
      succeeded: computed,
    });

    if (loadImmediately) {
      this.debug("loading immediately");
      this.load();
    }
  }

  get state(): State {
    return this.statefulData.state;
  }
  get stateInspect(): string {
    switch (this.statefulData.state) {
      case State.Unstarted:
        return "unstarted";
      case State.InProgress:
        return "in-progress";
      case State.Succeeded:
        return "succeeded";
      case State.Failed:
        return "failed";
    }
  }
  get value(): T | undefined {
    if (this.statefulData.state === State.Succeeded) {
      return this.statefulData.value;
    }
    return;
  }
  get error(): Err {
    if (this.statefulData.state === State.Failed) {
      return this.statefulData.error;
    }
    return;
  }
  get promise(): Promise<T> | undefined {
    if (this.statefulData.state === State.InProgress) {
      return this.statefulData.promise;
    }
    return;
  }

  load(): Promise<T> {
    this.debug(`load() - state: ${this.stateString}`);
    if (this.statefulData.state === State.Unstarted) {
      const promise = this.loader();
      this.statefulData = {
        state: State.InProgress,
        promise: promise,
      };
      promise.then(this.succeed.bind(this), this.fail.bind(this));
    }
    this.debug(`load() returning promise`);
    return this.statefulData.promise;
  }

  reload(): Promise<T> {
    this.debug(`reload() - state: ${this.stateString}`);
    this.statefulData = {
      state: State.Unstarted,
    };
    return this.load();
  }

  get stateString() {
    if (this.state === State.Unstarted) return "unstarted";
    if (this.state === State.InProgress) return "in-progress";
    if (this.state === State.Succeeded) return "succeeded";
    if (this.state === State.Failed) return "failed";
    return "unknown";
  }
  get unstarted() {
    return this.state === State.Unstarted;
  }
  get inProgress() {
    return this.state === State.InProgress;
  }
  get succeeded() {
    return this.state === State.Succeeded;
  }
  get failed() {
    return this.state === State.Failed;
  }

  succeed(value: T) {
    this.debug(
      `succeed() called with a ${typeof value} - state: ${
        this.statefulData.state
      }`
    );
    this.debug("value:", value);
    if (typeof value === "undefined" || value === null) {
      console.warn(
        `LoadableValue:${this.id}${
          this.debugInfo ? `(${this.debugInfo})` : ""
        } succeeded with ${value}`
      );
    }
    if (this.statefulData.state === State.InProgress) {
      this.statefulData = {
        state: State.Succeeded,
        value: value,
        promise: this.statefulData.promise,
      };
    } else {
      throw `Cannot transition to succeeded state from ${this.stateString}`;
    }
  }

  fail(error: string) {
    this.debug(`fail() called - state: ${this.statefulData.state}`);
    console.error("fail", error, this.state);
    if (this.statefulData.state === State.InProgress) {
      this.statefulData = {
        state: State.Failed,
        error: error,
        promise: this.statefulData.promise,
      };
    } else {
      throw `Cannot transition to failed state from ${this.stateString}`;
    }
  }

  then(
    callback: (value: T) => void,
    failureCallback?: (error: any) => void
  ): void {
    this.debug("then()");
    if (this.statefulData.state === State.Unstarted) {
      this.load();
    }

    if (this.statefulData.state === State.InProgress) {
      if (failureCallback) {
        this.statefulData.promise.then(callback, failureCallback);
      } else {
        this.statefulData.promise.then(callback);
      }
    } else if (this.statefulData.state === State.Succeeded) {
      callback(this.statefulData.value);
    }
  }

  thenR<R>(callback: (value: T) => Promise<R>): Promise<R> {
    this.debug("then()");
    if (this.statefulData.state === State.Unstarted) {
      this.load();
    }

    if (this.statefulData.state === State.InProgress) {
      return this.statefulData.promise.then(callback);
    } else if (this.statefulData.state === State.Succeeded) {
      return callback(this.statefulData.value);
    } else {
      return Promise.reject(
        (
          this.statefulData as {
            state: State.Failed;
            promise: Promise<T>;
            error: string;
          }
        ).error
      );
    }
  }

  ifSucceeded<R>(callback: (value: T) => R): R | undefined {
    if (this.statefulData.state === State.Succeeded) {
      return callback(this.statefulData.value);
    }
    return;
  }

  // I think this is like "cata" or "fold" in monet.js Either
  // either.cata(
  //   failure => `oh dear it failed because ${failure}`,
  //   success => `yay! ${success}`
  // )
  select<R>(
    succeededCallback: (value: T) => R,
    loadingCallback: (error?: Err) => R
  ): R {
    this.debug("select()");
    if (this.statefulData.state === State.Unstarted) {
      // this.load();
    }

    if (this.statefulData.state === State.Succeeded) {
      return succeededCallback(this.statefulData.value);
    }
    if (this.statefulData.state === State.Failed) {
      return loadingCallback(this.statefulData.error);
    }
    return loadingCallback();
  }

  toMaybeLoaded() {
    this.debug("toMaybeLoaded()");
    return this.select<MaybeLoaded<T>>(
      (value) => MaybeLoaded.Value(value),
      (error) => (error ? MaybeLoaded.Failure(error) : MaybeLoaded.Pending())
    );
  }

  map<U>(fn: (value: T) => U, options: TOptions = {}): LoadableValue<U> {
    this.debug("map()");
    return new LoadableValue<U>(
      async () => {
        const value = await this;
        return fn(value);
      },
      false,
      options?.debugInfo ?? `${this.debugInfo ?? ""}-map`
    );
  }

  chain<U>(
    fn: (value: T) => LoadableValue<U>,
    options: TOptions = {}
  ): LoadableValue<U> {
    this.debug("chain()");
    return new LoadableValue<U>(
      async () => {
        const value = await this;
        return await fn(value);
      },
      false,
      options?.debugInfo ?? `${this.debugInfo ?? ""}-chain`
    );
  }
}
