import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/system/Stack";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { pause } from "../playing-track/pause";
import { enqueueTrack } from "../queue/enqueueTrack";
import { playTrack } from "../queue/playTrack";
import { Queue } from "../queue/Queue";
import { removeFromQueue } from "../queue/removeFromQueue";
import { Track } from "@/modules/track/Track";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { TrackPopupMenuIconButton } from "./TrackPopupMenuIconButton";

export const TrackListItemActions = ({ track }: { track: Track }) => {
  return (
    <Stack
      direction="row"
      sx={
        {
          // background: "rgba(0, 0, 255, 0.2)"
        }
      }
    >
      <PlayPauseIconButton track={track} />
      <AddToQueueIconButton track={track} />
      <TrackPopupMenuIconButton track={track} />
    </Stack>
  );
};

const PlayPauseIconButton = observer(({ track }: { track: Track }) => {
  const isPlaying = track.isCurrent && track.workspace.playerState.playing;

  const handleClick = () => {
    if (isPlaying) {
      recordEvent("click-pause-from-list-item");
      pause(track);
    } else {
      recordEvent("click-play-from-list-item");
      playTrack(track);
    }
  };

  return (
    <IconButton size="large" onClick={handleClick}>
      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
  );
});

const AddToQueueIconButton = observer(({ track }: { track: Track }) => {
  const [queue, setQueue] = useState<Queue | null>(null);
  useEffect(() => {
    track.workspace?.queue?.then(setQueue);
  }, []);

  const isInQueue = queue?.isTrackInQueue(track);

  const handleClick = () => {
    if (isInQueue) {
      recordEvent("remove-from-queue-from-list-item");
      removeFromQueue(track);
    } else {
      recordEvent("add-to-queue-from-list-item");
      enqueueTrack(track);
    }
  };

  return (
    <IconButton size="large" onClick={handleClick}>
      {isInQueue ? <PlaylistRemoveIcon /> : <PlaylistAddIcon />}
    </IconButton>
  );
});
