import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { observer } from "mobx-react";
import * as React from "react";
import { AppToolbar } from "./AppToolbar";
import { AppNotificationsDisplay } from "./AppNotificationsDisplay";
import { StarterCx } from "./contexts";
import { theme } from "./theme";

function HideOnScroll(props: { children: React.ReactElement }) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type AppBarLayoutProps<TWorkspace> = {
  children: React.ReactNode;
  cx: StarterCx<TWorkspace>;
};

export const AppBarLayout = observer(
  <TWorkspace,>({ children, cx }: AppBarLayoutProps<TWorkspace>) => {
    const screenTopPadding = "0px";
    const StyledAppBar = styled(AppBar)(() => ({
      paddingTop: screenTopPadding,
    }));
    const StyledToolbar = styled(Toolbar)(() => ({
      paddingTop: screenTopPadding,
      boxSizing: "content-box",
    }));

    return (
      <AppNotificationsDisplay>
        <Box
          className="text-background"
          sx={{
            flexGrow: 1,
            minHeight: "100vh",
            pb: 20,
          }}
        >
          <HideOnScroll>
            <StyledAppBar
              position="fixed"
              color="inherit"
              sx={{ background: "inherit", boxShadow: "none" }}
            >
              <AppToolbar cx={cx} />
            </StyledAppBar>
          </HideOnScroll>
          <StyledToolbar />

          {children}

          {/* <CreationDialogsContainer /> */}
        </Box>
      </AppNotificationsDisplay>
    );
  }
);
