import Box, { BoxProps } from "@mui/material/Box";
import { SubscreenTitle } from "../main/SubscreenTitle";

// A top-level section of a screen
// - gap between sections
// - horizontal margin to the screen edges

export type ScreenSectionProps = {
  title?: string;
  children: React.ReactNode;
  centeredContent?: boolean;
  sx?: BoxProps["sx"];
};

export const ScreenSection = ({
  children,
  title,
  centeredContent,
  sx,
}: ScreenSectionProps) => {
  return (
    <Box
      className={"screen-section"}
      sx={{
        mb: 8,
        px: 2,
        maxWidth: "50rem",
        mx: "auto",
        textAlign: centeredContent ? "center" : null,
        ...sx,
      }}
    >
      {title && <SubscreenTitle>{title}</SubscreenTitle>}
      {children}
    </Box>
  );
};
