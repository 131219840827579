import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/system/Box";
import { useEffect } from "react";
import { ContactButton } from "../contact/ContactButton";
import { ContactDialogConfig } from "../contact/ContactDialog";
import { trackSimpleEvent } from "../tracking/trackEvent";
import { recordEvent } from "../tracking/usage/recordEvent";
import type { AppConfigContext } from "../types";
import { ImprintContentText } from "./ImprintContentText";

export function AboutDialog({
  cx,
  open,
  onClose,
  contactDialogConfig,
}: {
  cx: AppConfigContext;
  open: boolean;
  onClose: () => void;
  contactDialogConfig?: ContactDialogConfig;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { appConfig } = cx;

  useEffect(() => {
    if (open) {
      recordEvent("about-dialog-open");
      trackSimpleEvent("about-dialog-open");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="about-dialog-title"
      fullScreen={fullScreen}
    >
      {/* <DialogTitle id="about-dialog-title">About</DialogTitle> */}
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <Typography variant="h5">{appConfig.name}</Typography>
          {appConfig.stage && (
            <Typography variant="body2">{appConfig.stage}</Typography>
          )}
          {appConfig.version && (
            <Typography variant="body2">Version {appConfig.version}</Typography>
          )}

          {appConfig.websiteUrl && (
            <Typography variant="body2">
              <a href={appConfig.websiteUrl} target="_blank" rel="noreferrer">
                {appConfig.websiteUrl}
              </a>
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: 1 }}>
          <ImprintContentText />
        </Box>
        <Box sx={{ mt: 1 }}>
          <ContactButton variant="outlined" config={contactDialogConfig} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
