import { DateTime, Duration } from "luxon";
import { doubleDigit, tripleDigit } from "../../utils/numbers";
import { isMissing } from "../presence";

export { parseTimestampToSeconds } from "./parse";

export function getCurrentTimeForFilename({
  millis,
}: { millis?: boolean } = {}): string {
  return DateTime.now().toFormat(`yyyy-MM-dd-HH-mm-s${millis ? "-SSS" : ""}`);
}

export function dateTimeRfcToIso(dateTimeString: string): string {
  const dt = DateTime.fromRFC2822(dateTimeString);
  if (!dt.isValid)
    throw new Error(`Invalid date time string: ${dateTimeString}`);
  const s = dt.toISO();
  if (!s)
    throw new Error(
      `Invalid date time string: ${dateTimeString} (could not be converted to ISO)`
    );
  return s;
}

export function isoToRelativeCalendar(
  dateTimeString: string
): string | undefined {
  return DateTime.fromISO(dateTimeString)?.toRelativeCalendar() ?? undefined;
}

export function isoToHumanDateTime(dateTimeString: string): string {
  return DateTime.fromISO(dateTimeString).toLocaleString(
    DateTime.DATETIME_FULL
  );
  //   {
  //   weekday: "short",
  //   month: "short",
  //   day: "2-digit",
  //   hour: "1-digit",
  // });
}

export function rfcToHumanDateTime(
  dateTimeString: string | undefined
): string | undefined {
  if (!dateTimeString) return undefined;
  return DateTime.fromRFC2822(dateTimeString).toLocaleString(
    DateTime.DATETIME_FULL
  );
}

export function rfcToHumanDate(
  dateTimeString: string | undefined
): string | undefined {
  if (!dateTimeString) return undefined;
  const time = DateTime.fromRFC2822(dateTimeString);
  if (time.invalidExplanation) {
    console.warn(
      `Invalid date: ${dateTimeString} - ${time.invalidExplanation}`
    );
  }
  return time.toLocaleString(DateTime.DATE_FULL);
}

export function isoToHumanDate(
  dateTimeString: string | undefined
): string | undefined {
  if (!dateTimeString) return undefined;
  const time = DateTime.fromISO(dateTimeString);
  if (time.invalidExplanation) {
    console.warn(
      `Invalid date: ${dateTimeString} - ${time.invalidExplanation}`
    );
  }
  return time.toLocaleString(DateTime.DATE_FULL);
}

export function rfcOrIsoToHumanDate(
  dateTimeString: string | undefined
): string | undefined {
  if (!dateTimeString) return undefined;
  let time = DateTime.fromRFC2822(dateTimeString);

  if (time.invalidExplanation) {
    const explanation1 = time.invalidExplanation;
    time = DateTime.fromISO(dateTimeString);
    if (time.invalidExplanation) {
      const explanation2 = time.invalidExplanation;
      console.warn(
        `Invalid date: ${dateTimeString} - ${explanation1}; ${explanation2}`
      );
    }
  }

  return time.toLocaleString(DateTime.DATE_FULL);
}

export function formatMilliseconds(
  ms: number,
  options: { includeHours?: boolean; decimals?: number } = {}
) {
  const { includeHours = false, decimals = 0 } = options;

  const sign = ms < 0 ? "-" : "";
  const r = Math.abs(ms);

  const milliseconds = Math.floor(r % 1000);
  const rSeconds = Math.floor(r / 1000);

  const seconds = rSeconds % 60;
  const rMinutes = Math.floor(rSeconds / 60);

  const minutes = rMinutes % 60;
  const rHours = Math.floor(rMinutes / 60);

  const hours = Math.abs(rHours);

  const decimalPart = decimals
    ? `.${tripleDigit(milliseconds).slice(0, decimals)}`
    : "";

  if (includeHours || hours > 0) {
    return `${sign}${hours}:${doubleDigit(minutes)}:${doubleDigit(
      seconds
    )}${decimalPart}`;
  } else {
    return `${sign}${minutes}:${doubleDigit(seconds)}${decimalPart}`;
  }
}

export function formatSeconds(
  seconds: number,
  options: { includeHours?: boolean; decimals?: number } = {}
) {
  return formatMilliseconds(seconds * 1000, options);
}

export function secondsToStringTimestampWithHours(seconds: number) {
  if (seconds === null) return null;

  const secondsComponent = seconds % 60;
  const minutes = Math.floor(seconds / 60);

  const minutesComponent = minutes % 60;

  const hoursComponent = Math.floor(minutes / 60);

  return `${hoursComponent}:${doubleDigit(minutesComponent)}:${doubleDigit(
    secondsComponent
  )}`;
}

export function secondsToStringTimestampWithMinutesOrHours(
  seconds: number
): string {
  const secondsComponent = Math.floor(seconds % 60);
  const minutes = Math.floor(seconds / 60);

  const minutesComponent = minutes % 60;

  const hoursComponent = Math.floor(minutes / 60);

  if (hoursComponent === 0) {
    return `${minutesComponent}:${doubleDigit(secondsComponent)}`;
  } else {
    return `${hoursComponent}:${doubleDigit(minutesComponent)}:${doubleDigit(
      secondsComponent
    )}`;
  }
}

export function secondsToStringTimestampWithMinutesOrHoursDecimal(
  seconds: number
) {
  if (seconds === null) return null;

  const subsecond = seconds % 1;
  const decimalComponent = Math.floor(subsecond * 10);

  const r = Math.floor(seconds);

  const secondsComponent = r % 60;
  const minutes = Math.floor(r / 60);

  const minutesComponent = minutes % 60;

  const hoursComponent = Math.floor(minutes / 60);

  if (hoursComponent === 0) {
    return `${minutesComponent}:${doubleDigit(
      secondsComponent
    )}.${decimalComponent}`;
  } else {
    return `${hoursComponent}:${doubleDigit(minutesComponent)}:${doubleDigit(
      secondsComponent
    )}.${decimalComponent}`;
  }
}

export function millisecondsToStringTimestampWithMinutesOrHours(
  milliseconds: number
) {
  if (milliseconds === null) return null;

  const ms = milliseconds % 1000;
  const decimalComponent = tripleDigit(Math.floor(ms));

  const r = Math.floor(milliseconds / 1000);

  const secondsComponent = r % 60;
  const minutes = Math.floor(r / 60);

  const minutesComponent = minutes % 60;

  const hoursComponent = Math.floor(minutes / 60);

  if (hoursComponent === 0) {
    return `${minutesComponent}:${doubleDigit(
      secondsComponent
    )}.${decimalComponent}`;
  } else {
    return `${hoursComponent}:${doubleDigit(minutesComponent)}:${doubleDigit(
      secondsComponent
    )}.${decimalComponent}`;
  }
}

export function millisecondsToStringTimestampWithMinutesAndHours(
  milliseconds: number
) {
  if (milliseconds === null) return null;

  const ms = milliseconds % 1000;
  const decimalComponent = tripleDigit(Math.floor(ms));

  const r = Math.floor(milliseconds / 1000);

  const secondsComponent = r % 60;
  const minutes = Math.floor(r / 60);

  const minutesComponent = minutes % 60;

  const hoursComponent = Math.floor(minutes / 60);

  return `${hoursComponent}:${doubleDigit(minutesComponent)}:${doubleDigit(
    secondsComponent
  )}.${decimalComponent}`;
}

export function secondsToDurationString(seconds: number) {
  if (isMissing(seconds) || seconds === Infinity) return "";

  if (seconds < 10) {
    seconds = Math.trunc(seconds * 10) / 10;
  } else {
    seconds = Math.trunc(seconds);
  }

  return Duration.fromObject(
    seconds < 60
      ? { seconds: seconds }
      : seconds < 3600
      ? { minutes: 0, seconds: seconds }
      : { hours: 0, minutes: 0, seconds: seconds }
  )
    .normalize()
    .toHuman({ unitDisplay: "short" });
}
