import { AppConfig } from "@/lib/nemmp/app-config/types";
import { createContext, useContext } from "react";
import { Router } from "../routing/Router";
import type { StarterConfig } from "../types";
import { WEnv } from "./WEnv";

export const AppConfigContext = createContext<AppConfig | null>(null);

export function useAppConfig() {
  const appConfig = useContext(AppConfigContext);
  if (!appConfig) {
    throw new Error("AppConfigContext not set");
  }
  return appConfig;
}

// export const StarterContext = createContext<{
//   appConfig: AppConfig;
//   router: Router;
// } | null>(null);

// export function useStarterContext() {
//   const context = useContext(StarterContext);
//   if (!context) {
//     throw new Error("StarterContext not set");
//   }
//   return context;
// }

export type StarterCx<TWorkspace> = {
  appConfig: AppConfig;
  router: Router;
  starterConfig: StarterConfig<TWorkspace>;
  wEnv: WEnv<TWorkspace>;
};
