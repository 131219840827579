import { action, computed, makeAutoObservable, observable } from "mobx";

export class ProgressStateCancellable {
  error: string | null = null;
  messages: string[] = [];

  onCancelCb: (() => void) | null = null;

  constructor() {
    makeAutoObservable(this, {
      error: observable,
      messages: observable,
      fail: action,
      setMessages: action,
    });
  }

  fail(error: string | null) {
    this.error = error;
  }

  setMessages(messages: string[]) {
    this.messages = messages;
  }

  setMessage(message: string) {
    this.setMessages([message]);
  }

  onCancel(cb: () => void) {
    this.onCancelCb = cb;
  }

  cancel() {
    if (this.onCancelCb) {
      this.onCancelCb();
    }
  }
}
