import { ItemTypes } from "@/modules/drag/constants";
import { TrackListItemButton } from "@/modules/episode/TrackListItemButton";
import { Track } from "@/modules/track/Track";
import { useEffect, useState } from "react";
import { useDrag, useDragLayer, useDrop } from "react-dnd";
import type { CxProps } from "../types";
import { clickTrack } from "./clickTrack";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { Queue } from "@/modules/queue/Queue";

type QueueItemDisplayProps = {
  track: Track;
  queue: Queue;
  lazy?: boolean;
} & CxProps;

type RelativePosition = "before" | "after";

export const QueueItemDisplay = ({
  track,
  queue,
  cx,
  lazy,
}: QueueItemDisplayProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.QUEUE_EPISODE,
    item: track,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1, position: "relative" }}
    >
      <DraggingTargetBox episode={track} pos={"before"} />
      <DraggingTargetBox episode={track} pos={"after"} />

      <TrackListItemButton
        cx={cx}
        key={track.key}
        sx={{ my: 2 }}
        track={track}
        onClick={() => clickTrack(cx, track)}
        lazy={lazy}
        // secondaryAction={
        //   <Tooltip title="Remove from queue">
        //     <IconButton
        //       edge="end"
        //       aria-label="remove from queue"
        //       onClick={() => removeFromQueue(track)}
        //     >
        //       <DeleteIcon />
        //     </IconButton>
        //   </Tooltip>
        // }
      />
    </div>
  );
};

const DraggingTargetBox = ({
  episode,
  pos,
}: {
  episode: Track;
  pos: RelativePosition;
}) => {
  const [delayPassed, setDelayPassed] = useState(false);
  const { isDragging } = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging(),
    };
  });
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.QUEUE_EPISODE,
      drop: (draggedEpisode: Track) => {
        recordEvent("queue-item-dropped");
        console.log(
          "Dropped",
          draggedEpisode.friendlyReference,
          pos
          // episode.title
        );
        // move draggedEpisode before/after episode
        const { workspace } = draggedEpisode;
        const { queue } = workspace;
        queue?.then((queue) => {
          queue.moveEpisode(draggedEpisode, episode, pos);
        });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [episode]
  );

  useEffect(() => {
    if (isDragging) {
      setTimeout(() => {
        setDelayPassed(true);
      }, 100);
    } else {
      setDelayPassed(false);
    }
  }, [isDragging]);

  if (isDragging && delayPassed) {
    return (
      <div
        ref={drop}
        style={{
          position: "absolute",
          inset: "0",
          bottom: pos === "after" ? 0 : "50%",
          top: pos === "after" ? "50%" : 0,
          borderTop: isOver && pos === "before" ? "5px solid #335" : undefined,
          borderBottom:
            isOver && pos === "after" ? "5px solid #335" : undefined,
          // background: isOver ? "yellow" : pos === "after" ? "orange" : "green",
          // opacity: "0.1",
          zIndex: delayPassed ? 2 : -1,
        }}
      />
    );
  } else {
    return null;
  }
};
