import { makeAutoObservable, observable, action } from "mobx";
import { sleep } from "../utils";

export class DialogState {
  isOpen: boolean;

  constructor(isOpen = false) {
    this.isOpen = isOpen;
    makeAutoObservable(this, {
      isOpen: observable,
      open: action,
      close: action,
    });
  }

  open() {
    this.isOpen = true;
  }

  async close(): Promise<void> {
    console.log("closing");
    this.isOpen = false;
    console.log("closed", this.isOpen);

    // Give the dialog a chance to transition out before resetting the state
    // & the BackableDialogMobx to navigate back.

    // FIXME: wait until the route has actually changed

    await sleep(500);
  }
}
