import { appConfig } from "@/config/app-config";
import { loadWorkspacesData } from "@/framework/workspacing/loadWorkspacesData";
import { debugFactory } from "@/lib/utils/debug";
import { localStorageUtils } from "@/lib/utils/localStorageUtils";
import { randomName } from "@/lib/utils/random";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { WorkspaceOptions } from "./WorkspacesManager";
import type { MAppEnv } from "@/framework/workspacing/MAppEnv";

const debug = debugFactory("couch-pouch-sync:WorkspacesManagerState");

export type WorkspaceEntry = {
  name: string;
  localName: string | null;
  remoteAccessKey: string | null;
  remoteDatabaseUrl: string | null;
};

/**
 * Manages the list of workspaces that are stored in localStorage.
 */
export class WorkspacesManagerState {
  entries: WorkspaceEntry[] = [];

  currentIndex = 0;

  constructor(
    public storage: Storage,
    public baseDbName: string,
    private mAppEnv?: MAppEnv
  ) {
    this.load();
    makeAutoObservable(this, {
      entries: observable,
      currentIndex: observable,
      currentEntry: computed,
      updateCurrentEntry: action,
      remoteAccessKey: computed,
      isSyncEnabled: computed,
      remoteDatabaseUrl: computed,
      localName: computed,
      switchToNewWorkspace: action,
      switchToIndex: action,
      deleteCurrentWorkspace: action,
    });
  }

  get currentEntry(): WorkspaceEntry {
    if (this.currentIndex >= this.entries.length) {
      throw new Error("Invalid current index");
    }

    return this.entries[this.currentIndex];
  }

  updateCurrentEntry(options: Partial<WorkspaceEntry>) {
    this.entries[this.currentIndex] = {
      ...this.entries[this.currentIndex],
      ...options,
    };
    this.save();
  }

  load() {
    const workspacesData = loadWorkspacesData(appConfig);
    debug("from localStorage:", workspacesData);

    this.entries = workspacesData.entries;
    this.currentIndex = workspacesData.currentIndex;
    this.save();
  }

  save() {
    const payload = {
      entries: this.entries,
      currentIndex: this.currentIndex,
    };
    localStorageUtils.setJSON("workspaces", payload);
    this.syncStateToMAppEnv();
  }

  get remoteAccessKey(): string | null {
    return this.entries[this.currentIndex].remoteAccessKey;
  }

  get isSyncEnabled(): boolean {
    return !!this.entries[this.currentIndex].remoteAccessKey;
  }

  get remoteDatabaseUrl(): string | null {
    return this.entries[this.currentIndex].remoteDatabaseUrl;
  }

  get localName(): string | null {
    return this.entries[this.currentIndex].localName;
  }

  switchToNewWorkspace(
    options: {
      remoteAccessKey: string | null;
      remoteDatabaseUrl: string | null;
    } & WorkspaceOptions
  ) {
    this.entries.push({
      name: options.name ?? `Workspace ${this.entries.length + 1}`,
      ...options,
      localName: `${this.baseDbName}:${randomName(10)}`,
    });
    this.currentIndex = this.entries.length - 1;
    this.save();
  }

  switchToNewBlankWorkspace(workspaceOptions: WorkspaceOptions = {}) {
    this.switchToNewWorkspace({
      remoteAccessKey: null,
      remoteDatabaseUrl: null,
      ...workspaceOptions,
    });
  }

  switchToIndex(index: number) {
    this.currentIndex = index;
    this.save();
  }

  deleteCurrentWorkspace() {
    this.entries.splice(this.currentIndex, 1);
    this.currentIndex = 0;
    if (this.entries.length === 0) {
      this.switchToNewBlankWorkspace();
    }
  }

  getOrGenerateAccessKey(): string {
    let accessKey = this.remoteAccessKey;
    if (!accessKey) {
      accessKey = randomName(40);
      this.updateCurrentEntry({ remoteAccessKey: accessKey });
    }
    if (!accessKey) {
      throw new Error("Could not find or generate accessKey via localStorage");
    }
    return accessKey;
  }

  private syncStateToMAppEnv() {
    runInAction(() => {
      if (this.mAppEnv) {
        this.mAppEnv.workspaceEntries = this.entries;
        this.mAppEnv.workspaceIndex = this.currentIndex;
      }
    });
  }
}
