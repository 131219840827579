import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";

export function initClient() {
  const originalOnError = window.onerror;
  window.onerror = function (message, file, line, col, error) {
    recordEvent("error", { message, file, line, col, error });
    if (originalOnError) {
      return originalOnError(message, file, line, col, error);
    }
    return false;
  };
  window.addEventListener("error", function (e) {
    recordEvent("error", { message: e.message, error: e.error });
    return false;
  });
  window.addEventListener("unhandledrejection", function (e) {
    recordEvent("unhandledrejection", {
      message: e.reason.message,
      error: e.reason.error,
    });
  });
}
