import { StarterCx } from "@/lib/nemmp/starter";
import z from "zod";
import { Workspace } from "./main/Workspace";

export type CxProps = {
  cx: StarterCx<Workspace>;
};

// returned by api-client getFeedByUrl // compatible with FeedClientZ
export type FeedResult = FeedAttributes & {
  feedId: string; // originates from couchdb _id
  etag?: string | null;
  lastCheckedAt?: string | null;
  items: FeedItemAttributes[];
};

export type FeedItemAttributes = {
  // Track ID
  trackId: string;

  // Properties from RSS
  title: string;
  description?: string | null;
  linkUrl?: string | null;
  coverUrl?: string | null;
  publishedAt: string;
  audioUrl: string;
  guid: string;

  duration?: number;
};

export interface FeedAttributes {
  url: string;

  isPublic?: boolean;
  owner?: string;

  title: string;
  description?: string | null;
  coverUrl?: string | null;
  language?: string | null;
  linkUrl?: string | null;

  // Items
  items: FeedItemAttributes[];
}

export const PinZ = z.object({
  id: z.string(),
  timestamp: z.number(),
  text: z.string(),
  translation: z.string().nullish(),
  isPublic: z.boolean().nullish(),
  lang: z.string().nullish(),
  translationLang: z.string().nullish(),
  transcriptContext: z.tuple([z.string(), z.string(), z.string()]).nullish(),
});

export type Pin = z.infer<typeof PinZ>;

export type TTranscriptResult = {
  transcript: TTranscript;
};

export type TTranscript = {
  chapters: TChapter[];
};
export type TChapter = {
  start: number;
  end: number;
  paragraphs: TParagraph[];
};
export type TParagraph = {
  start: number;
  end: number;
  sentences: TSentence[];
};
export type TSentence = {
  start: number;
  end: number;
  words: TWord[];
};
export type TWord = {
  start: number;
  end: number;
  text: string;
  bare_text?: string | null;
  confidence?: number | null;
  speaker?: number | null;
  speaker_confidence?: number | null;
};
