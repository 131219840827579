import { runInAction } from "mobx";
import { getEpisodeByRef } from "../episode/episode-referencing";
import { Track } from "@/modules/track/Track";
import { QueueItem, Queue, QueueDoc } from "./Queue";
import debug from "debug";
import {
  moveInArrayByIndex,
  withElementAtIndex,
  withoutElementAtIndex,
} from "@/lib/utils/array";
import { isMissing, isPresent } from "@/lib/prelude";

const log = debug("queue:QueueDualArrayManipulator");

// Manipulates the queue's dual array (items and tracks) in a consistent way.
//
export class QueueDualArrayManipulator {
  constructor(public queue: Queue) {}

  addToEnd(track: Track) {
    this._with(({ doc, tracks }) => {
      doc.items.push(track.reference);

      tracks.push(track);
    });
  }

  addAtIndex(track: Track, index: number) {
    if (isMissing(track)) {
      throw new Error(`${typeof track} was passed to addAtIndex`);
    }
    this._with(({ doc, queue, tracks }) => {
      log("Adding track to queue:", JSON.stringify(track.reference, null, 2));
      // 1) Add to the stored document
      doc.items = withElementAtIndex(doc.items, index, track.reference);

      // 2) Add to the observable array
      queue.tracks = withElementAtIndex(tracks, index, track);
      queue.checkIntegrity();
    });
  }

  removeAtIndex(index: number) {
    this._with(({ doc, queue, tracks }) => {
      // 1) Remove from the stored document
      doc.items = withoutElementAtIndex(doc.items, index);

      // 2) Remove from the observable array
      queue.tracks = withoutElementAtIndex(tracks, index);
      queue.checkIntegrity();
    });
  }

  moveByIndex(subjectIndex: number, targetIndex: number) {
    this._with(({ queue, doc, tracks }) => {
      // 1) Move in the stored document

      doc.items = moveInArrayByIndex(doc.items, subjectIndex, targetIndex);

      // 2) Move in the observable array
      queue.tracks = moveInArrayByIndex(tracks, subjectIndex, targetIndex);
      queue.checkIntegrity();
    });
  }

  contains(track: Track) {
    return this.indexOf(track) !== null;
  }

  indexOf(track: Track): null | number {
    const index = this.queue.tracks.findIndex((t) => t?.key === track.key);
    // log(
    //   "indexOf",
    //   track.key,
    //   this.queue.tracks.map((t) => t?.key),
    //   "=>",
    //   index
    // );
    return index === -1 ? null : index;
  }

  // Replicate the raw doc items into the observable array of tracks without destroying references
  async updateFromDoc(doc: QueueDoc) {
    const { queue } = this;
    const { tracks: tracks } = queue;

    // Lookup for existing tracks, so that they can be reused
    const tracksByKey = tracks.reduce((map, track) => {
      if (!track) {
        console.error(
          "Blank item found in queue.tracks while executing updateFromDoc"
        );
      } else {
        map[track.key] = track;
      }
      return map;
    }, {} as Record<string, Track>);

    // Get/create instances for all tracks; wait for all
    const updatedEpisodes: Track[] = (
      await Promise.all(
        doc.items.map(
          (ref: QueueItem) =>
            // Reuse existing track instance if it exists
            tracksByKey[ref.trackId] || getEpisodeByRef(queue.workspace, ref)
        )
      )
    ).filter((e) => {
      if (isPresent(e)) {
        return true;
      } else {
        console.error("Blank item found in updatedEpisodes");
        return false;
      }
    });

    // Compare keys, and only update if they are different
    const updatedKeys = updatedEpisodes.map((e) => e.key).join(",");
    const existingKeys = tracks.map((e) => e.key).join(",");
    if (updatedKeys !== existingKeys) {
      log("Changes in queue");
      runInAction(() => {
        queue.entity.doc.items = updatedEpisodes.map((e) => e.reference);
        queue.tracks = updatedEpisodes;
        queue.checkIntegrity();
      });
    } else {
      log("No changes in queue");
    }
  }

  private _with<R>(
    callback: (p: {
      queue: Queue;
      entity: any;
      doc: { items: any[] };
      tracks: Track[];
    }) => R
  ): R {
    const { queue } = this;
    const { entity, tracks: tracks } = queue;
    const { doc } = entity;

    return runInAction(() => {
      return callback({ queue, entity, doc, tracks });
    });
  }
}
