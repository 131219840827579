import Box from "@mui/material/Box";

export const CoverImageBox = ({
  coverUrlFn,
  size = 80,
  lazy = true,
}: {
  coverUrlFn?: (size: number) => string;
  size?: number;
  lazy?: boolean;
}) => {
  const srcSet = coverUrlFn
    ? [
        `${coverUrlFn(size * 1)}`,
        `${coverUrlFn(size * 1.5)} 1.5x`,
        `${coverUrlFn(size * 2)} 2x`,
      ].join(", ")
    : "";
  return (
    <Box
      className="image-placeholder"
      sx={{
        display: "inline-block",
        height: `${size}px`,
        width: `${size}px`,
        overflow: "hidden",
      }}
    >
      {coverUrlFn && (
        <img
          src={coverUrlFn ? coverUrlFn(size) : "/assets/grey-box.png"}
          srcSet={srcSet}
          width={size}
          height={size}
          alt={""}
          loading={lazy ? "lazy" : "eager"}
        />
      )}
    </Box>
  );
};
