import Box from "@mui/material/Box";
import { ButtonBaseProps } from "@mui/material/ButtonBase";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import { CoverImageBox } from "./CoverImageBox";
import { TrackInfoTextBox } from "./TrackInfoTextBox";
import { ProgressBox } from "./ProgressBox";
import { styled, useTheme } from "@mui/material/styles";

const StyledListItem = styled(ListItem)({
  "& .MuiListItemButton-root": {
    // leave space for 3 large icon-buttons
    paddingRight: "0px",
  },
});

type TrackListItemButtonLayoutProps = {
  onClick: () => void;
  isPlaying: boolean;
  isComplete: boolean;
  coverUrlFn?: (size: number) => string;
  title: string | React.ReactNode;
  programmeTitle?: string;
  publishedAt?: string;
  listeningPositionPercent: number | null;
  sx: ButtonBaseProps["sx"];
  secondaryAction?: ListItemProps["secondaryAction"];
  debugInfo?: string;
  lazy?: boolean;
};
export const TrackListItemButtonLayout = ({
  onClick,
  sx,
  isPlaying,
  isComplete,
  coverUrlFn,
  title,
  programmeTitle,
  listeningPositionPercent,
  publishedAt,
  secondaryAction,
  debugInfo,
  lazy,
}: TrackListItemButtonLayoutProps) => {
  const theme = useTheme();
  const light = theme.palette.mode === "light";
  const itemSx = {
    m: 0,
    p: 0,
    background: isPlaying ? (light ? "white" : "#333") : "transparent",
    opacity: isComplete ? 0.5 : 1,
    ...(sx ?? {}),
  };
  const buttonSx = {
    m: 0,
    p: 1,
  };

  return (
    <StyledListItem
      sx={itemSx}
      style={{ position: "relative" }}
      secondaryAction={secondaryAction}
    >
      <ListItemButton
        onClick={onClick}
        sx={buttonSx}
        className="list-item-button"
      >
        <Box
          sx={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "88px 1fr 148px",
            // background: "rgba(0, 255, 0, 0.2)",
          }}
        >
          <Box>
            <CoverImageBox coverUrlFn={coverUrlFn} lazy={lazy} />
            <ProgressBox listeningPositionPercent={listeningPositionPercent} />
          </Box>

          <TrackInfoTextBox
            title={title}
            programmeTitle={programmeTitle}
            publishedAt={publishedAt}
            debugInfo={debugInfo}
          />

          <Box
            className="padding"
            // sx={{ background: "rgba(255, 0, 190, 0.2)" }}
          />
        </Box>
      </ListItemButton>
    </StyledListItem>
  );
};
