import { TranscriptWChaPaSeIdZ } from "@/modules/transcript/formats/types";
import { z } from "zod";

export const TranscriptZ = z.object({
  trackId: z.string(),
  transcriptionJobId: z.string().nullish(),
  data: TranscriptWChaPaSeIdZ,
  serviceCode: z.string().nullish(),
  processorVersion: z.number().nullish(),
});

export type Transcript = z.infer<typeof TranscriptZ>;

export const TranscriptDocZ = TranscriptZ.extend({
  _id: z.string(),
  _rev: z.string(),
  type: z.literal("transcript"),
});

export type TranscriptDoc = z.infer<typeof TranscriptDocZ>;
