import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import BadgeIcon from "@mui/icons-material/Badge";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HelpIcon from "@mui/icons-material/Help";
import MailIcon from "@mui/icons-material/Mail";
import PolicyIcon from "@mui/icons-material/Policy";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useMemo } from "react";
import { Logo } from "./Logo";
import { StarterCx } from "./contexts";

type AppDrawerProps<TWorkspace> = {
  cx: StarterCx<TWorkspace>;
  open: boolean;
  onClose: () => void;
};

type MenuItemDefinition = {
  label: string;
  icon: React.ReactNode;
  onClick: (opts: { closeDrawer: () => void }) => void;
};

const buildMenuItems = <TWorkspace,>(cx: StarterCx<TWorkspace>) => {
  const { router, wEnv, starterConfig } = cx;
  const menuItems: Array<MenuItemDefinition> = [];

  if (starterConfig.buildMenuItems)
    menuItems.push(...starterConfig.buildMenuItems(wEnv));

  if (starterConfig.workspaces) {
    menuItems.push({
      label: "Workspaces",
      // hidden: process.env['NODE_ENV'] !== 'development' && !workspace.syncFeatures.isEnabled,
      icon: <WorkspacesIcon />,
      onClick: () => {
        recordEvent("click-app-drawer-workspaces");
        router.openDialog("workspaces");
      },
    });
  }

  if (starterConfig.help) {
    menuItems.push({
      label: "Help",
      icon: <HelpIcon />,
      onClick: () => {
        recordEvent("click-app-drawer-help");
        router.openDialog("help");
      },
    });
  }

  if (starterConfig.feedback) {
    menuItems.push({
      label: "Feedback",
      icon: <FactCheckIcon />,
      onClick: () => {
        recordEvent("click-app-drawer-feedback");
        router.openDialog("feedback");
      },
    });
  }

  menuItems.push({
    label: "Contact",
    icon: <MailIcon />,
    onClick: () => {
      recordEvent("click-app-drawer-contact");
      router.openDialog("contact");
    },
  });

  menuItems.push({
    label: "About",
    icon: <BadgeIcon />,
    onClick: () => {
      recordEvent("click-app-drawer-about");
      router.openDialog("about");
    },
  });

  if (starterConfig.privacyPolicy) {
    menuItems.push({
      label: "Privacy Policy",
      icon: <PolicyIcon />,
      onClick: () => {
        recordEvent("click-app-drawer-privacy");
        router.openDialog("privacy");
      },
    });
  }
  return menuItems;
};

export function AppDrawer<TWorkspace>({
  open,
  onClose,
  cx,
}: AppDrawerProps<TWorkspace>) {
  const { router, starterConfig } = cx;

  const menuItems = useMemo(() => buildMenuItems(cx), [cx]);

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      {/* <Divider /> */}
      <List>
        <ListItem sx={{ mb: 1 }} onClick={() => router.openDialog("about")}>
          <Logo />
        </ListItem>
        {menuItems.map(({ label, icon, onClick }, index) => (
          <ListItem key={label} disablePadding>
            <ListItemButton onClick={() => onClick({ closeDrawer: onClose })}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={() => {
          recordEvent("app-drawer-closed");
          onClose();
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}
