import { Iso2LanguageCode } from "@/lib/language/codes/iso2ToName";
import { isMissing } from "@/lib/prelude";
import { iso2LanguageCodes } from "./../../lib/language/codes/iso2ToName";

export function getLanguageAndRegion(lang: string): [Iso2LanguageCode, string] {
  let [language, region] = lang.split("-");
  language = language.toLowerCase();
  if (!iso2LanguageCodes.includes(language as Iso2LanguageCode)) {
    throw new Error(`Invalid language code: ${lang}`);
  }
  return [language as Iso2LanguageCode, region?.toUpperCase() ?? ""];
}

export function getLanguageAndRegionOptional(
  lang: string | null | undefined
): [Iso2LanguageCode | null, string] {
  if (isMissing(lang)) return [null, ""];
  let [language, region] = lang.split("-");
  language = language.toLowerCase();
  if (!iso2LanguageCodes.includes(language as Iso2LanguageCode)) {
    throw new Error(`Invalid language code: ${lang}`);
  }
  return [language as Iso2LanguageCode, region?.toUpperCase() ?? ""];
}

export function getLanguageAlpha2(lang: string): string {
  return getLanguageAndRegion(lang)[0];
}
