import debug from "debug";
import { makeAutoObservable, makeObservable, observable } from "mobx";
import { Workspace } from "@/modules/main/Workspace";
import { SuggestionState } from "./SuggestionState";
import { ObservableLocalStorageJson } from "@/lib/utils/local-storage";

// - if no activity for 15 seconds, show a tooltip to press "Play"
// - once playing for for 10 seconds show a tooltip: "Click on the word to look it up"
// - once playing for 20 more seconds show a tooltip: "More features coming soon!"

// Events:
// - page loaded => start timer for next suggestion
// - play button pressed => start timer
// - paused => stop timer
// - word looked up and dialog closed => start timer

const log = debug("onboarding");

type State = {
  played?: boolean;
  lookupWord?: boolean;
  earlyAccessSignup?: boolean;
};

export class OnboardingState {
  dictionaryButtonTooltipState = new SuggestionState();

  playSuggestion = new SuggestionState();

  earlyAccessTooltipState = new SuggestionState();

  private timer: ReturnType<typeof setTimeout> | null = null;
  private state = new ObservableLocalStorageJson<State>("demoOnboardingState");
  private nextStep: keyof State | null = null;

  constructor(private workspace: Workspace) {
    makeObservable(this, {
      dictionaryButtonTooltipState: observable,
      playSuggestion: observable,
      earlyAccessTooltipState: observable,
    });

    workspace.playerState.onNextPlay(() => {
      this.abortTimer();
      this.state.patch((v) => ({ ...v, played: true }));
      this.performNextStep();
    });

    // Don't show the word lookup suggestion while not playing
    workspace.playerState.addEventListener("play", () => {
      if (this.nextStep === "lookupWord") {
        this.abortTimer();
        this.performNextStep();
      }
    });
    workspace.playerState.addEventListener("pause", () => {
      if (this.nextStep === "lookupWord") {
        this.abortTimer();
      }
    });

    this.performNextStep();
  }

  performNextStep() {
    // if (!this.state.value?.played) {
    //   log("Scheduling play suggestion");
    //   this.whenNoActivityForSeconds(5, () => {
    //     log("Suggesting play");
    //     this.playSuggestion.flash();
    //   });
    //   return;
    // }

    if (!this.state.value?.lookupWord) {
      this.nextStep = "lookupWord";
      if (!this.workspace.playerState.playing) {
        return;
      }
      log("Scheduling lookup word suggestion");
      this.whenNoActivityForSeconds(5, () => {
        log("Suggesting lookup word");
        this.dictionaryButtonTooltipState.flash();
      });
      return;
    }

    if (!this.state.value?.earlyAccessSignup) {
      this.nextStep = "earlyAccessSignup";
      log("Scheduling early access signup suggestion");
      this.whenNoActivityForSeconds(5, () => {
        log("Suggesting early access signup");
        this.earlyAccessTooltipState.flash();
      });
      return;
    }

    this.nextStep = null;
  }

  wordLookedUp() {
    this.state.patch((v) => ({ ...v, lookupWord: true }));
    this.performNextStep();
  }

  earlyAccessSignupOpened() {
    this.state.patch((v) => ({ ...v, earlyAccessSignup: true }));
    this.performNextStep();
  }

  private abortTimer() {
    if (this.timer) {
      log("Aborting timer");
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private whenNoActivityForSeconds(seconds: number, callback: () => void) {
    this.abortTimer();
    log("Setting timer to " + seconds + " seconds");
    this.timer = setTimeout(() => {
      callback();
    }, seconds * 1000);
  }
}
