import Typography, { TypographyProps } from "@mui/material/Typography";

type TProps = TypographyProps & {
  children: React.ReactNode;
  component?: string;
};

export function ParagraphTitle(props: TProps) {
  return (
    <Typography
      component="h3"
      variant="h6"
      sx={{ mt: props.mt ?? 4, fontSize: 16 }}
      {...props}
    />
  );
}
