import { Collection } from "@/lib/mobx-pouch/Collection";
import { Database } from "@/lib/mobx-pouch/Database";
import { Entity } from "@/lib/mobx-pouch/Entity";
import { Workspace } from "@/modules/main/Workspace";
import {
  ListenerEpisode,
  ListenerEpisodeAttributes,
  ListenerEpisodeDoc,
} from "./ListenerEpisode";

export type ListenerEpisodeCollection = Collection<
  ListenerEpisodeAttributes,
  ListenerEpisode
>;

export function buildListenerEpisodeCollection(workspace: Workspace) {
  return workspace.database.collection<
    ListenerEpisodeAttributes,
    ListenerEpisode
  >(
    "listenerEpisode",
    (
      doc: ListenerEpisodeDoc,
      database: Database,
      collection: ListenerEpisodeCollection
    ) => {
      return new ListenerEpisode(
        workspace,
        new Entity(doc, database, collection)
      );
    }
  );
}
