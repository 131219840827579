import { normalizePouchDBErrors } from "@/lib/pouch";
// Entity
//
// Generic observable object, member of Collection.
//
// - Collection will update the observable `doc` on changes
// - methods for the higher-level entity
//    - save() - save whatever is in `doc` to the databse
//    - put() - replace attibutes with params and save
//    - delete()
//    - putAttachment()
//    - getAttachment()
//

import { forceUpdatePouchDoc } from "@/lib/pouch";
import { observable, makeAutoObservable, autorun, runInAction } from "mobx";
import { Collection } from "./Collection";
import { Database } from "./Database";

type TCouchMeta = { _id: string; _rev: string };
type TMeta = TCouchMeta & { type: string };

// FIXME: rename to differentiate from the higher level class (TEntity)
// - Entity .docAdapter -> DocAdapter .doc, .db
// - Entity .docProxy -> DocProxy .doc, .db
// - Entity .storage -> DocAdapter
export class Entity<TAttributes, E extends { entity: Entity<TAttributes, E> }> {
  database: Database;
  doc: TAttributes & TMeta;
  collection: Collection<TAttributes, E>;

  private savingPromise: Promise<void> | null = null;

  constructor(
    doc: TAttributes & TMeta,
    database: Database,
    collection: Collection<TAttributes, E>
  ) {
    this.database = database;
    this.doc = doc;
    this.collection = collection;

    makeAutoObservable(this, {
      doc: observable,
    });
  }

  get db() {
    return this.database.db;
  }

  async delete() {
    await this.db.remove(this.doc);
  }

  async save(): Promise<void> {
    // Prevent multiple saves at the same time:
    // chain new saves after the previous one
    // FIXME: handle failures
    // if (this.savingPromise) {
    //   return (this.savingPromise = this.savingPromise.then(() => this.save()));
    // } else {
    return (this.savingPromise = this._save());
    // }
  }

  private async _save() {
    const response = await forceUpdatePouchDoc(this.db, this.doc);
    runInAction(() => {
      this.doc._rev = response.rev;
    });
  }

  async putAttachment(
    attachmentId: string,
    blob: PouchDB.Core.AttachmentData,
    type: string
  ) {
    const response = await normalizePouchDBErrors(
      `while adding attachment in Entity (${attachmentId} in doc ${this.doc._id}})`,
      () =>
        this.db.putAttachment(
          this.doc._id,
          attachmentId,
          this.doc._rev,
          blob,
          type
        )
    );

    runInAction(() => {
      this.doc._rev = response.rev;
    });
  }

  async getAttachment(attachmentId: string) {
    const response = await this.db.getAttachment(this.doc._id, attachmentId);
    return response;
  }

  async put(attributes: TAttributes) {
    const { _id, _rev, type } = this.doc;
    runInAction(() => {
      // Make sure _id, _rev, type cannot be overridden by attributes
      this.doc = { ...attributes, _id, _rev, type };
    });
    return await this.save();
  }
}
