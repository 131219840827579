import {
  observable,
  action,
  computed,
  makeAutoObservable,
  runInAction,
} from "mobx";

export class RefreshState {
  isRefreshing = false;
  totalCount = 0;
  completedCount = 0;
  failedCount = 0;

  constructor() {
    makeAutoObservable(this, {
      isRefreshing: observable,
      totalCount: observable,
      completedCount: observable,
      failedCount: observable,
      start: action,
      clear: action,
      addSuccess: action,
      addFailure: action,
      isComplete: computed,
      text: computed,
    });
  }

  start(totalCount: number) {
    this.isRefreshing = true;
    this.totalCount = totalCount;
    this.completedCount = 0;
    this.failedCount = 0;
  }

  clear() {
    this.isRefreshing = false;
    this.totalCount = 0;
    this.completedCount = 0;
    this.failedCount = 0;
  }

  addSuccess() {
    this.completedCount += 1;
    this.checkIfComplete();
  }

  addFailure() {
    this.failedCount += 1;
    this.checkIfComplete();
  }

  get isComplete(): boolean {
    return this.completedCount + this.failedCount === this.totalCount;
  }

  get text(): string {
    if (this.isComplete) {
      return `Refreshed ${this.completedCount} subscriptions${
        this.failedCount > 0 ? `, ${this.failedCount} failed` : ""
      }.`;
    } else {
      return `Refreshing ${this.totalCount} subscriptions...${
        this.failedCount > 0 ? ` (${this.failedCount} failed)` : ""
      }`;
    }
  }

  checkIfComplete() {
    if (this.isComplete) {
      runInAction(() => {
        this.isRefreshing = false;
      });
    }
  }
}
