import { FeedInfoZ, FeedItemZ } from "@/modules/data/feeds/types";
import { z } from "zod";

// Track info returned to the frontend
// - for displaying it the player and playing the audio
// - for identifying the track in order to perform additional actions when needed
//
// (Ape = API Entity)

const TrackPlayInfoApeBaseZ = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullish(),
  linkUrl: z.string().nullish(),
  coverUrl: z.string().nullish(),
  publishedAt: z.string().nullish(),
  duration: z.number().nullish(),
  language: z.string().nullish(),
  // audioUrl: The front-end derives audio URL from the trackId
});
export type TrackPlayInfoApeBase = z.infer<typeof TrackPlayInfoApeBaseZ>;

export const PodTrackPlayInfoApeZ = TrackPlayInfoApeBaseZ.extend({
  feedInfo: FeedInfoZ,
  feedItem: FeedItemZ,
});
export type PodTrackPlayInfoApe = z.infer<typeof PodTrackPlayInfoApeZ>;

export const RecTrackPlayInfoApeZ = TrackPlayInfoApeBaseZ.extend({
  recordingId: z.string(),
});
export type RecTrackPlayInfoApe = z.infer<typeof RecTrackPlayInfoApeZ>;

export const TrackPlayInfoApeZ = z.union([
  PodTrackPlayInfoApeZ,
  RecTrackPlayInfoApeZ,
  TrackPlayInfoApeBaseZ,
]);
export type TrackPlayInfoApe = z.infer<typeof TrackPlayInfoApeZ>;
