import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import { PlayingTrackInfoBar } from "../playing-track/PlayingTrackInfoBar";
import type { CxProps } from "../types";
import { zIndex } from "../app/zIndex";

type BottomPanelProps = CxProps;

export const BottomPanel = observer(({ cx }: BottomPanelProps) => {
  return (
    <Box
      className="bottom-panel"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: zIndex.fixedScreenPanel,
      }}
    >
      <PlayingTrackInfoBar cx={cx} />

      {/* We now only show the controls on the currently playing track's screen -> TrackScreen */}
      {/* <PlayerControlBox cx={cx} /> */}
    </Box>
  );
});
