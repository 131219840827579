import { observer } from "mobx-react";
import { LatestEpisodeCollection } from "../episode/LatestEpisodeCollection";
import { TrackListItemButton } from "../episode/TrackListItemButton";
import { TrackListLook } from "../look/TrackListLook";
import { CxProps } from "../types";
import { RefreshStateDisplay } from "./RefreshStateDisplay";
import { Track } from "../track/Track";

type LatestEpisodesListProps = CxProps & {
  episodeCollection: LatestEpisodeCollection;
  limit?: number;
  expandButton?: React.ReactNode;
};

export const LatestEpisodesList = observer((props: LatestEpisodesListProps) => {
  const { cx, episodeCollection, limit, expandButton } = props;
  const episodesToShow = limit
    ? episodeCollection.all.slice(0, limit)
    : episodeCollection.all;

  return (
    <TrackListLook expandButton={expandButton}>
      <RefreshStateDisplay cx={cx} />
      {episodesToShow.map((track: Track, index) => {
        return (
          <TrackListItemButton
            cx={cx}
            key={track.key}
            sx={{ my: 2 }}
            track={track}
            lazy={index > 5}
          />
        );
      })}
    </TrackListLook>
  );
});
