import { useState } from "react";
import { ExpandButtonLook } from "@/modules/look/ExpandButtonLook";
import { Queue } from "@/modules/queue/Queue";
import { QueueList } from "@/modules/queue/QueueList";
import { CxProps } from "@/modules/types";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";

export const ExpandableQueueList = ({
  queue,
  collapsedLimit = 5,
  cx,
}: {
  queue: Queue;
  collapsedLimit?: number;
} & CxProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    recordEvent("expand-queue-list");
    setExpanded(!expanded);
  };

  return (
    <>
      <QueueList
        cx={cx}
        queue={queue}
        limit={expanded ? undefined : collapsedLimit}
        expandButton={
          !expanded && (
            <ExpandButtonLook onClick={handleExpandClick}>
              Show all
            </ExpandButtonLook>
          )
        }
      />
    </>
  );
};
