import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/system/Box";
import React, { useEffect } from "react";
import { trackSimpleEvent } from "../tracking/trackEvent";
import { localStorageUtils } from "@/lib/utils/localStorageUtils";

export type ContactDialogConfig = {
  intro?: string;
};

export type ContactDialogProps = {
  open: boolean;
  onClose: () => void;
  topic?: string;
  easterEggs?: { [key: string]: () => void };
  config?: ContactDialogConfig;
};

export function ContactDialog({
  open,
  onClose,
  topic,
  easterEggs,
  config,
}: ContactDialogProps) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [done, setDone] = React.useState(false);
  const [doneMessage, setDoneMessage] = React.useState<string | null>(null);

  const defaultName = localStorageUtils.get("contact_name");
  const defaultEmail = localStorageUtils.get("contact_email");

  useEffect(() => {
    if (open) {
      trackSimpleEvent("contact-dialog-open");
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setLoading(false);
      setError(null);
      setDone(false);
    }, 500);
  };

  const handleSend = () => {
    trackSimpleEvent("contact-dialog-send");
    setLoading(true);
    setError(null);

    if (easterEggs) {
      const key = getInputValue("contact-message")?.trim();
      if (key && easterEggs[key]) {
        easterEggs[key]();
        setDoneMessage(`${key} done`);
        setLoading(false);
        setDone(true);
        return;
      }
    }

    console.log("sending message");

    localStorageUtils.set("contact_name", getInputValue("contact-name"));
    localStorageUtils.set("contact_email", getInputValue("contact-email"));

    fetch("/api/contact", {
      method: "post",
      body: JSON.stringify({
        action: `Contact${topic ? ` ${topic}` : ""}`,
        name: getInputValue("contact-name"),
        email: getInputValue("contact-email"),
        message: getInputValue("contact-message"),
      }),
    })
      .then((response) => {
        console.log("fetch resolved", response);
        trackSimpleEvent("contact-send-success");
        if (response.ok) {
          setLoading(false);
          setDone(true);
        } else {
          setLoading(false);
          setError(response.statusText);
        }
      })
      .catch((error) => {
        trackSimpleEvent("contact-send-fail");
        console.log("fetch rejected", error);
        setLoading(false);
        setError(error.message);
      });
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      fullScreen={fullScreen}
    >
      <DialogTitle id="alert-dialog-title">Contact</DialogTitle>
      <DialogContent>
        {done ? (
          <Alert
            severity="success"
            variant="filled"
            style={{ marginTop: "1em" }}
          >
            {doneMessage ?? "Your message has been sent. Thank you!"}
          </Alert>
        ) : (
          <Box sx={{ mt: 1 }}>
            {config && config.intro && (
              <Typography mb={2} variant="body2">
                {config.intro}
              </Typography>
            )}

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="contact-name"
                label="Your Name"
                helperText="Optional. Your name or nickname"
                autoFocus={!defaultName && !defaultEmail}
                variant="outlined"
                disabled={loading}
                defaultValue={defaultName}
              />
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="contact-email"
                label="Email Address"
                helperText="Optional. I will not use your email address for any other purpose than replying to your message."
                variant="outlined"
                disabled={loading}
                defaultValue={defaultEmail}
              />
            </FormControl>

            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                id="contact-message"
                label="Message"
                autoFocus={!!defaultName || !!defaultEmail}
                multiline
                rows={4}
                variant="outlined"
                disabled={loading}
                required={true}
              />
              <FormHelperText></FormHelperText>
            </FormControl>
          </Box>
        )}

        {error && (
          <Alert severity="error" variant="filled" style={{ marginTop: "1em" }}>
            Couldn't send your message. Please try again later.
            <br />
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress />}

        {done ? (
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        ) : (
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleSend}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Send
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

function getInputValue(id: string) {
  const element = document.querySelector("#" + id) as HTMLInputElement;
  if (!element) return null;
  return element.value;
}
