// Singleton
//
// A MobX object representing a single document in a database identified by `id`.
// Similar to `Collection`, but for a single document, and instead of a `type` it has an `id`.
//
// Purpose:
// - to provide an object that can be observed for loading state (via `loadable`) and changes

import { LoadableValue } from "@/lib/mobx";
import { findOrCreatePouchDoc } from "@/lib/pouch";
import { makeAutoObservable, observable, runInAction } from "mobx";
import { Database } from "./Database";
import { SingletonEntity } from "./SingletonEntity";
import { debugFactory } from "../utils/debug";
import { memo } from "../utils/decorators";

export type TCouchMeta = { _id: string; _rev: string };
export type TMeta = TCouchMeta;

export type TSingletonEntityConstructor<
  A,
  E extends { entity: SingletonEntity<A, E> }
> = (doc: A & TMeta, database: Database, singleton: Singleton<A, E>) => E;

export class Singleton<
  TAttributes,
  TEntity extends { entity: SingletonEntity<TAttributes, TEntity> }
> {
  database: Database;
  id: string;
  defaultAttributes: TAttributes;
  entityConstructor: TSingletonEntityConstructor<TAttributes, TEntity>;

  entity: TEntity | null = null;

  debug: (...args: any) => void;

  private loadPromise: Promise<TAttributes & TMeta> | undefined;

  static buildAndLoad<A, E extends { entity: SingletonEntity<A, any> }>(
    database: Database,
    id: string,
    defaultAttributes: A,
    entityConstructor: TSingletonEntityConstructor<A, E>
  ): LoadableValue<Singleton<A, E>> {
    return new LoadableValue(async () => {
      const singleton = new Singleton<A, E>(
        database,
        id,
        defaultAttributes,
        entityConstructor
      );
      return singleton;
    });
  }

  //---------------------------
  constructor(
    database: Database,
    id: string,
    defaultAttributes: TAttributes,
    entityConstructor: (
      doc: TAttributes & TMeta,
      database: Database,
      singleton: Singleton<TAttributes, TEntity>
    ) => TEntity
  ) {
    this.database = database;
    this.id = id;
    this.defaultAttributes = defaultAttributes;
    this.entityConstructor = entityConstructor;

    this.debug = debugFactory("Singleton");

    makeAutoObservable(this, {
      entity: observable,
    });

    this.load().then((doc) => {
      this.debug("processing load() results");
      runInAction(() => {
        this._addOrUpdate(doc);
      });
    });

    // ------ SUBSCRIBE TO CHANGES ------ //

    this.database.onChange((change) => {
      const doc = change.doc as any as TAttributes & TMeta;

      if (change.deleted) {
        // FIXME: handle delete
        throw new Error("Singleton: delete not implemented");
      } else if (doc._id === this.id) {
        runInAction(() => {
          this._addOrUpdate(doc);
        });
      }
    });
  }
  //---------------------------

  get db() {
    return this.database.db;
  }

  load() {
    this.debug("load()");

    if (this.loadPromise) {
      return this.loadPromise;
    }

    return (this.loadPromise = (async () => {
      const doc = await findOrCreatePouchDoc(
        this.db,
        this.id,
        this.defaultAttributes
      );
      console.log("load() doc:", doc);

      return doc as any as TAttributes & TMeta;
    })());
  }

  loadable = new LoadableValue(async () => {
    await this.load();
    if (!this.entity) {
      throw new Error("Singleton: entity not found");
    }
    return this.entity;
  });

  _addOrUpdate(doc: TAttributes & TMeta): TEntity {
    if (this.entity) {
      this.debug(`Updating ${doc._id} in the ${this.id} singleton`);
      const e = this.entity;
      runInAction(() => {
        Object.assign(e.entity.doc, doc);
      });
      return e;
    } else {
      this.debug(`Setting ${doc._id} to the ${this.id} singleton`);
      const entity = this._makeEntity(doc);
      runInAction(() => {
        this.entity = entity;
      });
      return entity;
    }
  }

  _makeEntity(doc: TAttributes & TMeta): TEntity {
    return this.entityConstructor(doc, this.database, this);
  }
}
