import { LoadableSkeleton } from "@/lib/mobx-mui/loadable-skeleton/loadable-skeleton";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { observer } from "mobx-react";
import { LatestEpisodeCollection } from "@/modules/episode/LatestEpisodeCollection";
import { ExpandableFeaturedEpisodeList } from "@/modules/featured/ExpandableFeaturedEpisodeList";
import { FeaturedEpisodeCollection } from "@/modules/featured/FeaturedEpisodeCollection";
import { ScreenSection } from "@/modules/look/ScreenSection";
import { Queue } from "@/modules/queue/Queue";
import type { CxProps } from "@/modules/types";
import { ExpandableLatestEpisodesList } from "./ExpandableLatestEpisodesList";
import { ExpandableQueueList } from "./ExpandableQueueList";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { useEffect } from "react";
import { AppNoticeSection } from "../app-meta/AppNoticeSection";

type DashboardScreenProps = CxProps;

export const DashboardScreen = observer(({ cx }: DashboardScreenProps) => {
  return (
    <Box sx={{ position: "absolute", inset: 0, overflow: "auto", pb: 8 }}>
      <AppNoticeSection cx={cx} />
      <QueueSection cx={cx} />
      <LatestEpisodesSection cx={cx} />
      {/* <FeaturedEpisodesSection cx={cx} /> */}
      <AddPodcastSection cx={cx} />
    </Box>
  );
});

const QueueSection = observer(({ cx }: CxProps) => {
  const { workspace } = cx.wEnv;

  useEffect(() => {
    if (workspace.queue.stateString === "succeeded") {
      recordEvent("loaded-queue", {
        t: Math.round(performance.now() / 1000),
      });
    }
  }, [workspace.queue.stateString]);

  return (
    <LoadableSkeleton
      loadable={workspace.queue}
      renderLoaded={(queue: Queue) =>
        queue.tracks.length > 0 ? (
          <ScreenSection title="Queue">
            <ExpandableQueueList queue={queue} cx={cx} />
          </ScreenSection>
        ) : null
      }
    />
  );
});

const LatestEpisodesSection = observer(({ cx }: CxProps) => {
  const { workspace } = cx.wEnv;

  useEffect(() => {
    if (workspace.latestEpisodeCollection.stateString === "succeeded") {
      recordEvent("loaded-latest-episodes", {
        t: Math.round(performance.now() / 1000),
      });
    }
  }, [workspace.latestEpisodeCollection.stateString]);

  return (
    <LoadableSkeleton
      loadable={workspace.latestEpisodeCollection}
      renderLoaded={(episodeCollection: LatestEpisodeCollection) =>
        episodeCollection.hasAnyItems ? (
          <ScreenSection title="Latest Episodes">
            <ExpandableLatestEpisodesList
              cx={cx}
              episodeCollection={episodeCollection}
            />
          </ScreenSection>
        ) : null
      }
    />
  );
});

const FeaturedEpisodesSection = observer(({ cx }: CxProps) => {
  const { workspace } = cx.wEnv;

  useEffect(() => {
    if (workspace.featuredEpisodeCollection.stateString === "succeeded") {
      recordEvent("loaded-featured-episodes", {
        t: Math.round(performance.now() / 1000),
      });
    }
  }, [workspace.featuredEpisodeCollection.stateString]);

  return (
    <LoadableSkeleton
      loadable={workspace.featuredEpisodeCollection}
      renderLoaded={(featuredEpisodeCollection: FeaturedEpisodeCollection) =>
        featuredEpisodeCollection.hasAnyItems ? (
          <ScreenSection title="Featured Episodes">
            <ExpandableFeaturedEpisodeList
              cx={cx}
              featuredEpisodeCollection={featuredEpisodeCollection}
            />
          </ScreenSection>
        ) : null
      }
    />
  );
});

const AddPodcastSection = ({ cx }: CxProps) => {
  const { workspace } = cx.wEnv;

  const onClick = async () => {
    recordEvent("click-add-podcast-on-dashboard");
    workspace.podcastSearchState.open();
  };
  return (
    <ScreenSection>
      <Box sx={{ textAlign: "center" }}>
        <Button
          onClick={onClick}
          startIcon={<AddIcon />}
          size="large"
          variant="contained"
        >
          Add podcast
        </Button>
      </Box>
    </ScreenSection>
  );
};
