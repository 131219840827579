// Wrapper around any screen that has audio playing, transcript and dictionary

import { AudioElement } from "@/lib/audio-player/AudioElement";
import { PlayerContext } from "@/lib/audio-player/PlayerContext";
import { WebAudioPlayerState } from "@/lib/audio-player/WebAudioPlayerState";
import type { CxProps } from "../types";
import { WorkspaceDialogs } from "./WorkspaceDialogs";
import { observer } from "mobx-react";

export const CommonWrapper = observer(
  ({ cx, children }: CxProps & { children: React.ReactNode }) => {
    const { workspace } = cx.wEnv;
    const { playerState } = workspace;

    return (
      <PlayerContext.Provider value={{ playerState }}>
        {children}

        <WorkspaceDialogs cx={cx} />

        {playerState.type === "web" && (
          <AudioElement
            hidden
            audioPlayerState={playerState as WebAudioPlayerState}
          />
        )}
      </PlayerContext.Provider>
    );
  }
);
