import { isMissing } from "@/lib/prelude/presence";
import { DateTime } from "luxon";

export function feedTimestampToSeconds(
  timestamp: string | undefined | null
): number | null {
  if (isMissing(timestamp)) return null;
  return DateTime.fromRFC2822(timestamp).toSeconds();
}

export function isoTimestampToSeconds(
  timestamp: string | undefined | null
): number | null {
  if (isMissing(timestamp)) return null;
  (window as any).d = DateTime;
  return DateTime.fromISO(timestamp).toSeconds();
}
