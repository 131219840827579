export const onIntersection = (
  el: HTMLElement,
  opts: { rootMargin?: string } = {},
  fn: (isIntersecting: boolean) => void
) => {
  const options = {
    // root: document.querySelector("#scrollArea"),
    rootMargin: opts.rootMargin, // e.g. '100px'
    // threshold: 1.0,
  };

  const callback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      fn(entry.isIntersecting);
    });
  };

  const observer = new IntersectionObserver(callback, options);

  observer.observe(el);
};
