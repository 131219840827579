import Box, { BoxProps } from "@mui/material/Box";

type ScreenProps = {
  children: React.ReactNode;
  id?: BoxProps["id"];
  className?: BoxProps["className"];
  noBottomPadding?: boolean;
};

export const Screen = ({
  id,
  className,
  children,
  noBottomPadding,
}: ScreenProps) => {
  return (
    <Box
      // className used by to AutoExpandingTrackListLook
      className={`screen ${className || ""}`}
      id={id}
      sx={{
        position: "absolute",
        inset: 0,
        overflow: "auto",

        // normally leave some space at the bottom for the bottom bar
        pb: noBottomPadding ? 0 : 8,
      }}
    >
      {children}
    </Box>
  );
};
