import { observer } from "mobx-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import type { CxProps } from "../types";
import { MainRouter } from "./MainRouter";
import { CommonWrapper } from "./CommonWrapper";

export const MainScreen = observer(({ cx }: CxProps) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <CommonWrapper cx={cx}>
        <MainRouter cx={cx} />
      </CommonWrapper>
    </DndProvider>
  );
});
