import { logTime } from "@/lib/utils/benchmarking";
import { flatMap } from "lodash";
import { autorun, computed, makeObservable, observable } from "mobx";
import { Subscription } from "../subscriptions/Subscription";
import { Track } from "../track/Track";
import { Workspace } from "./../main/Workspace";

export class LatestEpisodeCollection {
  all: Track[] = [];

  constructor(public workspace: Workspace) {
    console.log("LatestEpisodeCollection constructor");
    makeObservable(this, {
      all: observable,
      hasAnyItems: computed,
    });

    // Doing this in autorun because computed properties don't seem to be cached
    autorun(() => {
      this.all = logTime("LatestEpisodeCollection#all", () =>
        this.sortByLatest(
          flatMap(
            this.workspace.subscriptionCollection.allObs.map(
              (subscription: Subscription) => {
                return subscription.recentEpisodes;
              }
            )
          )
        )
      );
    });
  }

  get hasAnyItems(): boolean {
    return this.all.length > 0;
  }

  private sortByLatest(episodes: Track[]): Track[] {
    return logTime("LatestEpisodeCollection#sortByLatest", () =>
      episodes.sort((a, b) => {
        if (!a.publishedAt) return 1; // b should come first
        if (!b.publishedAt) return -1; // a should come first

        // Strings should be in ISO format, so we can compare them as strings
        if (a.publishedAt < b.publishedAt) return 1; // b is more recent, should come first
        if (a.publishedAt > b.publishedAt) return -1; // a is more recent, should come first
        return 0;
      })
    );
  }
}
