import { sha1 } from "@/lib/prelude/sha1";
import { internalUE } from "@/framework/errors/sugar";

export function feedIdFromUrl(feedUrl: string): string {
  return `feed:${sha1(feedUrl)}`;
}

export function feedIdFromTrackId(trackId: string): string | null {
  const match = trackId.match(/^track:(feed:\w+):(item:\w+)$/);
  if (!match) {
    console.warn("Invalid trackId", trackId);
    return null;
  }
  const [, feedId] = match;
  return feedId;
}

export type ParsedPodTrackId = {
  feedId: string;
  feedUrlSha1: string;
  itemId: string;
  itemGuidSha1: string;
};
export type ParsedRecTrackId = {
  recordingId: string;
};
export type ParsedSharedTrackId = {
  accessKey: string;
};

export type ParsedTrackId =
  | ParsedPodTrackId
  | ParsedRecTrackId
  | ParsedSharedTrackId;

export function parseTrackIdStrict(trackId: string): ParsedTrackId {
  const [type, parentType, ...parentIdentifiers] = trackId.split(":");

  if (type !== "track") {
    throw internalUE('invalid trackId - must start with "track:"', { trackId });
  }

  if (parentType === "feed") {
    const [feedUrlSha1, , itemGuidSha1] = parentIdentifiers;
    return {
      feedId: `feed:${feedUrlSha1}`,
      feedUrlSha1,
      itemId: `item:${itemGuidSha1}`,
      itemGuidSha1,
    };
  }

  if (parentType === "recording") {
    const [recordingId] = parentIdentifiers;
    return { recordingId };
  }

  if (parentType === "s") {
    const [accessKey] = parentIdentifiers;
    return { accessKey };
  }

  throw internalUE("invalid trackId - invalid type", { trackId });
}
export function parseTrackId(trackId: string): ParsedTrackId | null {
  const [type, parentType, ...parentIdentifiers] = trackId.split(":");

  if (type !== "track") {
    console.warn("Invalid trackId - must start with track:", trackId);
    return null;
  }

  if (parentType === "feed") {
    const [feedUrlSha1, , itemGuidSha1] = parentIdentifiers;
    return {
      feedId: `feed:${feedUrlSha1}`,
      feedUrlSha1,
      itemId: `item:${itemGuidSha1}`,
      itemGuidSha1,
    };
  }

  if (parentType === "recording") {
    const [recordingId] = parentIdentifiers;
    return { recordingId };
  }

  if (parentType === "s") {
    const [accessKey] = parentIdentifiers;
    return { accessKey };
  }

  console.warn("Invalid trackId - invalid type", trackId);
  return null;
}

export function generateTrackId(parsed: ParsedTrackId): string {
  if ("recordingId" in parsed) {
    return `track:recording:${parsed.recordingId}`;
  }

  if ("accessKey" in parsed) {
    return `track:s:${parsed.accessKey}`;
  }

  const { feedUrlSha1, itemGuidSha1 } = parsed;

  return `track:feed:${feedUrlSha1}:item:${itemGuidSha1}`;
}

export function generateTrackIdFromFeedIdAndItemGuid(
  feedId: string,
  itemGuid: string
): string {
  return `track:${feedId}:item:${sha1(itemGuid)}`;
}

export function subscriptionIdFromTrackId(trackId: string): string | null {
  const parsed = parseTrackId(trackId);
  if (parsed === null) return null;
  if (!("feedUrlSha1" in parsed)) return null;
  return `subscription:${parsed.feedUrlSha1}`;
}

// transcript:track:feed:70ee20ed036fb2e27b060c54630823756fbf36e7:item:f88d62afaa6f2e3672d457f277fff2f235e73e1b:whisper:xrjiha
// => track:feed:70ee20ed036fb2e27b060c54630823756fbf36e7:item:f88d62afaa6f2e3672d457f277fff2f235e73e1b
export function trackIdFromTranscriptId(transcriptId: string): string | null {
  const match = transcriptId.match(
    /^transcript:(track:(feed:(\w+)):(item:(\w+))):/
  );
  if (!match) {
    console.warn("Invalid transcriptId", transcriptId);
    return null;
  }
  console.log("match", match);
  const [, trackId] = match;
  return trackId;
}

export function trackIdFromRecordingId(recordingId: string): string {
  return `track:recording:${recordingId}`;
}

export function isRecordingTrackId(trackId: string): boolean {
  return trackId.startsWith("track:recording:");
}

export function recordingIdFromTrackId(trackId: string): string | null {
  const parsed = parseTrackId(trackId);
  if (parsed === null) return null;
  if (!("recordingId" in parsed)) return null;
  return parsed.recordingId;
}

export function isSharedTrackId(trackId: string): boolean {
  return trackId.startsWith("track:s:");
}

export function sharedTrackIdFromAccessKey(accessKey: string): string {
  return `track:s:${accessKey}`;
}
