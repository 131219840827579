import { PageTitle } from "@/lib/mui/PageTitle";
import SectionTitle from "@/lib/mui/SectionLabel";
import Typography from "@mui/material/Typography";
import { ContactButton } from "../contact/ContactButton";

export function ImprintContent() {
  return (
    <>
      <PageTitle>Imprint</PageTitle>

      <Typography component="div" variant="body2">
        <p>
          <SectionTitle component="h2">Business Name</SectionTitle>
          Levente-László Bagi Software-Entwickler
          <br />
        </p>

        <p>
          <SectionTitle component="h2">Business Address</SectionTitle>
          Stendaler Str. 77
          <br />
          12627 Berlin
          <br />
          Germany
        </p>

        <ContactButton variant="contained" />
      </Typography>
    </>
  );
}
