export function isSupported(): boolean {
  return (
    typeof window !== "undefined" && typeof window.localStorage !== "undefined"
  );
}
export function getLocal(key) {
  if (typeof window === "undefined") return;
  return window.localStorage.getItem(key);
}

export function setLocal(key, value) {
  if (typeof window === "undefined") return;
  window.localStorage.setItem(key, value);
}

export function getOrGenerateLocal(
  key: string,
  generate: () => string
): string | undefined {
  if (typeof window === "undefined") {
    console.error("window undefined");
    return;
  }

  const existingValue = window.localStorage.getItem(key);
  if (typeof existingValue !== "undefined" && existingValue) {
    console.log("Found:", existingValue);
    return existingValue;
  }

  const newValue = generate();
  console.log("generated", newValue);
  window.localStorage.setItem(key, newValue);

  return newValue;
}

export function getLocalInteger(key) {
  const value = getLocal(key);
  if (value) {
    return parseInt(value);
  }
  return null;
}

export function getLocalFloat(key) {
  const value = getLocal(key);
  if (value) {
    return parseFloat(value);
  }
  return null;
}

export function setLocalBoolean(key, value) {
  setLocal(key, value ? "yes" : "no");
}

export function getLocalBoolean(key) {
  const value = getLocal(key);
  if (value === "yes") return true;
  else if (value === "no") return false;
  return null;
}

export function setLocalJSON(key, value) {
  setLocal(key, JSON.stringify(value));
}

export function getLocalJSON(key) {
  const value = getLocal(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.log("Error while parsing JSON", e);
      return null;
    }
  }
}

export function getLocalCustom<T>(
  key: string,
  decoder: (value: string) => T | null
): T | null {
  const value = getLocal(key);
  if (!value) return null;
  return decoder(value);
}
