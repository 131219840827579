type Subscriber<T> = (data: T) => void;

export class PubSub<T> {
  private subscribers = new Set<Subscriber<T>>();

  subscribe(callback: Subscriber<T>) {
    this.subscribers.add(callback);
  }

  publish(data: T) {
    this.subscribers.forEach((callback) => callback(data));
  }
}
