export type Err = unknown;

export class MaybeLoaded<T> {
  isValue: boolean;
  value?: T;
  error?: Err;

  static Pending<T>() {
    return new MaybeLoaded<T>(false);
  }
  static Failure<T>(error: Err) {
    return new MaybeLoaded<T>(false, undefined, error);
  }
  static Value<T>(value: T) {
    return new MaybeLoaded<T>(true, value);
  }

  constructor(isValue: boolean, value?: T, error?: Err) {
    this.isValue = isValue;
    this.value = value;
    this.error = error;
  }

  get isFailure() {
    return !!this.error;
  }
  get isPending() {
    return !this.isValue && !this.isFailure;
  }

  get inspect(): string {
    if (this.isValue) return `loaded<${this.value}>`;
    if (this.isFailure) return `failure<${this.error}>`;
    return "pending";
  }

  map<U>(fn: (v: T) => U): MaybeLoaded<U> {
    if (this.isValue) {
      return new MaybeLoaded(true, fn(this.value as T));
    } else {
      return this as unknown as MaybeLoaded<U>;
    }
  }

  chain<U>(fn: (v: T) => MaybeLoaded<U>): MaybeLoaded<U> {
    if (this.isValue) {
      return fn(this.value as T);
    } else {
      return this as unknown as MaybeLoaded<U>;
    }
  }

  fold<Z>(
    valueFn: (v: T) => Z,
    pendingFn: () => Z,
    failureFn: (error: Err) => Z
  ): Z {
    if (this.isValue) {
      return valueFn(this.value as T);
    } else if (this.isFailure) {
      return failureFn(this.error as Err);
    } else {
      return pendingFn();
    }
  }

  static all<T>(list: MaybeLoaded<T>[]): MaybeLoaded<T[]> {
    let acc = MaybeLoaded.Value<T[]>([]);

    list.forEach((item: MaybeLoaded<T>) => {
      if (acc.isFailure) return;
      if (item.isFailure) acc = MaybeLoaded.Failure<T[]>(item.error as Err);
      if (item.isPending) acc = MaybeLoaded.Failure<T[]>(item.error as Err);
      if (item.isValue && acc.isValue)
        acc = MaybeLoaded.Value<T[]>([...(acc.value as T[]), item.value as T]);
    });

    return acc;
  }

  static sum(list: MaybeLoaded<number>[]): MaybeLoaded<number> {
    let acc = MaybeLoaded.Value<number>(0);

    list.forEach((item: MaybeLoaded<number>) => {
      if (acc.isFailure) return;
      if (item.isFailure) acc = MaybeLoaded.Failure<number>(item.error as Err);
      if (item.isPending) acc = MaybeLoaded.Failure<number>(item.error as Err);
      if (item.isValue && acc.isValue)
        acc = MaybeLoaded.Value<number>(<number>acc.value + <number>item.value);
    });

    return acc;
  }
}
