import TextField, { StandardTextFieldProps } from "@mui/material/TextField";
import { observer } from "mobx-react";

// Plain text field (with no label)

export interface ITextFieldValueState {
  value: string;
  change: (value: string) => void;
}

type TextFieldMobxProps = Omit<StandardTextFieldProps, "value" | "onChange"> & {
  state: ITextFieldValueState;
};

export const TextFieldMobx = observer(
  ({ state, ...props }: TextFieldMobxProps) => {
    return (
      <TextField
        value={state.value}
        onChange={(e) => state.change(e.target.value)}
        {...props}
      />
    );
  }
);
