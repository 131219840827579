import Typography, { TypographyProps } from "@mui/material/Typography";

export type ParagraphProps = Pick<TypographyProps, "sx"> & {
  children: React.ReactNode;
};

export const Paragraph = ({ children, sx }: ParagraphProps) => {
  return (
    <Typography component="p" sx={{ my: 2, ...sx }} variant="body2">
      {children}
    </Typography>
  );
};
