import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { LoadableValue } from "@/lib/mobx";
import { findOrCreatePouchDoc } from "@/lib/pouch";
import debug from "debug";
import { makeAutoObservable, observable, runInAction, toJS } from "mobx";
import { getEpisodeByRef } from "../episode/episode-referencing";
import { makeTrackCurrent } from "../queue/makeTrackCurrent";
import { Track } from "@/modules/track/Track";
import { Workspace } from "./../main/Workspace";
import { QueueDualArrayManipulator } from "./QueueDualArrayManipulator";
import { playTrack } from "./playTrack";
import { Singleton } from "@/lib/mobx-pouch/Singleton";
import { SingletonEntity } from "@/lib/mobx-pouch/SingletonEntity";
import { isMissing } from "@/lib/prelude";

const log = debug("queue:Queue");

export type QueueItem = {
  trackId: string;
  title: string;
  feedUrl?: string;
  feedTitle?: string;
  feedItemId?: string;
  feedItemGuid?: string;
  subscriptionId?: string;
};
export type QueueAttributes = {
  items: QueueItem[];
};

export type QueueDoc = QueueAttributes & { _id: string; _rev: string };
export type QueueSingleton = Singleton<QueueAttributes, Queue>;

export class Queue {
  tracks: Track[];

  private dualArray: QueueDualArrayManipulator;

  constructor(
    public workspace: Workspace,
    public entity: SingletonEntity<QueueAttributes, Queue>,
    initialTracks: Track[]
  ) {
    log(
      "initiliazing queue",
      JSON.stringify(entity.doc, null, 2),
      initialTracks
    );
    initialTracks.forEach((track) => {
      if (!track) {
        console.error("Blank item found in the initialTracks passed to Queue");
      }
    });
    this.tracks = initialTracks;
    this.checkIntegrity();
    makeAutoObservable(this, {
      tracks: observable,
    });

    this.dualArray = new QueueDualArrayManipulator(this);

    // Subscribe to changes
    this.workspace.database.onChange((change) => {
      const doc = change.doc as any as QueueDoc;

      if (change.deleted) {
        throw new Error("Queue: delete not implemented");
      } else if (doc._id === this.entity.doc._id) {
        runInAction(() => {
          Object.assign(this.entity.doc, doc);
        });

        this.dualArray.updateFromDoc(doc);
      }
    });
  }

  static getLoadable(workspace: Workspace): LoadableValue<Queue> {
    return new LoadableValue<Queue>(async (): Promise<Queue> => {
      const doc = await findOrCreatePouchDoc(workspace.database.db, "queue", {
        items: [],
      });
      const entity = new SingletonEntity(doc, workspace.database);

      const initialTracks: Track[] = (
        await Promise.all(
          (entity.doc.items ?? []).map((ref: QueueItem) => {
            console.log("Queue ref:", toJS(ref));
            return getEpisodeByRef(workspace, ref, ["Queue/initialTracks"]);
          })
        )
      ).filter((e) => e !== null) as Track[];

      // In case some episodes could not be found, remove them from the document
      // (initialTracks will be shorter due to  `getEpisodeByRef`  returning null and being filtered out)
      // if (
      //   entity.doc.items &&
      //   initialTracks.length !== entity.doc.items.length
      // ) {
      //   entity.doc.items = initialTracks.map((e): QueueItem => ({
      //     subscriptionId: e.subscriptionId,
      //     episodeKey: e.key,
      //   }));
      //   await entity.save();
      // }

      return new Queue(workspace, entity, initialTracks);
    });
  }

  isTrackInQueue(track: Track) {
    return this.dualArray.contains(track);
  }

  addTrack(track: Track) {
    return this.addToTheTop(track);
  }

  async addToTheTop(track: Track) {
    if (this.isTrackInQueue(track)) {
      log("Track already in queue, not adding it again");
      return;
    }

    log("Track not in queue, adding it to the top");
    this.dualArray.addAtIndex(track, 0);
    await this.save();
  }

  async addToTheEnd(track: Track) {
    if (this.isTrackInQueue(track)) {
      log("Track already in queue, not adding it again");
      return;
    }

    log("Track not in queue, adding it to the end");
    this.dualArray.addToEnd(track);
    await this.save();
  }

  async addAfter(track: Track, relativeTrack: Track) {
    const currentIndex = this.dualArray.indexOf(track);
    log("currentIndex", currentIndex);

    const relativeIndex = this.getTrackIndex(relativeTrack);
    log("relativeIndex", relativeIndex);
    if (relativeIndex === null) {
      console.warn("Trying to add after an track that is not in the queue", {
        track,
        relativeTrack,
      });
      return;
    }

    if (currentIndex === null) {
      log("Adding track at index", relativeIndex + 1);
      this.dualArray.addAtIndex(track, relativeIndex + 1);
    } else if (currentIndex !== relativeIndex) {
      // E = episode (moving)
      // R = relativeEpisode (moving after this)
      //
      // Case 1: E is before R
      //
      // E R . . . (currentIndex = 0, relativeIndex = 1)
      // => after removing E:
      // R . . .
      // => want to insert after R (1)
      // => newIndex = relativeIndex = 1
      //
      // Case 2: E is after R
      // R . E . . (currentIndex = 2, relativeIndex = 0)
      // = after removing E:
      // R . . .
      // => want to insert after R (1)
      // => newIndex = relativeIndex + 1 = 1

      const newIndex =
        currentIndex < relativeIndex ? relativeIndex : relativeIndex + 1;

      log(
        "Moving track to index",
        relativeIndex,
        " - after shift if needed:",
        newIndex
      );
      this.dualArray.moveByIndex(currentIndex, newIndex);
    } else {
      log("Episode already in the right position");
    }

    await this.save();
  }

  async removeEpisodeAtIndex(index: number) {
    this.dualArray.removeAtIndex(index);
    await this.save();
  }

  removeTrack(track: Track) {
    const { playerState, playingTrackController: playingEpisodeController } =
      this.workspace;
    const wasPlaying = playerState.playing;

    const wasCurrent = playingEpisodeController.currentTrack === track;

    log("wasPlaying", wasPlaying);
    log("removedEpisodeWasActive", wasCurrent);
    const index = this.getTrackIndex(track);

    if (index === null) return;

    if (wasCurrent) {
      this.workspace.playerState.clearMedia();
    }

    this.removeEpisodeAtIndex(index);

    if (wasCurrent) {
      this.continuePlayingAfterRemoval(index, wasPlaying);
    }
  }

  handleEpisodeComplete(track: Track) {
    this.removeTrack(track);
  }

  moveTrack(track: Track, position: "top" | "bottom") {
    const index = this.getTrackIndex(track);
    if (index === null) return;

    if (position === "top") {
      this.dualArray.moveByIndex(index, 0);
    } else {
      this.dualArray.moveByIndex(index, this.tracks.length - 1);
    }

    this.save();
  }

  async moveEpisode(
    subjectTrack: Track,
    relativeTrack: Track,
    position: "before" | "after"
  ) {
    const subjectIndex = this.getTrackIndex(subjectTrack);
    log("subjectIndex", subjectIndex);
    if (subjectIndex === null) return;
    const relativeIndex = this.getTrackIndex(relativeTrack);
    log("relativeIndex", relativeIndex);
    if (relativeIndex === null) return;

    // 0: A
    // 1: B
    // 2: C
    // Move A from 0 to before C (relativeIndex 2) before(-1) forward(0)   targetIndex = 1
    // Move A from 0 to after  C (relativeIndex 2) after(0)   forward(0)   targetIndex = 2
    // Move C from 0 to before A (relativeIndex 0) before(-1) backward(+1) targetIndex = 0
    // Move C from 0 to after  A (relativeIndex 0) after(0)   backward(+1) targetIndex = 1

    const targetIndex =
      relativeIndex +
      (position === "before" ? -1 : 0) +
      (relativeIndex > subjectIndex ? 0 : 1);
    position === "before" ? relativeIndex : relativeIndex + 1;
    if (subjectIndex === targetIndex) return;
    log("targetIndex", targetIndex);

    this.dualArray.moveByIndex(subjectIndex, targetIndex);

    await this.save();
  }

  private continuePlayingAfterRemoval(index: number, wasPlaying: boolean) {
    if (this.tracks.length === 0) {
      log("Queue is empty, stopping playback");
    } else if (index > this.tracks.length - 1) {
      log(
        "Last track in queue completed, stopping playback",
        index,
        ">",
        this.tracks.length - 1
      );
    } else {
      const nextEpisode = this.tracks[index];
      if (!nextEpisode) {
        console.error("No next track in queue", index, this.tracks.length);
        this.workspace.playingTrackController.clearCurrent();
        return;
      }
      if (wasPlaying) {
        log("Playing next track in queue:", nextEpisode.friendlyReference);
        // nextEpisode.play();
        recordEvent("auto-play-next-episode");
        playTrack(nextEpisode);
      } else {
        log("Activating next track in queue:", nextEpisode.friendlyReference);
        makeTrackCurrent(nextEpisode);
      }
    }
  }

  getTrackIndex(track: Track): number | null {
    const index = this.entity.doc.items.findIndex(
      (ref) => ref.trackId === track.trackId
    );
    if (index === -1) {
      // console.warn(
      //   'Episode not found in queue: "' + episode.title + '"',
      //   episode.key,
      //   this.entity.doc.items.map((ref) => ref.episodeKey),
      //   this.episodes.map((episode) => {
      //     if (episode) {
      //       const { title, key } = episode;
      //       return { title, key };
      //     }
      //     return null;
      //   })
      // );
      return null;
    }

    return index;
  }

  async save() {
    return this.entity.save().then(() => {
      console.groupCollapsed("Queue saved");
      log(JSON.stringify(this.entity.doc, null, 2));
      console.groupEnd();
    });
  }

  checkIntegrity() {
    this.tracks.forEach((track, index) => {
      if (isMissing(track)) {
        throw new Error(`Track at index ${index} is ${typeof track}`);
      }
    });
  }
}
