import { getUserFriendlyError } from "@/framework/errors/getUserFriendlyError";
import { Screen } from "@/modules/look/Screen";
import { ScreenSection } from "@/modules/look/ScreenSection";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { toUserError } from "./toUserError";
import Box from "@mui/material/Box";

type ScreenErrorDisplayProps = {
  error: unknown;
};

export const ScreenErrorDisplay = ({ error }: ScreenErrorDisplayProps) => {
  const e = toUserError(error);
  return (
    <Screen>
      <ScreenSection>
        <Card>
          {/* centered card */}
          <CardContent sx={{ textAlign: "center", py: 4 }}>
            <Typography gutterBottom>{getUserFriendlyError(e)}</Typography>

            {process.env.NODE_ENV === "development" && (
              <Box sx={{ textAlign: "left" }}>
                <pre style={{ fontSize: "0.8em" }}>
                  {JSON.stringify(e, null, 2)}
                </pre>
              </Box>
            )}
          </CardContent>
        </Card>
      </ScreenSection>
    </Screen>
  );
};
