import { StubTrack } from "./StubTrack";
import { Workspace } from "./../main/Workspace";
import { QueueItem } from "../queue/Queue";
import { Subscription } from "../subscriptions/Subscription";
import { FeedItemAttributes } from "../types";
import debug from "debug";
import { sha1 } from "@/lib/prelude/sha1";
import { Track } from "../track/Track";

const log = debug("queue:episode-referencing");

// Translating an episode reference (subscriptionId + episodeKey) to an episode.
// Used by the queue so that we can store a small reference instead of the whole podcast+episode data.

export async function getEpisodeByRef(
  workspace: Workspace,
  ref: QueueItem,
  stack: string[] = []
): Promise<Track | null> {
  stack = [...stack, "getEpisodeByRef"];
  log("getEpisodeByRef", JSON.stringify(ref));
  const { trackId } = ref;

  if (!trackId) {
    // Maybe it was inserted by a previous version of the app
    console.error(`No trackId found in QueueItem ref: ${JSON.stringify(ref)}`);
    return null;
  }

  if ("subscriptionId" in ref && ref.subscriptionId) {
    // Wait for the collection to be populated
    await workspace.subscriptionCollection.load();

    const subscriptionId = ref.subscriptionId;

    // Find subscription (podcast)
    const subscription: Subscription | null =
      await workspace.subscriptionCollection.fetch(subscriptionId);
    if (!subscription) {
      console.error(`No subscription found by id: ${subscriptionId}`);
      return null;
    }

    // Find episode within podcast
    const episode: Track | null = await subscription.getTrackById(trackId);

    if (episode) {
      return episode;
    } else {
      console.error(`No episode found by key: ${trackId} in subscription`);
      subscription.doc.items.forEach((e) => {
        log("-", getEpisodeKey(e));
      });
    }
  } else {
    // This can happen if the person enqueued an episode without a subscription
    log(`No subscriptionId found in queue item: ${JSON.stringify(ref)}`);
  }

  try {
    return await workspace.getLoadableTrackById(trackId, stack).load();
  } catch (e) {
    console.error(`Error thrown in getLoadableTrackById: ${e}`);
    return new StubTrack(workspace, ref);
  }
}

export function getEpisodeKey(episode: Partial<FeedItemAttributes>) {
  const sTitle = sanitizeId(episode.title ?? "");
  const sGuid = sanitizeId(episode.guid ?? "");
  const sPubDate = sanitizeId(episode.publishedAt ?? "");
  const sAudioUrl = sha1(episode.audioUrl ?? "");

  return `${sTitle}${sGuid}${sPubDate}${sAudioUrl}`.slice(0, 64);
}

function sanitizeId(s: string) {
  return s.replace(/[^a-zA-Z0-9]/gi, "");
}
