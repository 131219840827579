import Dialog, { DialogProps } from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export type ResponsiveDialogProps = DialogProps;

export function ResponsiveDialog({ sx, ...props }: ResponsiveDialogProps) {
  const maxWidth = typeof props.maxWidth === "string" ? props.maxWidth : "md";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(maxWidth));

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
      sx={sx}
      {...props}
    />
  );
}

export default ResponsiveDialog;
