import { TextFieldState } from "@/lib/mobx-mui-form/TextFieldState";
import { LoadableValue } from "@/lib/mobx/LoadableValue";
import { debounce } from "@/lib/utils/debounce";
import { RemoteDictionary } from "@/modules/dictionary/remote-dictionary/RemoteDictionary";
import { SearchResults } from "@/modules/dictionary/search/types";
import { Workspace } from "@/modules/main/Workspace";
import debug from "debug";
import {
  autorun,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";

const log = debug("dict:DictionarySearchState");

// State for a search in a dictionary + input for typing your own translation.
//
export class DictionarySearchState {
  queryInput = new TextFieldState("query", "Search Term", this.initialQuery);
  translationInput = new TextFieldState("translation", "Translation");

  results: LoadableValue<SearchResults> | null = null;

  deboucedSearch: (s: string) => void;

  constructor(
    public initialQuery: string,
    public sourceLang: string | null,
    public targetLang: string | null,
    public textContext: [string, string, string] | null = null,
    private workspace: Workspace
  ) {
    makeObservable(this, {
      results: observable.shallow,
    });

    this.search(initialQuery);

    this.deboucedSearch = debounce(200, this.search.bind(this));

    reaction(
      () => this.queryInput.value,
      (value) => {
        this.deboucedSearch(this.queryInput.value);
      }
    );
  }

  search(enteredSearchTerm: string) {
    log("search", enteredSearchTerm);
    const dict = new RemoteDictionary();

    runInAction(() => {
      this.results = new LoadableValue(async () => {
        const settingsState = await this.workspace.settingsState.load();
        return await dict.findTranslations(enteredSearchTerm, {
          sourceLang: this.sourceLang,
          targetLang: this.targetLang,
          textContext: this.textContext,
          settingsState,
        });
      });
    });
  }

  get isTranslationSubmittable() {
    return (
      this.queryInput.value.length > 0 && this.translationInput.value.length > 0
    );
  }
}
