// chapaseid = CHApter-PAragraph-SEntence with-ID

// Transcript containing a list of chapters,
// each of which contains a list of paragraphs,
// each of which contains a list of sentences,
// each of which contains a list of words.

// Each chapter, paragraph, sentence, word contains a short id,
// so that they can be referenced by edits.

// Compatible with Textime

import * as z from "zod";

export const SpeakerZ = z.object({
  id: z.number().optional().nullable(),
  name: z.string().optional().nullable(),
  confidence: z.number().optional().nullable(),
});
export const WidSpeakerZ = SpeakerZ;
export type Speaker = z.infer<typeof SpeakerZ>;
export type WidSpeaker = Speaker;

export const WordWIdZ = z.object({
  id: z.string(),
  startTime: z.number(),
  endTime: z.number().optional().nullable(),
  text: z.string(),
  bareText: z.string().optional().nullable(),
  confidence: z.number().optional().nullable(),
});
export const WidWordZ = WordWIdZ;
export type WordWId = z.infer<typeof WordWIdZ>;
export type WidWord = WordWId;

export const SentenceWIdZ = z.object({
  id: z.string(),
  startTime: z.number(),
  endTime: z.number(),
  parts: z.array(WordWIdZ),
  speaker: SpeakerZ.optional().nullable(),
});
export const WidSentenceZ = SentenceWIdZ;
export type SentenceWId = z.infer<typeof SentenceWIdZ>;
export type WidSentence = SentenceWId;

export const ParagraphWSeIdZ = z.object({
  id: z.string(),
  startTime: z.number(),
  endTime: z.number(),
  parts: z.array(SentenceWIdZ),
  speaker: SpeakerZ.optional().nullable(),
});
export const WidParagraphZ = ParagraphWSeIdZ;
export type ParagraphWSeId = z.infer<typeof ParagraphWSeIdZ>;
export type WidParagraph = ParagraphWSeId;

export const ChapterWPaSeIdZ = z.object({
  id: z.string(),
  title: z.string().optional(),
  startTime: z.number(),
  endTime: z.number(),
  parts: z.array(ParagraphWSeIdZ),
});
export const WidChapterZ = ChapterWPaSeIdZ;
export type ChapterWPaSeId = z.infer<typeof ChapterWPaSeIdZ>;
export type WidChapter = ChapterWPaSeId;

export const TranscriptWChaPaSeIdZ = z.object({
  parts: z.array(ChapterWPaSeIdZ),
  language: z.string().nullish(),
});
export const WidTranscriptZ = TranscriptWChaPaSeIdZ;
export type TranscriptWChaPaSeId = z.infer<typeof TranscriptWChaPaSeIdZ>;
export type WidTranscript = TranscriptWChaPaSeId;
