import { makeAutoObservable, observable } from "mobx";
import { Workspace } from "@/modules/main/Workspace";
import { FeedAttributes } from "../types";

export class Podcast {
  constructor(public workspace: Workspace, public attributes: FeedAttributes) {
    this.attributes = attributes;
    makeAutoObservable(this, { attributes: observable });
  }

  get url() {
    return this.attributes.url;
  }
  get isPublic() {
    return this.attributes.isPublic;
  }
  get title() {
    return this.attributes.title;
  }
  get description() {
    return this.attributes.description;
  }
  get owner() {
    return this.attributes.owner;
  }
  get coverUrl() {
    return this.attributes.coverUrl;
  }
  get language() {
    return this.attributes.language;
  }
  get linkUrl() {
    return this.attributes.linkUrl;
  }

  // get episodes(): Episode[] {
  //   return this.attributes.episodes.map((feedEpisodeResult) => {
  //     return this.workspace.getOrMakeEpisodeInstance(
  //       feedEpisodeResult,
  //       this.subscription,
  //       this
  //     );
  //   });
  // }

  // getEpisodeByKey(key: string): Episode | null {
  //   const feedEpisodeResult = this.attributes.episodes.find(
  //     (feedEpisodeResult) => getEpisodeKey(feedEpisodeResult) === key
  //   );
  //   if (!feedEpisodeResult) return null;
  //   return this.workspace.getOrMakeEpisodeInstance(feedEpisodeResult);
  // }
}
