import { localStorageUtils } from "@/lib/utils/localStorageUtils";
import { DateTime } from "luxon";

export function getLastOpenDate(): DateTime | null {
  // fromISO creates a local DateTime by default
  return localStorageUtils.getCustom<DateTime>(
    "lastOpenDate",
    DateTime.fromISO
  );
}

export function setLastOpenDateToToday() {
  setLastOpenDate(DateTime.local());
}

export function setLastOpenDate(value: DateTime) {
  localStorageUtils.set("lastOpenDate", value?.toISODate());
}

// ---------------------------------------------

export function getFirstOpenDate(): DateTime | null {
  return localStorageUtils.getCustom<DateTime>(
    "firstOpenDate",
    DateTime.fromISO
  );
}

export function setFirstOpenDateToToday() {
  setFirstOpenDate(DateTime.local());
}

export function setFirstOpenDate(value: DateTime) {
  localStorageUtils.set("firstOpenDate", value?.toISODate());
}

export function isFirstOpenDateToday() {
  const firstOpenDate = getFirstOpenDate();
  return firstOpenDate ? isToday(firstOpenDate) : true;
}

// ---------------------------------------------

export function getUsageDaysCounter() {
  return localStorageUtils.getInteger("daysCounter") ?? 0;
}

export function setUsageDaysCounter(value: number) {
  localStorageUtils.set("daysCounter", value);
}

// ---------------------------------------------

export function isToday(d: DateTime): boolean {
  return d.startOf("day").equals(DateTime.local().startOf("day"));
}
