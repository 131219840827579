import { runInAction } from "mobx";
import { ProgressStateCancellable } from "@/lib/mobx/ProgressStateCancellable";
import { Database } from "@/lib/mobx-pouch/Database";
import { IWEnv } from "@/lib/nemmp/base/types";
import { DatabaseFactory } from "./DatabaseFactory";
import { WorkspacesManagerState } from "./WorkspacesManagerState";
import { PubSub } from "@/lib/utils/PubSub";
import type { MAppEnv } from "@/framework/workspacing/MAppEnv";
import debug from "debug";

const log = debug("pouch:sync:WorkspacesManager");

export type WorkspacesManagerOptions<TWEnv> = {
  beforeWorkspaceChange?: () => Promise<void>;
  buildNewWorkspaceEnv: (database: Database) => TWEnv;
  apiBaseUrl: string;
  baseDbName: string;
  mAppEnv?: MAppEnv;
};

export type WorkspaceOptions = {
  name?: string;
};

export class WorkspacesManager<TWEnv extends IWEnv> {
  switchPubSub = new PubSub<TWEnv>();
  currentWEnv: TWEnv | null = null;

  static async build<TWEnv extends IWEnv>(
    storage: Storage,
    options: WorkspacesManagerOptions<TWEnv>
  ): Promise<WorkspacesManager<TWEnv>> {
    const state = new WorkspacesManagerState(
      storage,
      options.baseDbName,
      options.mAppEnv
    );

    const databaseFactory = new DatabaseFactory(state, options);

    const manager = new WorkspacesManager(state, databaseFactory, options);
    return manager;
  }

  constructor(
    public state: WorkspacesManagerState,
    public databaseFactory: DatabaseFactory<TWEnv>,
    public options: WorkspacesManagerOptions<TWEnv>
  ) {
    log("constructing WorkspaceManager");
    this.switchToIndex(this.state.currentIndex);
  }

  get currentEntry() {
    return this.state.currentEntry;
  }

  // Switch to the last used workspace or the newly created one.
  async initialize(): Promise<TWEnv> {
    try {
      console.group("WorkspacesManager.initialize");
      // `state` will make sure we have at least one workspace
      return this.switchToIndex(this.state.currentIndex);
    } finally {
      console.groupEnd();
    }
  }

  get isSyncEnabled(): boolean {
    return this.state.isSyncEnabled ?? false;
  }

  /**
   * Enable sync of local data
   */
  async enableSync(): Promise<string> {
    const accessKey = this.enableSyncWithAccessKey();

    // Get CouchDB URL from server (based on the accessKey in localStorage)
    const url = await this.databaseFactory.getDatabaseUrl();
    if (!url) throw new Error("Blank url returned by getDatabaseUrl");

    if (!this.currentWEnv) throw new Error("No current workspace");

    await this.currentWEnv.database.makeSynced(url);

    return accessKey;
  }

  // Called from enableSync when user enables sync of their local data
  // Returns access key to be shared as a QR code
  //
  enableSyncWithAccessKey(): string {
    const accessKey = this.state.getOrGenerateAccessKey();
    this.state.updateCurrentEntry({ remoteAccessKey: accessKey });
    return accessKey;
  }

  /**
   * User scanned the QR code and wants to sync data from another device
   */
  async setupSyncByAccessKey(
    accessKey: string,
    progressState?: ProgressStateCancellable
  ) {
    log("syncBasedOnAccessKey", accessKey);

    // Get CouchDB URL from server (based on the accessKey in localStorage)
    const dbUrl = await this.databaseFactory.fetchDatabaseUrl(accessKey);
    log("syncBasedOnAccessKey - got url", dbUrl);
    if (!dbUrl) throw new Error("Blank url returned by fetchDatabaseUrl");

    this.state.switchToNewWorkspace({
      remoteAccessKey: accessKey,
      remoteDatabaseUrl: dbUrl,
    });

    log("pouch: setupSyncByAccessKey");
    const database = new Database(dbUrl, this.state.localName);

    log("calling makeSynced");
    await database.makeSyncedDown(dbUrl, progressState);

    if (this.options.beforeWorkspaceChange)
      await this.options.beforeWorkspaceChange();

    this.switchToDatabase(database);
  }

  async createBlankWorkspace(
    workspaceOptions: WorkspaceOptions = {},
    {
      prepareDatabase,
    }: { prepareDatabase?: (database: Database) => Promise<void> } = {}
  ) {
    this.state.switchToNewBlankWorkspace(workspaceOptions);

    log("createBlankWorkspace");
    const database = new Database(null, this.state.localName);

    if (prepareDatabase) {
      await prepareDatabase(database);
    }

    if (this.options.beforeWorkspaceChange) {
      await this.options.beforeWorkspaceChange();
    }

    this.switchToDatabase(database);
  }

  async switchToIndex(index: number): Promise<TWEnv> {
    log("switchToIndex", index);
    if (this.options.beforeWorkspaceChange)
      await this.options.beforeWorkspaceChange();

    this.state.switchToIndex(index);

    const database = new Database(
      this.state.remoteDatabaseUrl,
      this.state.localName
    );

    return this.switchToDatabase(database);
  }

  switchToDatabase(database: Database): TWEnv {
    this.currentWEnv = this.options.buildNewWorkspaceEnv(database);
    log("switchPubSub publish:", this.currentWEnv.key);
    this.switchPubSub.publish(this.currentWEnv);
    return this.currentWEnv;
  }

  async deleteCurrentWorkspace() {
    if (!this.currentWEnv) throw new Error("No current workspace");
    await this.currentWEnv.database.deleteLocalDb();
    this.state.deleteCurrentWorkspace();
    await this.switchToIndex(0);
  }

  /**
   * Delete current database locally
   */
  async deleteLocalData() {
    if (!this.currentWEnv) throw new Error("No current workspace");
    await this.currentWEnv.database.deleteLocalDb();
    this.clearLocalStorageData();
  }

  // Called when the local database is deleted (from the console)
  // TODO: replace with removing an item from the workspaces list
  clearLocalStorageData() {
    this.state.updateCurrentEntry({
      remoteAccessKey: null,
      remoteDatabaseUrl: null,
    });
  }
}
