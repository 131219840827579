export function logTime<T>(label: string, fn: () => T): T {
  const start = performance.now();
  try {
    return fn();
  } finally {
    const end = performance.now();
    console.log(label, end - start);
  }
}

export const mkBenchmarkLogger =
  <R>(
    logCb: (duration: number) => void,
    fn: () => Promise<R>
  ): (() => Promise<R>) =>
  async () => {
    const startTime = process.hrtime();
    const ret = await fn();
    const endTime = process.hrtime(startTime);
    const delta = endTime[0] + endTime[1] / 1_000_000_000;
    logCb(delta);
    return ret;
  };

// Make a function that benchmarks another function and returns the function result and the duration in seconds
export const mkBenchmarkR =
  <R>(fn: () => Promise<R>): (() => Promise<{ result: R; duration: number }>) =>
  async () => {
    const startTime = process.hrtime();
    const result = await fn();
    const endTime = process.hrtime(startTime);
    const duration = endTime[0] + endTime[1] / 1_000_000_000;

    return { result, duration };
  };

// Benchmark a function and return the function result and the duration in seconds
export const benchmarkR = async <R>(
  fn: () => Promise<R>
): Promise<{ result: R; duration: number }> => {
  const startTime = process.hrtime();
  const result = await fn();
  const endTime = process.hrtime(startTime);
  const duration = endTime[0] + endTime[1] / 1_000_000_000;

  return { result, duration };
};
