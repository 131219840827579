import { AppConfig } from "@/lib/nemmp/app-config/types";
import { WorkspaceData, WorkspaceEntry } from "./types";
import { getJsonFromLocalStorage } from "@/lib/utils/getJsonFromLocalStorage";

export const loadWorkspacesData = (appConfig: AppConfig): WorkspaceData => {
  let workspacesData = getJsonFromLocalStorage("workspaces");

  // First use
  if (!workspacesData || workspacesData.entries.length === 0) {
    workspacesData = {
      currentIndex: 0,
      entries: [
        {
          name: "Workspace 1",
          localName: appConfig.code,
          remoteAccessKey: null,
          remoteDatabaseUrl: null,
        },
      ],
    };
  }
  // Validate
  if (!workspacesData.entries[workspacesData.currentIndex]) {
    workspacesData.currentIndex = 0;
  }
  workspacesData.entries.forEach((entry: any, index: number) => {
    if (!entry.name) {
      entry.name = `Workspace ${index + 1}`;
    }
    if (
      entry.remoteDatabaseUrl &&
      typeof entry.remoteDatabaseUrl !== "string"
    ) {
      entry.remoteDatabaseUrl = entry.remoteDatabaseUrl.dbUrl;
    }
  });

  // console.log("workspacesData", workspacesData);

  return workspacesData;
};
