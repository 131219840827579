import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const TrackInfoTextBox = ({
  title,
  programmeTitle,
  publishedAt,
  debugInfo,
}: {
  title: string | React.ReactNode;
  programmeTitle?: string;
  publishedAt?: string;
  debugInfo?: string;
}) => {
  return (
    <Box
      sx={{ overflow: "hidden", height: "80px", textAlign: "left" }}
      className="text-box"
    >
      {debugInfo && (
        <Typography variant="caption" component="div">
          {debugInfo}
        </Typography>
      )}
      <Typography variant="caption" component="div" sx={{ textAlign: "left" }}>
        {publishedAt}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{
          textAlign: "left",
          maxHeight: programmeTitle ? "40px" : "60px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // background: "green",
        }}
      >
        {title}
      </Typography>
      {programmeTitle && (
        <Typography
          variant="caption"
          component="div"
          sx={{
            textAlign: "left",
            maxHeight: "20px",
            // background: "yellow"
          }}
        >
          {programmeTitle}
        </Typography>
      )}
    </Box>
  );
};
