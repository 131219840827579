import { observable } from "mobx";
import { makeObservable } from "mobx";
import { debug } from "debug";

const log = debug("routing:History");

export type HistoryItem = {
  // ID so that we can uniquely identify the item when handling popstate event
  // (without the ID we would have to compare the fragment, which is not unique;
  // users can navigate to any path in the history by long-pressing the back/forward button)
  id: number;

  // The string that comes after the `#` in the URL
  path: string | null;
};

/**
 * Store the items that are in the history stack, so that we have more
 * information about where the browser's back/forward button will take us.
 */
export class History {
  public items: HistoryItem[];
  public index: number;

  constructor(item: HistoryItem) {
    log("constructor", item);

    this.items = [item];
    this.index = 0;

    makeObservable(this, {
      items: observable,
      index: observable,
    });

    this.logState();
  }

  push(item: HistoryItem) {
    log("push", item);
    // Clear the items that are after the current index, since it won't be possible to navigate to them anymore
    this.items = this.items.slice(0, this.index + 1);

    // Add new item
    this.items.push(item);

    // Point to the new item
    this.index++;

    this.logState();
  }

  replace(item: HistoryItem) {
    log("replace", item);

    // Clear the items that are after the current index
    this.items = this.items.slice(0, this.index + 1);

    this.items[this.index] = item;

    this.logState();
  }

  canGoBack() {
    return this.index > 0;
  }

  back() {
    log("back");

    if (this.index > 0) {
      this.index--;
    } else {
      console.warn(
        "Trying to go back while already at the beginning of the history stack"
      );
    }

    this.logState();
  }

  forward() {
    log("forward");

    if (this.index < this.items.length - 1) {
      this.index++;
    } else {
      console.warn(
        "Trying to go forward while already at the end of the history stack"
      );
    }

    this.logState();
  }

  onPopState(item: HistoryItem) {
    log("onPopState", item);
    // Find the index of the item in the history stack
    const index = this.items.findIndex((i) => i.id === item.id);

    if (index === -1) {
      console.warn(
        "Received a popstate event for an item that is not in the history stack; this is expected in development with live-reloading"
      );
      return;
    }

    this.index = index;
    this.logState();
  }

  logState() {
    log(
      `stack:\n${this.items
        .map((item, index) => {
          return `  ${index === this.index ? ">" : " "} [${item.id}] ${
            item.path
          }`;
        })
        .join("\n")}`
    );
  }
}
