import { getLocalInteger } from "@/lib/utils/localStorageUtilsV2";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { observer } from "mobx-react";
import { useState } from "react";
import { ScreenSection } from "../look/ScreenSection";
import type { CxProps } from "../types";
import { isFirstOpenDateToday } from "./usage/usage-data";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";

type AppNoticeSectionProps = CxProps;

const currentVersion = 3;
const showableUntilDate = DateTime.fromISO("2024-03-01");
const key = "appUpdateNotice";

export const AppNoticeSection = observer(({ cx }: AppNoticeSectionProps) => {
  const [dismissed, setDismissed] = useState(false);

  if (
    !isFirstOpenDateToday ||
    getLocalInteger(key) === currentVersion ||
    dismissed ||
    showableUntilDate.diffNow().milliseconds < 0
  ) {
    return null;
  }

  return (
    <ScreenSection>
      <Alert
        onClose={() => {
          localStorage.setItem(key, currentVersion.toString());
          setDismissed(true);
        }}
      >
        <AlertTitle>Important Update</AlertTitle>
        <Typography variant="body2" gutterBottom>
          Sadly, I won't be able to continue working on this app for much
          longer.
        </Typography>
        <Typography variant="body2" gutterBottom>
          You can still continue using it for now, but in order to keep my costs
          low, I ask you to sign up for transcription and translation services
          yourself and enter your API keys in the settings. Most of these
          services also have a free quota. Transcriptions will continue to work
          for some time, but please get your own API key soon to avoid any
          disruptions later.
        </Typography>
      </Alert>
    </ScreenSection>
  );
});
