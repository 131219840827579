import { observer } from "mobx-react";
import { TrackListLook } from "../look/TrackListLook";
import { CxProps } from "../types";
import { Queue } from "./Queue";
import { QueueItemDisplay } from "./QueueItemDisplay";
import { Track } from "../track/Track";

type QueueListProps = {
  queue: Queue;
  limit?: number;
  expandButton?: React.ReactNode;
} & CxProps;

export const QueueList = observer((props: QueueListProps) => {
  const { queue, limit, expandButton, cx } = props;
  const tracksToShow = limit ? queue.tracks.slice(0, limit) : queue.tracks;
  const isExpandButtonNeeded = limit && queue.tracks.length > limit;

  return (
    <TrackListLook expandButton={isExpandButtonNeeded && expandButton}>
      {tracksToShow.map((episode: Track, index) => (
        <QueueItemDisplay
          key={episode.key}
          queue={queue}
          track={episode}
          cx={cx}
          lazy={index > 5}
        />
      ))}
    </TrackListLook>
  );
});
