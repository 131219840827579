import { ProgressStateCancellable } from "@/lib/mobx";
import { IWEnv } from "@/lib/nemmp/base";
import { WorkspacesManager } from "./WorkspacesManager";

export const processIncomingShare = async <TWEnv extends IWEnv>(
  workspacesManager: WorkspacesManager<TWEnv>,
  encodedPayload: string,
  progressState?: ProgressStateCancellable
): Promise<boolean> => {
  const payload = parseQrText(encodedPayload);
  if (payload) {
    await workspacesManager.setupSyncByAccessKey(
      payload.accessKey,
      progressState
    );

    return true;
  }
  return false;
};

type TPayload = {
  accessKey: string;
};

function parseQrText(str: string): TPayload | null {
  const parsed = parseJSON(str);
  if (!parsed) return null;
  if (typeof parsed !== "object") return null;
  if (typeof parsed.accessKey !== "string") return null;
  return {
    accessKey: parsed.accessKey,
  };
}

function parseJSON(str: string) {
  try {
    return JSON.parse(str);
  } catch (err) {
    console.error("Error while parsing payload:", err);
    return null;
  }
}
