import { forceUpdatePouchDoc, normalizePouchDBErrors } from "@/lib/pouch";
import { observable, makeAutoObservable, autorun, runInAction } from "mobx";
import { Database } from "./Database";
import debug from "debug";

const log = debug("mobx-pouch:SingletonEntity");

type TCouchMeta = { _id: string; _rev: string };
type TMeta = TCouchMeta;

// FIXME: rename to differentiate from the higher level class (TEntity)
// - SingletonEntity .docAdapter -> DocAdapter .doc, .db
// - SingletonEntity .docProxy -> DocProxy .doc, .db
// - SingletonEntity .storage -> DocAdapter
export class SingletonEntity<
  TAttributes,
  E extends { entity: SingletonEntity<TAttributes, E> }
> {
  database: Database;
  doc: TAttributes & TMeta;

  constructor(doc: TAttributes & TMeta, database: Database) {
    this.database = database;
    this.doc = doc;

    log(`init ${JSON.stringify(doc)}`);

    makeAutoObservable(this, {
      doc: observable,
    });
  }

  get db() {
    return this.database.db;
  }

  async delete() {
    await this.db.remove(this.doc);
  }

  async save() {
    const response = await forceUpdatePouchDoc(this.db, this.doc);
    runInAction(() => {
      this.doc._rev = response.rev;
    });
  }

  async putAttachment(
    attachmentId: string,
    blob: PouchDB.Core.AttachmentData,
    type: string
  ) {
    const response = await normalizePouchDBErrors(
      `while adding attachment in SingletonEntity (${attachmentId} in doc ${this.doc._id}})`,
      () =>
        this.db.putAttachment(
          this.doc._id,
          attachmentId,
          this.doc._rev,
          blob,
          type
        )
    );
    runInAction(() => {
      this.doc._rev = response.rev;
    });
  }

  async getAttachment(attachmentId: string) {
    const response = await this.db.getAttachment(this.doc._id, attachmentId);
    return response;
  }

  async put(attributes: TAttributes) {
    log(`put ${JSON.stringify(attributes)}`);
    const { _id, _rev } = this.doc;
    runInAction(() => {
      this.doc = { _id, _rev, ...attributes };
    });
    return this.save();
  }
}
