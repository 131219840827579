import { isMissing } from "@/lib/prelude";
export const isNumber = (n: any): n is number =>
  typeof n === "number" && !isNaN(n) && isFinite(n);

export const parseIntIfExists = (
  s: string | string[] | null | undefined
): number | undefined => {
  if (isMissing(s)) return undefined;
  if (Array.isArray(s)) return parseInt(s[0]);
  return parseInt(s);
};
