import { ResponsiveDialog } from "./responsive-dialog/responsive-dialog";
import Button from "@mui/material/Button";
import { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/system/Box";

export type StandardDialogProps = Omit<DialogProps, "open"> & {
  title: string;
  children: React.ReactNode;
  open?: boolean;
  onClose: () => void;
  primaryAction?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
  // Influences the spacing, so that it looks good when the dialog contains paragraphs at the beginning
  forParagraphs?: boolean;
};

export function StandardDialog(props: StandardDialogProps) {
  const {
    open,
    onClose,
    title,
    children,
    forParagraphs: forText,
    primaryAction,
    ...otherProps
  } = props;

  return (
    <ResponsiveDialog {...otherProps} open={open ?? true} onClose={onClose}>
      <DialogTitle sx={forText ? { pb: 0 } : {}}>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>{children}</Box>
      </DialogContent>
      <DialogActions>
        {primaryAction ? (
          <>
            <Button onClick={onClose} variant="text" color="primary">
              {primaryAction ? "Cancel" : "Close"}
            </Button>
            <Button
              onClick={primaryAction.onClick}
              color="primary"
              variant="contained"
              disabled={primaryAction.disabled}
            >
              {primaryAction.label}
            </Button>
          </>
        ) : (
          <Button onClick={onClose} variant="contained" color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </ResponsiveDialog>
  );
}
