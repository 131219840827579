import { Database } from "@/lib/mobx-pouch/Database";
import { AppConfig } from "@/lib/nemmp/app-config/types";
import { IWEnv } from "@/lib/nemmp/base/types";
import { makeAutoObservable, observable } from "mobx";
import { Router } from "../routing/Router";
import { randomName } from "@/lib/utils/random";

export class WEnv<TWorkspace> implements IWEnv {
  public storage = window.localStorage;
  public key = randomName();
  public workspace: TWorkspace;

  constructor(
    public appConfig: AppConfig,
    public database: Database,
    public router: Router,
    public workspaceConstructor: (wEnv: WEnv<TWorkspace>) => TWorkspace,
    public updateNum = 1
  ) {
    this.workspace = workspaceConstructor(this);
    makeAutoObservable(this, {
      database: observable,
      workspace: observable,
      router: observable,
      updateNum: observable,
    });
  }
}
