import { css } from "@emotion/css";
import { useEffect, useRef, useState } from "react";

type Result = {
  source: string;
  translations: Array<string>;
};

export type TranslationWrapper = React.FC<{
  source: string;
  translation: string;
  children: React.ReactNode;
}>;

export type ResultsTableProps = {
  results: Result[];
  onResultPick?: (source: string, translation: string) => void;
  translationWrapper?: TranslationWrapper;
};

export function ResultsTable(props: ResultsTableProps) {
  const { results, onResultPick, translationWrapper } = props;

  if (typeof results === "undefined" || results.length === 0) {
    return null;
  }
  const TranslationWrapperC =
    translationWrapper ?? (({ children }) => <>{children}</>);

  return (
    <table
      id="translations"
      className={css`
        min-width: 25em;
        min-width: calc(min(100%, 25em));
        max-width: 40em;
        margin: 0 auto;
        width: 100%;
        &,
        th,
        td {
          border-collapse: collapse;
          border: solid 1px rgba(192, 192, 192, 0.3);
        }
        th,
        td {
          padding: 0.5em;
        }
        td {
          width: 50%;
          text-align: left;
          vertical-align: top;
        }
        .translation:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      `}
    >
      <tbody>
        {results.map(({ source, translations }) => (
          <tr key={source}>
            <td>
              <div style={{ padding: "8px 0" }}>{source}</div>
            </td>
            <td>
              {translations.map((translation) => (
                <TranslationWrapperC
                  key={translation}
                  source={source}
                  translation={translation}
                >
                  <div
                    onClick={() =>
                      onResultPick &&
                      !translationWrapper &&
                      onResultPick(source, translation)
                    }
                    style={{
                      cursor: onResultPick ? "pointer" : "default",
                    }}
                  >
                    {translation}
                  </div>
                </TranslationWrapperC>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ResultsTable;
