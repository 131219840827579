import { useState } from "react";
import { ExpandButtonLook } from "../look/ExpandButtonLook";
import { CxProps } from "../types";
import { FeaturedEpisodeCollection } from "./FeaturedEpisodeCollection";
import { FeaturedEpisodeList } from "./FeaturedEpisodeList";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";

export const ExpandableFeaturedEpisodeList = ({
  cx,
  featuredEpisodeCollection,
  collapsedLimit = 5,
}: CxProps & {
  featuredEpisodeCollection: FeaturedEpisodeCollection;
  collapsedLimit?: number;
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    recordEvent("expand-featured-episodes-list");
    setExpanded(!expanded);
  };

  return (
    <>
      <FeaturedEpisodeList
        cx={cx}
        featuredEpisodeCollection={featuredEpisodeCollection}
        limit={expanded ? undefined : collapsedLimit}
        expandButton={
          !expanded && (
            <ExpandButtonLook onClick={handleExpandClick}>
              Show more
            </ExpandButtonLook>
          )
        }
      />
    </>
  );
};
