import React from "react";
import { css } from "@emotion/css";
import { TranslationFavoriteButton } from "./TranslationFavoriteButton";

export const TranslationWrapper = ({
  source,
  translation,
  children,
  onButtonClick,
}: {
  source: string;
  translation: string;
  children: React.ReactNode;
  onButtonClick: (source: string, translation: string) => void;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      className={css`
        margin: 8px -8px;
        padding: 0 8px;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: rgba(0, 192, 255, 0.2);
          transition: background-color 0.1s ease-in-out;
        }
      `}
    >
      <div style={{ flex: "1" }}>{children}</div>
      <div>
        <TranslationFavoriteButton
          onClick={() => onButtonClick(source, translation)}
        />
      </div>
    </div>
  );
};
