import { observer } from "mobx-react";
import { StarterCx } from "../app/contexts";
import { NemmpContactDialog } from "../dialogs/NemmpContactDialog";

export const DialogSwitch = observer(
  <TWorkspace,>({ cx }: { cx: StarterCx<TWorkspace> }) => {
    const { router } = cx;

    // if (router.dialog === "help") {
    //   return (
    //     <HelpDialog
    //       open={true}
    //       onClose={() => router.closeDialog()}
    //       wEnv={wEnv}
    //     />
    //   );
    // }

    // if (router.dialog === "feedback") {
    //   return (
    //     <FeedbackDialog
    //       onClose={() => router.closeDialog()}
    //       questions={questions}
    //       voucherPrefix={appConfig.code.toUpperCase()}
    //     />
    //   );
    // }
    // console.log("DialogSwitch", router.dialog);

    if (router.dialog === "contact") {
      return <NemmpContactDialog cx={cx} />;
    }

    return null;
  }
);
