import { Track } from "../track/Track";

export async function removeFromQueue(track: Track) {
  const { workspace } = track;
  const queue = await workspace.queue;
  if (!queue) {
    throw new Error("Cannot remove from queue - no queue in workspace");
  }
  queue.removeTrack(track);
}
