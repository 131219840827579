import { ErrorDisplay } from "@/framework/errors/ErrorDisplay";
import { LoadableValue } from "@/lib/mobx";
import { ErrorText } from "@/lib/mui/ErrorText";
import { observer } from "mobx-react";
import { useEffect } from "react";

type LoadablePlaceholderProps<T> = {
  loadable?: LoadableValue<T>;
  renderLoaded: (a: T) => JSX.Element | string | null;
  renderPlaceholder: () => React.ReactNode;
  renderError?: (error: any) => React.ReactNode;
};

export const LoadablePlaceholder = observer(function <T>(
  props: LoadablePlaceholderProps<T>
) {
  const { loadable, renderLoaded, renderPlaceholder } = props;
  useEffect(() => {
    if (loadable?.load) {
      loadable.load();
    }
  }, [loadable]);

  if (typeof loadable === "undefined") return null;

  // if (loadable.unstarted) {
  //   return <span>unstarted</span>
  // }

  // if (loadable.inProgress) {
  //   return <span>inProgress</span>
  // }

  if (loadable.unstarted || loadable.inProgress) {
    return <>{renderPlaceholder()}</>;
  }
  if (loadable.succeeded) {
    if (typeof loadable.value === "undefined") {
      console.error("loadable succeeded but no value");
      return <ErrorText>Failed to load (Error code: LS-1332)</ErrorText>;
    }
    return <>{renderLoaded(loadable.value)}</>;
  }

  if (loadable.error instanceof Error) {
    if (props.renderError) {
      return <>{props.renderError(loadable.error)}</>;
    } else {
      return <ErrorDisplay error={loadable.error} />;
    }
  }

  return <ErrorText>Failed to load (Error code: LS-1331)</ErrorText>;
});
