import { action, computed, makeAutoObservable } from "mobx";
import { Workspace } from "@/modules/main/Workspace";
import { Entity } from "@/lib/mobx-pouch/Entity";
import { debugFactory } from "@/lib/utils/debug";
import { ratelimit } from "@/lib/utils/debounce";

export type ListenerEpisodeAttributes = {
  type: string;
  listeningPositionSeconds: number | null;
  duration: number | null;
};

export type ListenerEpisodeDoc = {
  _id: string;
  _rev: string;
} & ListenerEpisodeAttributes;

// Instantiated in ListenerEpisodeCollection
//
// Access:
//    - workspace.listenerEpisodeCollection.createOrFind(`listenerEpisode:${episode.key}`)
//    - workspace.listenerEpisodeCollection.fetch(`listenerEpisode:${episode.key}`)
export class ListenerEpisode {
  debug: (...args: any) => void;
  slowSave: () => void;

  constructor(
    public workspace: Workspace,
    public entity: Entity<ListenerEpisodeAttributes, ListenerEpisode>
  ) {
    makeAutoObservable(this, {
      isComplete: computed,
      resetListeningPosition: action,
      updateListeningPosition: action,
    });
    this.debug = debugFactory(`ListenerEpisode ${this.doc._id}`);

    // For saving the listening position
    this.slowSave = ratelimit(10000, () => {
      this.debug("slowSave: saving");
      this.save();
    });
  }

  get db() {
    return this.entity.db;
  }
  get doc(): ListenerEpisodeDoc {
    return this.entity.doc;
  }
  get listeningPositionSeconds() {
    return this.doc.listeningPositionSeconds;
  }
  get duration() {
    return this.doc.duration;
  }

  get isComplete() {
    if (this.doc.duration === null) return false;
    return this.doc.listeningPositionSeconds === this.doc.duration;
  }

  resetListeningPosition() {
    this.doc.listeningPositionSeconds = 0;
    this.slowSave();
  }

  updateListeningPosition(
    position: number,
    duration: number | null,
    { save = false } = {}
  ) {
    if (
      this.doc.listeningPositionSeconds === position &&
      this.doc.duration === duration
    ) {
      return;
    }
    // consoleGroup(`updateListeningPosition(${position}, ${duration})`, () => {
    this.doc.listeningPositionSeconds = position;
    this.doc.duration = duration;

    if (save) {
      this.save();
    } else {
      this.slowSave();
    }
    // });
  }

  async save() {
    this.debug("save");
    return this.entity.save();
  }
}
