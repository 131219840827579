import { observable, makeAutoObservable, action, computed } from "mobx";
import { DateTime } from "luxon";

const testMode = false;
const interval = testMode ? 50 : 1000;

// State that will update `time` at every second.
// Global for the whole application.
export class SecondsBeat {
  time: DateTime;

  constructor() {
    this.time = DateTime.now();
    makeAutoObservable(this, {
      time: observable,
      updateTimeToCurrent: action,
    });
  }

  updateTimeToCurrent() {
    if (testMode) {
      this.time = this.time.plus({ minutes: 1, seconds: 1 });
    } else {
      this.time = DateTime.now();
    }
  }
}

let secondsBeat: SecondsBeat | null = null;

export function getSecondsBeat(): SecondsBeat | null {
  if (typeof window === "undefined") {
    return null;
  }
  if (secondsBeat) {
    return secondsBeat;
  }
  secondsBeat = new SecondsBeat();

  const millisecondsUntilNextSecond = () =>
    interval - (DateTime.now().toMillis() % interval);

  const scheduleNextUpdate = () => {
    setTimeout(() => {
      if (secondsBeat) {
        secondsBeat.updateTimeToCurrent();
        scheduleNextUpdate();
      }
    }, millisecondsUntilNextSecond());
  };

  scheduleNextUpdate();
  return secondsBeat;
}
