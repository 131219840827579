import { debugFactory } from "@/lib/utils/debug";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { StarterCx } from "../app/contexts";
import { WScreenSwitch } from "./WScreenSwitch";

const debug = debugFactory("routing:WRouter");

// Root element for client-side custom routing
// - listens to the popstate event and delegates to the router object

export const WRouter = observer(
  <TWorkspace,>({ cx }: { cx: StarterCx<TWorkspace> }) => {
    const { wEnv, router } = cx;
    debug("WRouter", `updateNum:${wEnv.updateNum}`);
    const [attached, setAttached] = useState(false);

    useEffect(() => {
      const popStateHandler = (event: PopStateEvent) => {
        debug("popstate", event);
        router.onPopState(event);
      };

      if (!attached) {
        window.addEventListener("popstate", popStateHandler);
        debug("popstate in WRouter - ON");
        setAttached(true);
      }

      return () => {
        setAttached(false);
        window.removeEventListener("popstate", popStateHandler);
        debug("popstate in WRouter - OFF");
      };
    }, [router]);

    return <WScreenSwitch cx={cx} />;
  }
);
