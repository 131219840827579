// Matomo Docs:
// https://developer.matomo.org/api-reference/tracking-javascript

import { debugFactory } from "@/lib/utils/debug";

// trackEvent(category, action, [name], [value])
//
// Log an event with an event category (Videos, Music, Games...),
// an event action (Play, Pause, Duration, Add Playlist, Downloaded, Clicked...),
// and an optional event name and optional numeric value.

const alreadyTrackedEvents: Record<string, boolean> = {};

const debug = debugFactory("tracking");

export function trackEvent(
  category: string | undefined | null,
  action: string,
  name?: string | undefined,
  value?: string | number | undefined,
  options?: {
    once?: boolean | undefined;
  }
) {
  debug("trackEvent", category, action, name, value, options);

  const { once } = options || {};

  const identifier = category ? `${category}-${action}` : action;
  if (once && alreadyTrackedEvents[identifier]) {
    return;
  }
  alreadyTrackedEvents[identifier] = true;

  // Matomo
  const _paq: any = (window as any)._paq;
  if (_paq && _paq.push) {
    _paq.push(["trackEvent", category, action, name, value]);
  }

  // Plausible

  const plausible = (window as any).plausible;
  if (plausible) {
    debug("plausible <-", identifier);
    plausible(identifier);
  }
}

export function trackSimpleEvent(action: string) {
  return trackEvent(null, action);
}

export function trackEventOnce(
  category: string | undefined | null,
  action: string
) {
  return trackEvent(category, action, undefined, undefined, { once: true });
}

export function trackSimpleEventOnce(action: string) {
  return trackEvent(null, action, undefined, undefined, { once: true });
}
