import { LoadableValue } from "@/lib/mobx";
import { isNumber, isPresent } from "@/lib/prelude";
import { TranscriptWChaPaSeId } from "@/modules/transcript/formats/types";
import { Workspace } from "@/modules/main/Workspace";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { QueueItem } from "../queue/Queue";
import { ITrack } from "../track/ITrack";
import { ListenerEpisode } from "./ListenerEpisode";
import { getEpisodeKey as _getEpisodeKey } from "./episode-referencing";

export const getEpisodeKey = _getEpisodeKey;

// StubTrack is used when the episode is not available, e.g. deleted from the feed.
export class StubTrack implements ITrack {
  workspace: Workspace;

  listenerEpisode: ListenerEpisode | null = null;

  debugAttributes = {};

  constructor(workspace: Workspace, public ref: QueueItem) {
    this.workspace = workspace;
    makeAutoObservable(this, {
      listenerEpisode: observable,
      isCurrent: computed,
      isComplete: computed,
      listeningPositionSeconds: computed,
      listeningPositionPercent: computed,
    });
  }

  get trackId() {
    return this.ref.trackId;
  }

  get key() {
    return this.trackId;
  }

  get title(): string {
    return this.ref.title;
  }

  get isCurrent() {
    const currentTrack = this.workspace.playingTrackController.currentTrack;
    if (!currentTrack) return false;
    return currentTrack.key === this.key;
  }

  get isComplete() {
    return this.listenerEpisode?.isComplete ?? false;
  }

  // Reference: stored in the queue, used to find the episode in the queue
  get reference() {
    return this.ref;
  }

  get publishedAt(): string | undefined {
    return undefined;
  }
  get audioUrl(): string {
    return "";
  }
  get coverUrl(): string | undefined {
    return undefined;
  }

  get listeningPositionSeconds(): number | null {
    return this.listenerEpisode?.listeningPositionSeconds ?? null;
  }

  get listeningPositionPercent(): number | null {
    if (!this.listenerEpisode) return null;
    const { listeningPositionSeconds, duration } = this.listenerEpisode;
    if (
      !isNumber(listeningPositionSeconds) ||
      !isNumber(duration) ||
      duration === 0
    )
      return null;
    return (listeningPositionSeconds / duration) * 100;
  }

  get friendlyReference() {
    return `${this.title} from ${this.title}`;
  }

  async getOrCreateListenerEpisode(): Promise<ListenerEpisode> {
    return this.workspace?.getListenerEpisode(this.trackId);
  }
}
