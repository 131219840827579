import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { getTrackOptimizedCoverUrlFn } from "@/modules/podcast/image/url";
import { observer } from "mobx-react";
import { getFragmentRouteForTrack } from "../app/fragment-routes";
import { CoverImageBox } from "../episode/track-list-item/CoverImageBox";
import type { CxProps } from "../types";
import { PlayingTrackButton } from "./PlayingTrackButton";

type PlayingTrackInfoBarProps = CxProps;

export const PlayingTrackInfoBar = observer(
  ({ cx }: PlayingTrackInfoBarProps) => {
    const { workspace } = cx.wEnv;
    const { playingTrackController } = workspace;
    const { currentTrack: track } = playingTrackController;
    const { router } = cx;

    // Don't show the bar if there's no track playing/current.
    if (!track) {
      return null;
    }

    // Don't show the bar if we're already on the track page.
    if (router.fragment === getFragmentRouteForTrack(track)) {
      return null;
    }

    const height = 64;

    const navigateToTrack = () => {
      recordEvent("playing-track-bar-clicked");
      cx.router.goTo(getFragmentRouteForTrack(track));
    };

    return (
      <div id="playing-track-bar" onClick={navigateToTrack}>
        <div className="cover">
          <CoverImageBox
            size={height}
            coverUrlFn={getTrackOptimizedCoverUrlFn(track)}
          />
        </div>

        <div className="title">{track.title}</div>

        <div className="control">
          <PlayingTrackButton cx={cx} />
        </div>
      </div>
    );
  }
);
