import { DialogState } from "@/lib/mobx";
import { observer } from "mobx-react";
import { BackableDialog, BackableDialogProps } from "./BackableDialog";

export type BackableDialogMobxProps = Omit<
  BackableDialogProps,
  "open" | "onClose"
> & {
  dialogState: DialogState;
  children: React.ReactNode;
  title: string;
  afterClose?: () => void;
};

export const BackableDialogMobx = observer((props: BackableDialogMobxProps) => {
  const { dialogState, children, title, afterClose, ...otherProps } = props;

  const close = () => {
    console.log("onClose");
    dialogState.close();
    if (afterClose) afterClose();
  };

  return (
    <BackableDialog
      open={dialogState.isOpen}
      title={title}
      onClose={close}
      {...otherProps}
    >
      {children}
    </BackableDialog>
  );
});
