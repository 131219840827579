import debug from "debug";
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { DialogName, Route } from "../routing/types";
import { parseRoute } from "./parseRoute";
import { History } from "./History";
import { isMissing } from "@/lib/prelude";

const log = debug("routing:Router");

export class Router {
  debugData: any;

  // Unparsed fragment, without '#'

  fragment = "";

  // Parsed route object (this might be needed anymore)

  route: Route | null = null;

  canGoBack = false;

  history: History;

  private lastId = 0;

  constructor() {
    log("constructing Router");

    // Add a state to the initial history entry, so that we can recognize it by id,
    const hash = window.location.hash;
    const path = hash.length > 0 ? hash.replace(/^#/, "") : null;
    const state = { id: this.nextId(), path };
    window.history.replaceState(state, "", hash);
    this.history = new History(state);

    this.updateInternalState();
    makeAutoObservable(this, {
      debugData: observable,
      fragment: observable,
      route: observable,
      canGoBack: observable,
      updateDebugData: action,
      dialog: computed,
    });
  }

  updateDebugData() {
    if (typeof window === "undefined") return;
    this.debugData = {
      location: window.location,
      history: {
        length: window.history.length,
        state: window.history.state,
      },
    };
  }

  get dialog() {
    return this.route && "dialog" in this.route && this.route.dialog;
  }

  // Called after pushState/replaceState is called and on popState events
  // Updates the observable state on this instance to reflect the current route
  private updateInternalState() {
    if (typeof window === "undefined") return;
    const hash = window.location.hash;
    log("locationChanged", window.location.hash);

    runInAction(() => {
      log("changing fragment");
      this.fragment = hash.slice(1);
      log("changed fragment");
      this.route = parseRoute(
        hash.replace(/^#/, "").replace(/^\//, "").split("/")
      );
      log("route changed", this.route);
      this.canGoBack = this.history.canGoBack();
    });

    // this.updateDebugData();
  }

  goHome() {
    this.goTo(null);
  }

  // Normal navigation (back button will go to the originating page)
  goTo(path: string | null) {
    log(`----->>--- PUSH`, { path });
    const state = { id: this.nextId(), path };
    window.history.pushState(state, "", path ? `#${path}` : "#");
    this.history.push(state);
    this.updateInternalState();
  }

  // Navigation, but with replacing the current item (back button will go to the page before the originating page)
  replaceTo(path: string) {
    log(`----<>---- REPLACE`, { path });
    const state = { id: this.nextId(), path };
    window.history.replaceState(state, "", `#${path}`);
    this.history.replace(state);
    this.updateInternalState();
  }

  goBack() {
    window.history.back();
  }

  openDialog(name: DialogName) {
    this.goTo(`/${name}`);
  }

  // ------ Dialogs ------

  closeDialog(): Promise<void> {
    return new Promise((resolve) => {
      const popStateHandler = () => {
        window.removeEventListener("popstate", popStateHandler);
        resolve();
      };
      window.addEventListener("popstate", popStateHandler);

      window.history.back();
    });
  }

  suppressPopStateHandler = false;

  onPopState = (event: PopStateEvent) => {
    if (isMissing(event.state)) {
      console.warn(
        "popstate event without state; assuming user clicked a link - maybe we should avoid this by making sure every link is handled with a router.goTo()"
      );
      const path = window.location.hash.replace(/^#/, "");
      log(`----->>--- PUSH`, { path });
      const state = { id: this.nextId(), path };
      window.history.replaceState(state, "", path ? `#${path}` : "#");
      this.history.push(state);
      this.updateInternalState();
      return;
    }

    log(`---<<----- POP`, JSON.stringify(event.state));
    this.history.onPopState({
      id: event.state.id,
      path: event.state.path,
    });
    if (!this.suppressPopStateHandler) {
      this.updateInternalState();
    }
  };

  // Used by beforeWorkspaceChange, so that it can call closeDialog and openDialog when switching workspaces,
  // without triggering a popstate event
  async withSuppressedPopStateHandler(f: () => Promise<void>): Promise<void> {
    this.suppressPopStateHandler = true;
    await f();
    this.suppressPopStateHandler = false;
    this.updateInternalState();
  }

  nextId() {
    return ++this.lastId;
  }
}
