import { Singleton, TSingletonEntityConstructor } from "./Singleton";
import PouchDB from "pouchdb";
import PouchDBFind from "pouchdb-find";
import { ProgressStateCancellable } from "@/lib/mobx";
import { Collection, TEntityConstructor } from "./Collection";
import { SingletonEntity } from "./SingletonEntity";
import { debugFactory } from "../utils/debug";
import { Entity } from "./Entity";
import { toJS } from "mobx";

const debug = debugFactory("mobx-pouch:Database");

PouchDB.plugin(PouchDBFind);
// PouchDB.plugin(PouchDBDebug);

type ChangeCallback = (change: PouchDB.Core.ChangesResponseChange<any>) => void;

export class Database {
  url: string | null;
  localDbName: string | null;
  db: PouchDB.Database;
  localdb: PouchDB.Database | null = null;
  remotedb: PouchDB.Database | null = null;
  sync: null | PouchDB.Replication.Sync<any> = null;

  constructor(url: string | null, localDbName: string | null) {
    debug(`new Database(${url}, ${localDbName})`);
    if (url) url = this.normalizeUrl(url);
    this.url = url;
    this.localDbName = localDbName;

    if (!url && localDbName) {
      debug("Creating local-only database");
      this.db = this.localdb = new PouchDB(localDbName);
      return;
    } else if (url && localDbName) {
      // FIXME: delete PouchDB database when user logs out
      debug(`Creating synced database`);
      this.db = this.localdb = new PouchDB(localDbName);
      console.log("url", url);
      this.remotedb = new PouchDB(url);
      this.startSync();
    } else if (url && !localDbName) {
      debug("Using remote database");
      this.db = this.remotedb = new PouchDB(url);
    } else {
      throw new Error(
        "Could not initialize database. No url or localDbName provided."
      );
    }
  }

  // Convert a local-only db to a synced db
  async makeSynced(url: string) {
    if (!this.localdb) return;
    if (!url) throw new Error("Blank url passed to Database.makeSynced");
    url = this.normalizeUrl(url);
    this.url = url;
    this.remotedb = new PouchDB(url);
    this.startSync();
  }

  async makeSyncedDown(url: string, progressState?: ProgressStateCancellable) {
    if (!this.localdb) return;
    if (!url) throw new Error("Blank url passed to Database.makeSyncedDown");
    url = this.normalizeUrl(url);
    this.url = url;
    this.remotedb = new PouchDB(url);

    await this.syncDown(progressState);

    this.startSync();
  }

  syncDown(
    progressState?: ProgressStateCancellable
  ): Promise<{ finished: boolean }> {
    debug("syncDown");

    return new Promise((resolve) => {
      if (!this.remotedb)
        throw new Error("Cannot sync down: no remote database");
      if (!this.localdb) throw new Error("Cannot sync down: no local database");
      let totalSynced = 0;
      const c = this.localdb.changes({ live: true, since: "now" });
      c.on("change", (change) => {
        console.log("syncDown localdb change", change);
        totalSynced += 1;
        if (progressState) {
          progressState.setMessage(`Received ${totalSynced} documents...`);
        }
      });
      const r = PouchDB.replicate(
        this.remotedb,
        this.localdb,
        { live: false, retry: true, batch_size: 10 },
        () => {
          console.log("syncDown finished");
          c.cancel();
          resolve({ finished: true });
        }
      );

      if (progressState) {
        progressState.onCancel(() => {
          r.cancel();
          c.cancel();
          resolve({ finished: false });
        });
      }

      r.on("change", function (change) {
        console.log("syncDown change", change);
      });
      r.on("complete", (info) => {
        console.log("syncDown complete", info);
        progressState?.setMessage(`Sync complete`);
      });
      r.on("paused", function (info) {
        console.log("syncDown paused", info);
        progressState?.setMessage(`Sync paused (possibly network issues)`);
      });
      r.on("active", () => {
        console.log("syncDown active");
        progressState?.setMessage(`Sync in progress`);
      });
      r.on("denied", function (info) {
        console.log("syncDown denied", info);
        progressState?.setMessage(`Sync failed (received "denied" message)`);
      });
      r.on("error", function (err) {
        console.log("syncDown error", err);
        progressState?.setMessage(`Sync failed${err ? ` (${err})` : ""}`);
        resolve({ finished: false });
      });
    });
  }

  collection<A, E extends { entity: Entity<A, E> }>(
    type: string,
    entityConstructor: TEntityConstructor<A, E>
  ) {
    return new Collection<A, E>(this, type, entityConstructor);
  }

  singleton<A, E extends { entity: SingletonEntity<A, E> }>(
    id: string,
    defaultAttributes: A,
    entityConstructor: TSingletonEntityConstructor<A, E>
  ) {
    return new Singleton<A, E>(this, id, defaultAttributes, entityConstructor);
  }

  stopExistingSync() {
    if (this.sync) {
      debug("cancelling existing sync");
      this.sync.cancel();
    }
  }

  startSync() {
    debug("startSync");
    if (!this.remotedb)
      throw new Error("Cannot start sync: no remote database");

    if (!this.localdb) throw new Error("Cannot start sync: no local database");

    this.stopExistingSync();

    debug("PouchDB.sync");
    this.sync = PouchDB.sync(this.remotedb, this.localdb, {
      live: true,
      retry: true,
      batch_size: 10,
    })
      .on("change", function (info) {
        debug("sync change", info);
      })
      .on("paused", function (err) {
        debug("sync paused", err);
      })
      .on("active", function () {
        // replicate resumed (e.g. new changes replicating, user went back online)
        debug("sync active");
      })
      .on("denied", function (err) {
        // a document failed to replicate (e.g. due to permissions)
        debug("sync denied", err);
      })
      .on("complete", function (info) {
        // handle complete
        debug("sync complete", info);
      })
      .on("error", function (err) {
        // handle error
        debug("sync error", err);
      });
  }

  normalizeUrl(url: string): string {
    if (!url) throw new Error("Blank url passed to Database.normalizeUrl");
    return url;
  }

  async deleteLocalDb(): Promise<void> {
    this.stopExistingSync();

    if (this.localdb) {
      await this.localdb.destroy();
    }
  }

  // ================= CHANGES =================

  changes: PouchDB.Core.Changes<any> | null = null;
  changeCallbacks: ChangeCallback[] = [];

  onChange(callback: ChangeCallback) {
    this.changeCallbacks.push(callback);
    if (!this.changes) this.startFollowingChanges();
  }

  private startFollowingChanges() {
    this.changes = this.db.changes({
      live: true,
      since: "now",
      include_docs: true,
      conflicts: true,
      timeout: 2000,
      heartbeat: 3000,
    });
    this.changes.on("change", (change) => {
      debug("received change", change);
      // Structure:
      // - id: "<hexString32>"
      // - seq: "<number>-<string>"
      // - doc: { _id, _rev, ... }
      // - changes[]: { rev }
      this.changeCallbacks.forEach((callback) => callback(change));
    });
  }
}
