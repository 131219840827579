import { localStorageUtils } from "@/lib/utils/localStorageUtils";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import React from "react";

// TODO: remove duplication with ContactDialog

export function ContactForm() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [done, setDone] = React.useState(false);

  const defaultName = localStorageUtils.get("contact_name");
  const defaultEmail = localStorageUtils.get("contact_email");

  const handleSend = () => {
    setLoading(true);
    setError(null);
    console.log("sending message");

    localStorageUtils.set("contact_name", getInputValue("contact-name"));
    localStorageUtils.set("contact_email", getInputValue("contact-email"));

    fetch("/api/contact", {
      method: "post",
      body: JSON.stringify({
        name: getInputValue("contact-name"),
        email: getInputValue("contact-email"),
        message: getInputValue("contact-message"),
      }),
    })
      .then((response) => {
        console.log("fetch resolved", response);
        if (response.ok) {
          setLoading(false);
          setDone(true);
        } else {
          setLoading(false);
          setError(response.statusText);
        }
      })
      .catch((error) => {
        console.log("fetch rejected", error);
        setLoading(false);
        setError(error.message);
      });
  };

  return (
    <>
      {done ? (
        <Alert severity="success" variant="filled" style={{ marginTop: "1em" }}>
          Your message has been sent. Thank you!
        </Alert>
      ) : (
        <Box sx={{ mt: 1 }}>
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              id="contact-name"
              label="Your Name"
              autoFocus={!defaultName && !defaultEmail}
              variant="outlined"
              disabled={loading}
              // defaultValue={defaultName}
            />
          </FormControl>

          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              id="contact-email"
              label="Email Address"
              variant="outlined"
              disabled={loading}
              // defaultValue={defaultEmail}
            />
          </FormControl>

          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              id="contact-message"
              label="Message"
              autoFocus={!!defaultName || !!defaultEmail}
              multiline
              rows={4}
              variant="outlined"
              disabled={loading}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
        </Box>
      )}

      {error && (
        <Alert severity="error" variant="filled" sx={{ mb: 1 }}>
          Couldn't send your message. Please try again later.
          <br />
          {error}
        </Alert>
      )}

      <div>{loading && <CircularProgress />}</div>

      {done ? null : (
        <Button
          onClick={handleSend}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Send
        </Button>
      )}
    </>
  );
}

function getInputValue(id: string) {
  const element = document.querySelector("#" + id) as HTMLInputElement;
  if (!element) return null;
  return element.value;
}
