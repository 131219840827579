import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { Track } from "@/modules/track/Track";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { TrackPopupMenu } from "./TrackPopupMenu";
import { TrackDisplayState } from "@/modules/track/display/TrackDisplayState";

export const TrackPopupMenuIconButton = ({
  track,
  trackDisplayState,
}: {
  track: Track;
  trackDisplayState?: TrackDisplayState;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    recordEvent("open-track-popup-menu");
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    recordEvent("close-track-popup-menu");
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="large" edge="end" onClick={openMenu}>
        <MoreHorizIcon />
      </IconButton>
      <TrackPopupMenu
        track={track}
        onClose={closeMenu}
        anchorEl={anchorEl}
        trackDisplayState={trackDisplayState}
      />
    </>
  );
};
