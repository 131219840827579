import { makeAutoObservable, observable, action } from "mobx";

export class TextFieldState {
  value: string;
  disabled = false;

  constructor(public name: string, public label: string, initialValue = "") {
    this.value = initialValue;
    makeAutoObservable(this, {
      value: observable,
      change: action,
      clear: action,
      disabled: observable,
      disable: action,
      enable: action,
    });
  }

  change(newValue: string) {
    this.value = newValue;
  }

  clear() {
    this.value = "";
  }

  get plainText() {
    return `${this.label}\n${this.value}`;
  }

  disable() {
    this.disabled = true;
  }
  enable() {
    this.disabled = false;
  }
}
