import Typography from "@mui/material/Typography";

type SubscreenTitleProps = {
  children: React.ReactNode;
  margin?: boolean;
};

export const SubscreenTitle = ({ children, margin }: SubscreenTitleProps) => {
  return (
    <Typography variant="h5" sx={{ mx: margin ? 2 : 0 }}>
      {children}
    </Typography>
  );
};
