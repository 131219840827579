import { LoadableValue } from "@/lib/mobx";
import {
  FeedInfoClient,
  FeedInfoClientZ,
  FeedItem,
  FeedItemZ,
} from "@/modules/data/types";
import { Workspace } from "@/modules/main/Workspace";
import { makeObservable, observable, runInAction } from "mobx";
import { z } from "zod";
import { PodcastEpisodeTrack } from "../episode/PodcastEpisodeTrack";

const ApiResponseZ = z.object({
  entries: z.array(
    z.object({
      trackId: z.string(),
      feedInfo: FeedInfoClientZ,
      feedItem: FeedItemZ,
    })
  ),
});

type FeaturedEpisodeEntry = {
  trackId: string;
  feedInfo: FeedInfoClient;
  feedItem: FeedItem;
};

const firstBatchSize = 6;

export class FeaturedEpisodeCollection {
  fullyLoaded = false;

  constructor(
    public workspace: Workspace,
    public tracks: PodcastEpisodeTrack[]
  ) {
    makeObservable(this, {
      tracks: observable,
      fullyLoaded: observable,
    });
  }

  static getLoadable(
    workspace: Workspace
  ): LoadableValue<FeaturedEpisodeCollection> {
    return new LoadableValue<FeaturedEpisodeCollection>(async () => {
      const result = await workspace.api.getV2(
        `/tracks/featured?limit=${firstBatchSize}`
      );

      const parsed = ApiResponseZ.parse(result);
      const tracks: PodcastEpisodeTrack[] = [];

      for (const entry of parsed.entries) {
        const track = workspace.getOrMakeTrackInstance(
          entry.trackId,
          entry.feedInfo,
          entry.feedItem
        );
        tracks.push(track);
      }

      const collection = new FeaturedEpisodeCollection(workspace, tracks);
      collection.loadRest();
      return collection;
    });
  }

  get hasAnyItems() {
    return this.tracks.length > 0;
  }

  async loadRest() {
    const { workspace } = this;
    const result = await workspace.api.getV2(
      `/tracks/featured?offset=${firstBatchSize}`
    );

    const parsed = ApiResponseZ.parse(result);
    const tracks: PodcastEpisodeTrack[] = [];

    for (const entry of parsed.entries) {
      const track = workspace.getOrMakeTrackInstance(
        entry.trackId,
        entry.feedInfo,
        entry.feedItem
      );
      tracks.push(track);
    }

    runInAction(() => {
      this.tracks = [...this.tracks, ...tracks];
      this.fullyLoaded = true;
    });
  }
}
