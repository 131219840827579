import { AudioPlayerState } from "@/lib/audio-player/AudioPlayerState";
import { isEditableElementFocussed } from "@/lib/utils/browser-form";
import { useEffect } from "react";

// Handles Esc, '/' keys
//
// USAGE:
//
// In a React functional component:
//
//    useKeyboardShortcuts(playerState);
//
// Used by PlayerControlRow.
// Related: src/lib/audio-player/player-event-listeners.ts
//

export class KeyboardShortcutHandler {
  playerState: AudioPlayerState | null = null;
  searchInputElement: HTMLInputElement | null = null;
  wordSearchDialogState: { open: () => void } | null = null;

  handleKeyDown = (event: KeyboardEvent) => {
    if (!this.playerState) return;

    if (event.key === "Escape") {
      console.log("back 2 seconds");
      this.playerState.addTime(-2);
      return;
    }

    if (isEditableElementFocussed()) return;

    if (event.key === "/" && this.wordSearchDialogState) {
      event.preventDefault();
      this.wordSearchDialogState.open();
      // this.searchInputElement.focus();
    }
  };
}

export const keyboardShortcutHandler = new KeyboardShortcutHandler();

// React hook that adds a global keydown listener and handles keyboard shortcuts
// defined in the KeyboardShortcutHandler class.
//
export const useKeyboardShortcuts = (playerState: AudioPlayerState) => {
  keyboardShortcutHandler.playerState = playerState;
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) =>
      keyboardShortcutHandler.handleKeyDown(event);

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);
};
