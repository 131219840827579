import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

type ExpandButtonLookProps = {
  children: React.ReactNode;
  onClick: () => void;
};

export const ExpandButtonLook = ({
  children,
  onClick,
}: ExpandButtonLookProps) => {
  return (
    <Button onClick={onClick} startIcon={<ExpandMoreIcon />}>
      {children ?? "Show more"}
    </Button>
  );
};
