import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import { getFragmentRouteForTrack } from "@/modules/app/fragment-routes";
import { Track } from "../track/Track";
import { CxProps } from "../types";

export async function clickTrack(cx: CxProps["cx"], track: Track) {
  recordEvent("click-track");
  console.log("clickTrack", track.trackId);

  cx.router.goTo(getFragmentRouteForTrack(track));
  return;
}
