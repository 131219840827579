import { ProgressState, ProgressStateCancellable } from "@/lib/mobx";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import { observer } from "mobx-react";
import { debugFactory } from "@/lib/utils/debug";
import ResponsiveDialog from "@/lib/mui/responsive-dialog/responsive-dialog";

const debug = debugFactory("routing:LoadingDialog");

export type LoadingDialogProps = Omit<DialogProps, "open"> & {
  open?: boolean;
  title?: string;
  progressState?: ProgressState | ProgressStateCancellable | null;
  onCancel?: () => void;
};

export const LoadingDialog = observer((props: LoadingDialogProps) => {
  const { open, title, children, progressState, onCancel, ...otherProps } =
    props;

  return (
    <ResponsiveDialog open={open ?? true} maxWidth="xs" {...otherProps}>
      <DialogTitle>{title ?? "Please wait..."}</DialogTitle>
      <DialogContent>
        <Box sx={{ m: 4, textAlign: "center" }}>
          <CircularProgress />

          {progressState && (
            <>
              {"progress" in progressState && (
                <div>
                  {progressState.progress} of {progressState.total ?? "unknown"}
                </div>
              )}
              {"messages" in progressState ? (
                <>
                  {progressState.messages.map((message, i) => (
                    <div key={i}>{message}</div>
                  ))}
                </>
              ) : (
                <div>{progressState.message}</div>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      {progressState && "cancel" in progressState && (
        <DialogActions>
          <Button
            onClick={() => {
              progressState.cancel();
              if (onCancel) onCancel();
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      )}
    </ResponsiveDialog>
  );
});
