import { TextFieldMobx } from "@/lib/mobx-mui-form/TextFieldMobx";
import { TextFieldState } from "@/lib/mobx-mui-form/TextFieldState";
import debug from "debug";

const log = debug("dict:SearchBox");

type SearchBoxProps = {
  textFieldState: TextFieldState;
  onTyping?: () => void;
  onClear?: () => void;
  onSubmit?: () => void;
};

const SearchBox = (props: SearchBoxProps) => {
  const { textFieldState, onClear, onSubmit } = props;

  const handleSubmit = (event) => {
    log("SearchBox: handleSubmit", !!props.onSubmit);
    event.preventDefault();
    textFieldState.clear();
    onClear && onClear();
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <form id="form" action="/query" onSubmit={handleSubmit}>
      <TextFieldMobx
        state={textFieldState}
        id="q"
        name="q"
        type="text"
        autoComplete="off"
      />
    </form>
  );
};

export default SearchBox;
