import { LoadableValue } from "@/lib/mobx";
import { TranscriptWChaPaSeId } from "@/modules/transcript/formats/types";
import { action, computed, makeAutoObservable, observable } from "mobx";
import { isNumber, isPresent } from "@/lib/prelude";
import { ListenerEpisode } from "@/modules/episode/ListenerEpisode";
import { feedTimestampToSeconds } from "@/modules/feed/feedUtils";
import { Workspace } from "@/modules/main/Workspace";
import { QueueItem } from "@/modules/queue/Queue";
import { ITrack } from "@/modules/track/ITrack";
import { TrackPlayInfoApe } from "@/modules/track/track-play-info-ape";

// Client-side state for a generic track
// Generic: not a podcast episode, could be recorder uploaded by a user
// Using a generic track we can play any audio, regardless where it comes from.
// Maybe migrate the PodcastEpisodeTrack to use this class as well, with optional fields for podcast-specific attributes.
export class GenTrackState implements ITrack {
  workspace: Workspace;
  listenerEpisode: ListenerEpisode | null = null;

  constructor(workspace: Workspace, public attrs: TrackPlayInfoApe) {
    this.workspace = workspace;
    makeAutoObservable(this, {
      listenerEpisode: observable,
      isCurrent: computed,
      isComplete: computed,
      listeningPositionSeconds: computed,
      listeningPositionPercent: computed,
    });
  }

  get trackId() {
    return this.attrs.id;
  }

  get key() {
    return this.trackId;
  }

  get publishedAt(): string | undefined {
    return this.attrs.publishedAt ?? undefined;
  }
  get title(): string {
    return this.attrs.title;
  }
  get description(): string | undefined {
    return this.attrs.description ?? undefined;
  }
  get linkUrl(): string | undefined {
    return this.attrs.linkUrl ?? undefined;
  }
  // get audioUrl(): string {
  //   return this.attrs.audioUrl;
  // }
  get podcast() {
    return undefined;
  }
  get coverUrl(): string | undefined {
    return this.attrs.coverUrl ?? undefined;
  }
  get language(): string | undefined {
    return this.attrs.language ?? undefined;
  }

  get isCurrent() {
    const currentTrack = this.workspace.playingTrackController.currentTrack;
    if (!currentTrack) return false;
    return currentTrack.key === this.key;
  }

  get isComplete() {
    return this.listenerEpisode?.isComplete ?? false;
  }

  // Reference: stored in the queue, used to find the episode in the queue
  get reference(): QueueItem {
    return {
      trackId: this.trackId,
      title: this.title,
    };
  }

  get publishedAtSeconds(): number {
    const t = this.publishedAt;
    return feedTimestampToSeconds(t) ?? 0;
  }

  get listeningPositionSeconds(): number | null {
    return this.listenerEpisode?.listeningPositionSeconds ?? null;
  }

  get listeningPositionPercent(): number | null {
    if (!this.listenerEpisode) return null;
    const { listeningPositionSeconds, duration } = this.listenerEpisode;
    if (
      !isNumber(listeningPositionSeconds) ||
      !isNumber(duration) ||
      duration === 0
    )
      return null;
    return (listeningPositionSeconds / duration) * 100;
  }

  get friendlyReference() {
    return `${this.title} from ${this.title}`;
  }

  private _memoized_transcript: LoadableValue<TranscriptWChaPaSeId> | null =
    null;

  get debugAttributes() {
    return {
      ...this.attrs,
      listeningPositionPercent: this.listeningPositionPercent,
      isComplete: this.isComplete,
    };
  }

  async getOrCreateListenerEpisode(): Promise<ListenerEpisode> {
    return this.workspace?.getListenerEpisode(this.trackId);
  }
}
