import { z } from "zod";

export const FeedItemZ = z.object({
  // Track ID
  trackId: z.string(),

  // Properties from RSS
  title: z.string(),
  description: z.string().nullish(),
  // link: z.string().optional(),
  linkUrl: z.string().nullish(),
  coverUrl: z.string().nullish(),
  publishedAt: z.string(),
  audioUrl: z.string(),
  guid: z.string(),
});
export type FeedItem = z.infer<typeof FeedItemZ>;

//------------------------------------

export const FeedZ = z.object({
  // URL
  url: z.string(),

  // Slug for accessing the podcast via our SEO-friendly URL
  slug: z.string().nullish(),

  // Refresh-info
  etag: z.string().nullish(),
  lastCheckedAt: z.string().nullish(),

  // Properties from RSS
  title: z.string(),
  description: z.string().nullish(),
  coverUrl: z.string().nullish(),
  linkUrl: z.string().nullish(),
  language: z.string().nullish(),

  // Items
  items: z.array(FeedItemZ),
});
export type Feed = z.infer<typeof FeedZ>;

//------------------------------------

export const FeedInfoZ = z.object({
  // URL
  url: z.string(),

  // Refresh-info
  etag: z.string().nullish(),
  lastCheckedAt: z.string().nullish(),

  // Properties from RSS
  title: z.string(),
  description: z.string().nullish(),
  linkUrl: z.string().nullish(),
  coverUrl: z.string().nullish(),
  language: z.string().nullish(),
});
export type FeedInfo = z.infer<typeof FeedInfoZ>;

//------------------------------------

export const FeedInfoClientZ = z.object({
  // URL
  url: z.string(),

  // Properties from RSS
  title: z.string(),
  description: z.string().nullish(),
  linkUrl: z.string().nullish(),
  coverUrl: z.string().nullish(),
  language: z.string().nullish(),
});
export type FeedInfoClient = z.infer<typeof FeedInfoClientZ>;

//------------------------------------

export const FeedItemClientZ = FeedItemZ;
export type FeedItemClient = z.infer<typeof FeedItemClientZ>;

//------------------------------------

export const FeedClientZ = FeedInfoClientZ.extend({
  feedId: z.string(),
  etag: z.string().nullish(),
  items: z.array(FeedItemClientZ),
});
export type FeedClient = z.infer<typeof FeedClientZ>;

//------------------------------------

export const FeedDocZ = FeedZ.extend({
  _id: z.string(),
  _rev: z.string(),
  type: z.literal("feed"),
});

export type FeedDoc = z.infer<typeof FeedDocZ>;

//------------------------------------
