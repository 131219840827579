import { UserError } from "./UserError";

/**
 * Error to be shown to the user.
 * Used by components that render the error, e.g. ErrorDisplay.
 */
export function getUserFriendlyError(
  error: unknown,
  { context }: { context?: string } = {}
): string | null {
  // console.log("getUserFriendlyError", { error, context });
  let messages: string[] = [];
  if (context) {
    messages.push(`${context}:`);
  }
  if (error instanceof UserError) {
    if (error.message) {
      messages.push(error.message);
    }
    // Disabled because it creates some wierd messages, e.g. "Access to this track has been revoked while fetching data from the server."
    // if (error.details.context) {
    //   error.details.context.forEach((c) => {
    //     if (c.doing) {
    //       messages.push(`while ${c.doing}`);
    //     }
    //   });
    // }
  }

  if (messages.length === 0) {
    return null;
  }
  let message: string = messages.join(" ");
  message = message.charAt(0).toUpperCase() + message.slice(1);
  if (!message.endsWith(".")) {
    message += ".";
  }
  return message;
}
