import { Collection } from "@/lib/mobx-pouch/Collection";
import { Database } from "@/lib/mobx-pouch/Database";
import { Entity } from "@/lib/mobx-pouch/Entity";
import { Workspace } from "@/modules/main/Workspace";
import {
  Subscription,
  SubscriptionAttributes,
  SubscriptionDoc,
} from "./Subscription";

export type SubscriptionCollection = Collection<
  SubscriptionAttributes,
  Subscription
>;

export function buildSubscriptionCollection(workspace: Workspace) {
  return workspace.database.collection<SubscriptionAttributes, Subscription>(
    "subscription",
    (
      doc: SubscriptionDoc,
      database: Database,
      collection: SubscriptionCollection
    ) => {
      return new Subscription(workspace, new Entity(doc, database, collection));
    }
  );
}
