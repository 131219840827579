import { SearchAndResultsBox } from "@/modules/dictionary/search/SearchAndResultsBox";
import { BackableDialog } from "@/lib/nemmp/base";
import { recordEvent } from "@/lib/nemmp/base/tracking/usage/recordEvent";
import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import { useEffect, useMemo, useRef } from "react";
import { keyboardShortcutHandler } from "../keyboard-shortcuts/keyboard-shortcuts";
import { CxProps } from "../types";
import { TranslationWrapper } from "./TranslationWrapper";
import { WordSearchEntryState } from "./WordSearchEntryState";

type WordSearchDialogProps = CxProps & {
  // playerState: AudioPlayerState;
  // episodeState: EpisodeLearnerData;
  // mainState: MainState;
};

const Contents = ({ entryState }: { entryState: WordSearchEntryState }) => {
  // Search box has been cleared  =>  continue playing
  const onClear = () => entryState.close();

  // User picked a result from the dictionary search results  =>  update pair, clear & continue playing
  const onResultPick = (text: string, translation: string) => {
    recordEvent("word-search-result-picked");
    console.log("result picked", text, translation);

    entryState.pickResult(text, translation);
  };

  const searchBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchBoxRef.current) {
      const inputElement = searchBoxRef.current.querySelector(
        "input#q"
      ) as HTMLInputElement;
      inputElement?.focus();
      keyboardShortcutHandler.searchInputElement = inputElement;
    }
  }, [searchBoxRef]);

  if (!entryState.dictionarySearchState) return null;

  return (
    <Box sx={{ mx: "auto" }}>
      <Box ref={searchBoxRef}>
        {/* <Paragraph>
          Search for a word in the German-English dictionary, then click on a
          result to select it as the correct translation. Alternatively, you can
          enter your own translation.
        </Paragraph> */}
        {/* <HelpTip>
          Press <kbd>Enter</kbd> to submit your translation, <kbd>Escape</kbd>{" "}
          to close the dialog.
        </HelpTip> */}
        <SearchAndResultsBox
          dictionarySearchState={entryState.dictionarySearchState}
          initialSearchTerm={entryState.text}
          onClear={onClear}
          onResultPick={onResultPick}
          sourceLang={entryState.sourceLang}
          targetLang={entryState.targetLang}
          translationWrapper={({ source, translation, children }) => (
            <TranslationWrapper
              source={source}
              translation={translation}
              onButtonClick={() => onResultPick(source, translation)}
            >
              {children}
            </TranslationWrapper>
          )}
        />
      </Box>
    </Box>
  );
};

export const WordSearchDialog = observer(({ cx }: WordSearchDialogProps) => {
  const { workspace } = cx.wEnv;
  const { playerState, trackLearnerState } = workspace;

  // Create a new entry state whenever the track changes (not sure why this is needed)
  const entryState = useMemo(
    () => new WordSearchEntryState(playerState, workspace),
    [trackLearnerState, workspace]
  );

  useEffect(() => {
    // When the right key is pressed,
    // the keyboard shortcut handler will open the dialog.
    // keyboardShortcutHandler.wordSearchDialogState = entryState;
    workspace.wordSearchDialogState = entryState;
  }, [entryState]);

  if (!entryState) return null;

  return (
    <BackableDialog
      title="Search"
      open={entryState.isOpen}
      onClose={() => {
        entryState.close();
        recordEvent("word-search-dialog-closed");
      }}
      maxWidth="sm"
      fullScreen={false}
    >
      <Contents entryState={entryState} />
    </BackableDialog>
  );
});
