import { ProgressStateCancellable } from "@/lib/mobx";
import { Paragraph } from "@/lib/mui/Paragraph";
import { StandardDialog } from "@/lib/mui/StandardDialog";
import { LoadingDialog, sendContactMessage } from "@/lib/nemmp/base";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import { observer } from "mobx-react";
import { useState } from "react";
import { CxProps } from "../types";

const platformTypes = ["web", "ios", "android"] as const;
type PlatformType = (typeof platformTypes)[number];
const platformLabels: Record<PlatformType, string> = {
  web: "Web",
  ios: "iOS",
  android: "Android",
};

export const EarlyAccessSignupDialog = observer(({ cx }: CxProps) => {
  const { workspace } = cx.wEnv;

  const [stage, setStage] = useState<"form" | "submitting" | "thanks">("form");

  const [submissionState, setSubmissionState] =
    useState<ProgressStateCancellable | null>(null);

  const [email, setEmail] = useState("");
  const [platforms, setPlatforms] = useState<Record<PlatformType, boolean>>({
    web: false,
    ios: false,
    android: false,
  });
  const [message, setMessage] = useState("");

  const close = () => {
    workspace.earlyAccessDialogState.close();
    setTimeout(() => {
      setStage("form");
    }, 1000);
  };

  const isSubmittable =
    email.length > 0 && Object.values(platforms).some((p) => p);

  const submit = () => {
    const selectedPlatforms = platformTypes
      .map((t) => (platforms[t] ? t : null))
      .filter((t) => t);

    const ps = new ProgressStateCancellable();
    ps.onCancel(() => {
      close();
    });

    setSubmissionState(ps);
    setStage("submitting");

    sendContactMessage({
      email,
      message:
        `\n\n${selectedPlatforms
          .map((p) => `Pinpod-EarlyAccess-${p}`)
          .join("\n")}` +
        "\n\n" +
        message,
    }).then(() => {
      setStage("thanks");
    });
  };

  // Submission in progress - Loading
  if (stage === "submitting" && submissionState) {
    return <LoadingDialog title="Submitting" progressState={submissionState} />;
  }

  if (stage === "thanks") {
    return (
      <StandardDialog
        forParagraphs
        maxWidth="xs"
        open={workspace.earlyAccessDialogState.isOpen}
        onClose={close}
        title="Thank you!"
      >
        <Paragraph>
          You will get an email when the app is ready for testing.
        </Paragraph>
      </StandardDialog>
    );
  }

  return (
    <StandardDialog
      title="Early Access Signup"
      open={workspace.earlyAccessDialogState.isOpen}
      onClose={close}
      maxWidth="xs"
      primaryAction={{
        label: "Submit",
        onClick: () => submit(),
        disabled: !isSubmittable,
      }}
    >
      <>
        <Paragraph>
          Enter your email and I will send you an invitation when the app is
          ready for testing on any of the selected platforms.
        </Paragraph>
        <Paragraph>
          Your email address will not be used for any other purpose or shared
          with anyone else.
        </Paragraph>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            submit();
          }}
        >
          <Box>
            <TextField
              type="email"
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ width: "18em" }}
            />
          </Box>

          <Box sx={{ my: 3 }}>
            <FormLabel id="mobile-platform-label" component="legend">
              Your preferred platform(s):
            </FormLabel>
            <FormGroup row aria-labelledby="mobile-platform-label">
              {Object.entries(platformLabels).map(
                ([platform, platformLabel]) => {
                  const checked: boolean = platforms[platform as PlatformType];
                  return (
                    <FormControlLabel
                      key={platform}
                      value={platform}
                      control={<Checkbox />}
                      label={platformLabel}
                      checked={checked}
                      onChange={() =>
                        setPlatforms({
                          ...platforms,
                          [platform]: !checked,
                        })
                      }
                    />
                  );
                }
              )}
            </FormGroup>
          </Box>

          <Box sx={{ mt: 3 }}>
            <FormLabel
              id="mobile-platform-label"
              component="legend"
              sx={{ mb: 2 }}
            >
              Feature request, favorite podcast, or anything else:
            </FormLabel>
            <TextField
              type="message"
              id="message"
              name="message"
              label="Message (optional)"
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{ width: "18em" }}
              multiline
              rows={2}
              fullWidth
            />
          </Box>
        </form>
      </>
    </StandardDialog>
  );
});
