import { PodcastEpisodeTrack } from "@/modules/episode/PodcastEpisodeTrack";
import { Workspace } from "@/modules/main/Workspace";
import { GenTrackState } from "@/modules/track/gen/GenTrackState";
import debug from "debug";
import { TrackPlayInfoApe } from "./track-play-info-ape";

export function buildTrackStateFromApe(
  workspace: Workspace,
  r: TrackPlayInfoApe
) {
  const log = debug("track:info:buildTrackStateFromApe");
  const trackId = r.id;

  if ("feedInfo" in r && "feedItem" in r) {
    if (r.feedInfo && r.feedItem) {
      log("getTrackInfo", trackId, "new PodcastEpisodeTrack");
      return new PodcastEpisodeTrack(
        workspace,
        trackId,
        r.feedInfo,
        r.feedItem
      );
    }
    throw new Error("feedInfo or feedItem missing");
  } else {
    log("getTrackInfo", trackId, "new GenTrackState");
    return new GenTrackState(workspace, r);
  }
}
